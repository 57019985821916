import { useContext, useEffect, useMemo, useRef, useState, ChangeEvent } from 'react';
import cn from 'classnames';
import CodeEditor from '@monaco-editor/react';
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Tab as MuiTab,
  Tabs as MuiTabs,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
  Snackbar,
  useMediaQuery,
  ThemeProvider,
  Divider,
  
} from '@material-ui/core';
import {
  ArrowDropDown,
  Brightness2,
  Brightness2Outlined,
  ExpandLess,
  ExpandMore,
  Flag,
  FlagOutlined,
  PlayArrow,
  Close,
  LockOutlined,
} from '@material-ui/icons';
import { TestContext } from '../../context/test.context';
import { TStateCodeLanguage } from '../../test.props';
import { Section } from '../section/section';
// import Section from "../../components/AssessmentComponents/section";
import RichTextEditor from "../RichTextEditor/index";
import { TProps } from './solution-section.props';
import styles from './solution-section.module.scss';
import { makeStyles } from '@material-ui/core/styles';
import { CollapsibleSummarySection } from '../collapsible-summary-section/collapsible-summary-section';
// import { SectionSelector } from '../section-selector/section-selector';
// import { TSectionSelectorProps } from './section-selector.props';


const languages: { [key: string]: string } = {
  c: "c",
  "c++": "cpp",
  "c-sharp": "csharp",
  css: "css",
  go: "go",
  html: "html",
  java: "java",
  javascript: "javascript",
  kotlin: "kotlin",
  node: "javascript",
  "objective-c": "objective-c",
  php: "php",
  python: "python",
  swift: "swift",
  typescript: "typescript",
};

const OutputTopBar = withStyles({
  root: {
    backgroundColor: '#F2F1F3',
    borderBottom: '1px solid #D8D6DB',
  },
})(AppBar);
const useStyles = makeStyles({
  root:{
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "flex-start",
    marginLeft: "-11px",
    marginRight: "16px",
    verticalAlign: "middle"
  }
})
const OutputToolBar = withStyles({
  root: {
    backgroundColor: '#F2F1F3',
    color: '#000',
    height: 49,
    minHeight: 0,
  },
})(Toolbar);

const TopBar = withStyles({
  root: {
    backgroundColor: '#fff',
    borderBottom: '1px solid #F2F1F3',
  },
})(AppBar);

const TopToolBar = withStyles({
  root: {
    backgroundColor: '#fff',
    color: '#000',
    minHeight: 0,
  },
})(Toolbar);

const BottomBar = withStyles({
  root: {
    backgroundColor: '#fff',
  },
})(AppBar);

const BottomToolBar = withStyles({
  root: {
    backgroundColor: '#fff',
    borderTop: '1px solid #F2F1F3',
    color: '#000',
    height: 60,
    minHeight: 0,
  },
})(Toolbar);

const FlagIconButton = withStyles({
  root: {
    color: '#C38A00',
  },
})(IconButton);

const ConfirmButton = withStyles({
  root: {
    backgroundColor: '#1BA94C',
    color: '#fff',
    height: 42,
    minWidth: 0,
    '&:hover': {
      backgroundColor: '#15853c',
    },
  },
})(Button);

const RunCodeButton = withStyles({
  root: {
    borderColor: '#1BA94C',
    borderWidth: 1,
    color: '#1BA94C',
    height: 42,
    minWidth: 0,
    '&:hover': {
      borderColor: '#1BA94C',
      borderWidth: 1,
    },
  },
})(Button);

const ClearButton = withStyles({
  root: {
    color: '#63606C',
    height: 42,
    minWidth: 0,
    padding: '0 14px',
  },
})(Button);

const DropdownButton = withStyles({
  root: {
    backgroundColor: "#eee !important",
    color: "#000",
    height: 36,
    padding: "0 8px 0 12px",
    textTransform: 'capitalize',
  },
  disabled: {
    borderColor: "#fff8 !important",
    color: "#fff8 !important",
  },
})(Button);

const DropdownButtonSmall = withStyles({
  root: {
    backgroundColor: "#eee !important",
    minWidth: 0,
    padding: "0 8px",
  },
  endIcon: {
    marginLeft: 0,
  },
})(Button);

const Tabs = withStyles({
  root: {
    height: '100%',
  },
  indicator: {
    backgroundColor: '#1BA94C',
  },
})(MuiTabs);

const Tab = withStyles({
  root: {
    color: '#000',
    height: '100%',
    minWidth: 0,
    padding: '0 8px',
    textTransform: 'capitalize',
    '&:last-of-type': {
      marginLeft: 8,
    },
  },
})(MuiTab);

export function SolutionSection(props: TProps) {
  const { largeScreen, mediumScreen, smallScreen } = useContext(TestContext);
  const selectedOptions = useMemo(() => props.selectedOptions, [props]);
  const [tabMenuAnchor, setTabMenuAnchor] = useState(null);
  const [sectionComplete, setSectionComplete] = useState(false);
  const classes = useStyles();
  const editorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [editorValue, setEditorValue] = useState('');

  useEffect(() => {
    if (props.currentQuestion.num) {
      const newValue = props?.allSectionQuestionArray && props.currentSection && props.currentQuestion
        ? props.allSectionQuestionArray[props.currentSection.num - 1]?.[props.currentQuestion.num - 1]?.codemsg || ""
        : "";
      setEditorValue(newValue);
    } else {
      setEditorValue('');
    }
  }, [props.currentQuestion.num, props.allSectionQuestionArray, props.currentSection, props.currentQuestion]);

  function handleEditorDidMount(editor: any) {
    editorRef.current = editor;

    editor.onKeyDown((event: KeyboardEvent) => {
      const { keyCode, ctrlKey, metaKey } = event;

      if ((keyCode === 33 || keyCode === 52) && (metaKey || ctrlKey)) {
        event.preventDefault();
        // props?.handleRestrictedAction?.();
      }
    });

    editor.onDidChangeCursorSelection(
      () => {
        const { column, lineNumber } = editor.getPosition();
        editor.setPosition({ lineNumber, column });
      },
    );
  }

  useEffect(() => {
    if (!props.codeSolution.languageId && props.currentQuestion.isCodingType) {
      props.updateCodeSolutions((cs) => ({
        ...cs,
        [props.currentQuestion.id]: {
          ...(cs?.[props.currentQuestion.id] || {}),
          languageId: props?.codeLanguages?.[0]?.id,
          languageName: props?.codeLanguages?.[0]?.languageName,
          solution: '',
        }
      }));
    }

    props.setCustomInput('');
    props.setOutput('');
  }, [props.currentQuestion.id]);

 const handleChange = (event: ChangeEvent<{ value: string }>) => {
    props.setDescriptiveAnswers((cs) => ({
      ...cs,
      [props.currentQuestion.id]: event?.currentTarget?.value,
    }));
}

// const goToNextSection = useCallback(() => {
//   setCurrentQuestionValue(learningPathList[dropdownValue + 1])
//   setDropdownValue(dropdownValue + 1)
//   setType(learningPathList[dropdownValue]?.type)
// })

const handleClick = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(!open);
};

function hideSnackbar() {
  setOpen(false);
}

  return (
    <Section
      className={cn(
        styles.solutionSection,
        smallScreen && styles.smallScreen,
        mediumScreen && styles.mediumScreen,
        largeScreen && styles.largeScreen,
      )}
      noShadow={largeScreen || smallScreen}
      topBar={
        (props.currentQuestion.type === 'DESC' && smallScreen) ? '' :
        (
        <TopBar
          position="relative"
          style={{
            ...smallScreen && {
              borderTop: '1px solid #F2F1F3',
            },
          }}>
          
          <TopToolBar
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: smallScreen ? 16 : 20,
              paddingRight: smallScreen ? 16 : 20,
              ...smallScreen ? {
                height: 40,
              } : {
                height: 48,
              },
            }}>
            {!(smallScreen && props.currentQuestion.isCodingType) && (
              <Typography
                noWrap
                style={{
                  fontWeight: 500,
                }}>
                {props.currentQuestion.isCodingType
                  ? 'Code Editor'
                  : 'Answer'}
              </Typography>
            )}
            {props.currentQuestion.isCodingType && (
              <>
                {!smallScreen && (
                  <Box mx={1} />
                )}
                {smallScreen
                  ? (
                    <DropdownButtonSmall
                      aria-controls="language-menu"
                      aria-haspopup="true"
                      color="inherit"
                      disabled={props.disabled}
                      endIcon={<ArrowDropDown height={20} width={20} />}
                      onClick={(e: any) => {
                        setTabMenuAnchor(e.currentTarget);
                      }}
                      size="small"
                      variant="contained">
                      {props.codeSolution.languageName?.toLowerCase?.()}
                    </DropdownButtonSmall>
                  )
                  : (
                    <DropdownButton
                      aria-controls="language-menu"
                      aria-haspopup="true"
                      color="inherit"
                      disabled={props.disabled}
                      endIcon={<ArrowDropDown height={20} width={20} />}
                      onClick={(e: any) => {
                        setTabMenuAnchor(e.currentTarget);
                      }}
                      size="small"
                      variant="contained">
                      {props.codeSolution.languageName?.toLowerCase?.()}
                    </DropdownButton>
                  )}
                <Menu
                  anchorEl={tabMenuAnchor}
                  PopoverClasses={{ root: styles.languageSelectorBackdrop }}
                  id="language-menu"
                  keepMounted
                  transitionDuration={0}
                  onClose={() => {
                    setTabMenuAnchor(null);
                  }}
                  open={Boolean(tabMenuAnchor)}>
                  {props.codeLanguages?.map?.((languageItem: TStateCodeLanguage) => (
                    <MenuItem
                      onClick={() => {
                        props.updateCodeSolutions((cs: any) => ({
                          ...cs,
                          [props.currentQuestion.id]: {
                            ...(cs?.[props.currentQuestion.id] || {}),
                            languageId: languageItem.id,
                            languageName: languageItem.languageName,
                          },
                        }));
                        setTabMenuAnchor(null);
                      }}
                      // selected={props?.codeSolution?.selectedLanguageId ? 
                      //   props?.codeSolution?.selectedLanguageId : 
                      //   props.codeSolution.languageId === languageItem.id
                      // }
                      selected={
                        props.codeSolution.languageId === languageItem.id
                      }
                      value={languageItem.id}
                      key={languageItem.id}>
                      <Typography style={{ textTransform: 'capitalize' }}>
                        {languageItem.languageName?.toLowerCase?.()}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
            <Box mx="auto" />
            {(mediumScreen && props.currentQuestion.isCodingType) && (
              <Typography
                noWrap
                style={{
                  fontWeight: 500,
                }}>
                {props.currentQuestion.isCodingType &&
                  <ConfirmButton
                  disabled={
                    props.disabled || 
                    (props?.currentQuestion?.isCodingType
                    ? props?.codeSolution?.solution?.trim().length == 0
                    : props?.currentQuestion?.type === "DESC"
                    ? props?.descriptiveAnswer?.trim().length == 0
                    : !props.selectedOptions?.length) 
                    || props.isAdmin === "true"
                  }
                  variant="contained"
                  color="primary"
                  onClick={props.confirmSolution}
                  style={{
                    minWidth: 100,
                  }}>
                  {props.submittingSolution
                    ? <CircularProgress size={18} />
                    : 'confirm'
                  }
                </ConfirmButton>}
              </Typography>
            )}
            {props.currentQuestion.isCodingType && (
              <Tooltip title={`Toggle ${props.codeEditorIsInLightMode ? 'Dark Mode' : 'Light Mode'}`}>
                <IconButton
                  color="inherit"
                  disabled={props.disabled}
                  onClick={() => {
                    props?.setIfCodeEditorIsInLightMode?.(!props.codeEditorIsInLightMode);
                  }}
                  style={{
                    color: '#666',
                    marginRight: -8,
                  }}
                  size="small">
                  {props.codeEditorIsInLightMode ? <Brightness2Outlined /> : <Brightness2 />}
                </IconButton>
              </Tooltip>
            )}
            {smallScreen && !props.currentQuestion.isCodingType && (
              <FlagIconButton
                disabled={props.disabled || props.isAdmin === "true"}
                onClick={props.flagQuestion}>
                {props.currentQuestion.flagged
                  ? <Flag style={{ fontSize: 26 }} />
                  : <FlagOutlined style={{ fontSize: 26 }} />
                }
              </FlagIconButton>
            )}
            {(props.currentQuestion.type === 'MC' && smallScreen) && (
              <ClearButton
                disabled={props.disabled || props.isAdmin === "true"}
                variant="text"
                style={{backgroundColor:'#794DF5', color:'white', borderRadius:'3px'}}
                onClick={() => {
                  props.clearSelection(props.currentQuestion.id);
                }}>
                Clear Selection
              </ClearButton>
            )}
             {/* {(props.currentQuestion.isCodingType && smallScreen) && (
            <div>
                <Tooltip title="Test Case Validate" arrow>
                  <p style={{paddingTop:"12px"}}>
                  <span> 
                  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 6H7C7.28333 6 7.521 5.904 7.713 5.712C7.90433 5.52067 8 5.28333 8 5C8 4.71667 7.90433 4.479 7.713 4.287C7.521 4.09567 7.28333 4 7 4H4C3.71667 4 3.479 4.09567 3.287 4.287C3.09567 4.479 3 4.71667 3 5C3 5.28333 3.09567 5.52067 3.287 5.712C3.479 5.904 3.71667 6 4 6ZM4 10H7C7.28333 10 7.521 9.904 7.713 9.712C7.90433 9.52067 8 9.28333 8 9C8 8.71667 7.90433 8.479 7.713 8.287C7.521 8.09567 7.28333 8 7 8H4C3.71667 8 3.479 8.09567 3.287 8.287C3.09567 8.479 3 8.71667 3 9C3 9.28333 3.09567 9.52067 3.287 9.712C3.479 9.904 3.71667 10 4 10ZM4 14H7C7.28333 14 7.521 13.904 7.713 13.712C7.90433 13.5207 8 13.2833 8 13C8 12.7167 7.90433 12.479 7.713 12.287C7.521 12.0957 7.28333 12 7 12H4C3.71667 12 3.479 12.0957 3.287 12.287C3.09567 12.479 3 12.7167 3 13C3 13.2833 3.09567 13.5207 3.287 13.712C3.479 13.904 3.71667 14 4 14ZM12.55 11.575C12.6833 11.575 12.8083 11.554 12.925 11.512C13.0417 11.4707 13.15 11.4 13.25 11.3L16.8 7.75C17.0167 7.53333 17.121 7.29567 17.113 7.037C17.1043 6.779 17 6.55 16.8 6.35C16.6 6.15 16.3623 6.05 16.087 6.05C15.8123 6.05 15.575 6.15 15.375 6.35L12.55 9.175L11.825 8.45C11.625 8.25 11.3917 8.154 11.125 8.162C10.8583 8.17067 10.625 8.275 10.425 8.475C10.2417 8.675 10.1457 8.90833 10.137 9.175C10.129 9.44167 10.225 9.675 10.425 9.875L11.85 11.3C11.95 11.4 12.0583 11.4707 12.175 11.512C12.2917 11.554 12.4167 11.575 12.55 11.575ZM2 18C1.45 18 0.979333 17.8043 0.588 17.413C0.196 17.021 0 16.55 0 16V2C0 1.45 0.196 0.979 0.588 0.587C0.979333 0.195667 1.45 0 2 0H18C18.55 0 19.021 0.195667 19.413 0.587C19.8043 0.979 20 1.45 20 2V16C20 16.55 19.8043 17.021 19.413 17.413C19.021 17.8043 18.55 18 18 18H2ZM2 16H18V2H2V16ZM2 16V2V16Z" fill="#323036"/>
</svg></span><span className={styles.Passed}>
1/5 Test Case(S) Passed </span>
                  </p>
                  </Tooltip>
                </div>
            )} */}
          </TopToolBar>
        </TopBar>
      )}
      bottomBar={!smallScreen && (
        <BottomBar position="relative">
          <BottomToolBar
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingLeft: 8,
              paddingRight: 20,
            }}>
            {(props.currentQuestion.type === 'MC') && (
              <ClearButton
                disabled={props.disabled || props.isAdmin === "true"}
                variant="text"
                color="default"
                onClick={() => {
                  props.clearSelection(props.currentQuestion.id);
                }}>
                Clear Selection
              </ClearButton>
            )}
            {/* {props.currentQuestion.isCodingType && (
            <div>
                <Tooltip title="Test Case Validate" arrow>
                  <p style={{paddingTop:"12px"}}>
                  <span> 
                  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 6H7C7.28333 6 7.521 5.904 7.713 5.712C7.90433 5.52067 8 5.28333 8 5C8 4.71667 7.90433 4.479 7.713 4.287C7.521 4.09567 7.28333 4 7 4H4C3.71667 4 3.479 4.09567 3.287 4.287C3.09567 4.479 3 4.71667 3 5C3 5.28333 3.09567 5.52067 3.287 5.712C3.479 5.904 3.71667 6 4 6ZM4 10H7C7.28333 10 7.521 9.904 7.713 9.712C7.90433 9.52067 8 9.28333 8 9C8 8.71667 7.90433 8.479 7.713 8.287C7.521 8.09567 7.28333 8 7 8H4C3.71667 8 3.479 8.09567 3.287 8.287C3.09567 8.479 3 8.71667 3 9C3 9.28333 3.09567 9.52067 3.287 9.712C3.479 9.904 3.71667 10 4 10ZM4 14H7C7.28333 14 7.521 13.904 7.713 13.712C7.90433 13.5207 8 13.2833 8 13C8 12.7167 7.90433 12.479 7.713 12.287C7.521 12.0957 7.28333 12 7 12H4C3.71667 12 3.479 12.0957 3.287 12.287C3.09567 12.479 3 12.7167 3 13C3 13.2833 3.09567 13.5207 3.287 13.712C3.479 13.904 3.71667 14 4 14ZM12.55 11.575C12.6833 11.575 12.8083 11.554 12.925 11.512C13.0417 11.4707 13.15 11.4 13.25 11.3L16.8 7.75C17.0167 7.53333 17.121 7.29567 17.113 7.037C17.1043 6.779 17 6.55 16.8 6.35C16.6 6.15 16.3623 6.05 16.087 6.05C15.8123 6.05 15.575 6.15 15.375 6.35L12.55 9.175L11.825 8.45C11.625 8.25 11.3917 8.154 11.125 8.162C10.8583 8.17067 10.625 8.275 10.425 8.475C10.2417 8.675 10.1457 8.90833 10.137 9.175C10.129 9.44167 10.225 9.675 10.425 9.875L11.85 11.3C11.95 11.4 12.0583 11.4707 12.175 11.512C12.2917 11.554 12.4167 11.575 12.55 11.575ZM2 18C1.45 18 0.979333 17.8043 0.588 17.413C0.196 17.021 0 16.55 0 16V2C0 1.45 0.196 0.979 0.588 0.587C0.979333 0.195667 1.45 0 2 0H18C18.55 0 19.021 0.195667 19.413 0.587C19.8043 0.979 20 1.45 20 2V16C20 16.55 19.8043 17.021 19.413 17.413C19.021 17.8043 18.55 18 18 18H2ZM2 16H18V2H2V16ZM2 16V2V16Z" fill="#323036"/>
</svg></span><span className={styles.Passed}>
1/5 Test Case(S) Passed </span>
                  </p>
                  </Tooltip>
                </div>
            )} */}
            <Box mx="auto" />
            <Grid style={{ display: 'flex' }}>
              {props.currentQuestion.isCodingType && (
                <>
                  <RunCodeButton
                    onClick={() => {
                      props.runCode(props.customInput || '', () => {
                        props.setOutputTab(1);
                        props.setIfOutputIsVisible(true);
                      });
                    }}
                    disabled={props?.disabled || props?.codeSolution?.solution?.trim()?.length == 0 || props?.isAdmin === "true"}
                    variant="outlined" color="primary">
                    {props.runningCode
                      ? <CircularProgress color="inherit" size={18} />
                      : <PlayArrow />
                    }
                    &nbsp;&nbsp;
                    Run Code
                  </RunCodeButton>
                  <Box mx={1} />
                </>
              )}
              <ConfirmButton
                disabled={
                  props.disabled || 
                  (props?.currentQuestion?.isCodingType
                  ? props?.codeSolution?.solution?.trim().length == 0
                  : props?.currentQuestion?.type === "DESC"
                  ? props?.descriptiveAnswer?.trim().length == 0
                  : !props.selectedOptions?.length) 
                  || props.isAdmin === "true"
                }
                variant="contained"
                color="primary"
                 onClick={props.confirmSolution}
                // onClick={handleClick}
                style={{
                  minWidth: 100,
                }}>
                {props.submittingSolution
                  ? <CircularProgress size={18} />
                  : 'confirm '
                }
              </ConfirmButton>
               
             
               
              
            
            </Grid>
          </BottomToolBar>
        </BottomBar>
      )}
      contentClassName={(props.currentQuestion.type === 'CD')
        ? styles.noPadding
        : undefined}
      style={{ ...largeScreen && { width: `calc(${100 - props.width}% - 10px)` } }}>
      {(props.currentQuestion.type === 'CD') && (
        <div className={styles.codeSolution}>
          <div
            className={cn(
              styles.codeEditorWrapper,
              props.outputIsVisible && styles.outputVisible,
            )}>
            {props.currentQuestion.num && (
            <CodeEditor
              className={styles.codeEditor}
              value={editorValue}
              language={props.codeSolution.languageName?.toLowerCase() || 'text'}
              onChange={(val = '') => {
                setEditorValue(val);
                props.updateCodeSolutions((cs) => ({
                  ...cs,
                  [props.currentQuestion.id]: {
                    ...(cs?.[props.currentQuestion.id] || {}),
                    solution: val,
                  },
                }));
              }}
              onMount={handleEditorDidMount}
              theme={props.codeEditorIsInLightMode ? 'light' : 'vs-dark'}
              options={{
                autoIndent: "advanced",
                contextmenu: false,
                fontFamily: "Fira Code",
                fontLigatures: true,
                fontSize: 16,
                formatOnPaste: true,
                matchBrackets: "always",
                minimap: {
                  enabled: false,
                },
                padding: {
                  bottom: 12,
                  top: 12,
                },
                readOnly: false,
                quickSuggestions: false,
                smoothScrolling: true,
                snippetSuggestions: "none",
              }}
            /> 
            )}
          </div>
          <div
            className={cn(
              styles.outputEditorWrapper,
              props.outputIsVisible && styles.outputVisible,
            )}>
            <OutputTopBar position="sticky">
              <OutputToolBar
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '0 12px 0 20px',
                }}>
                <Grid>
                  <Collapse in={props.outputIsVisible}>
                    <Tabs
                      color="primary"
                      disabled={props.disabled}
                      textColor="inherit"
                      value={props.outputTab}
                      onChange={(e, val) => {
                        props.setOutputTab(val);
                      }}>
                      <Tab label="Enter Custom Input" value={0} />
                      <Tab label="Output" value={1} />
                    </Tabs>
                  </Collapse>
                  <Collapse in={!props.outputIsVisible}>
                    <Typography>Custom Input</Typography>
                  </Collapse>
                </Grid>
                <IconButton
                  color="inherit"
                  disabled={props.disabled}
                  size="small"
                  onClick={() => {
                    props.setIfOutputIsVisible((ov: boolean) => !ov);
                  }}>
                  {props.outputIsVisible
                    ? <ExpandMore style={{ fontSize: 26 }} />
                    : <ExpandLess style={{ fontSize: 26 }} />
                  }
                </IconButton>
              </OutputToolBar>
            </OutputTopBar>
            <div className={styles.outputEditorContainer}>
              {props.outputIsVisible && props.outputTab === 0 && (
                <CodeEditor
                  className={styles.outputEditor}
                  value={(props.outputTab === 0) ?  props.customInput :""}
                  language="text"
                  onChange={(val) => {
                    props.setCustomInput(val?.trim() || '');
                  }}
                  theme={props.codeEditorIsInLightMode ? 'light' : 'vs-dark'}
                  options={{
                    autoIndent: "advanced",
                    lineNumbers: "off",
                    contextmenu: false,
                    fontFamily: "Fira Code",
                    fontLigatures: true,
                    fontSize: 14,
                    formatOnPaste: true,
                    matchBrackets: "always",
                    scrollbar: {
                      useShadows: false,
                    },
                    minimap: {
                      enabled: false,
                    },
                    padding: {
                      bottom: 12,
                      top: 12,
                    },
                    readOnly: (props.outputTab !== 0),
                    quickSuggestions: false,
                    smoothScrolling: true,
                    snippetSuggestions: "none",
                  }}
                />
              )}
              {props.outputIsVisible && props.outputTab === 1 && (
                <CodeEditor
                  className={styles.outputEditorSecondTab}
                  value={(props.outputTab === 1) ?  props.output :""}
                  language="text"
              
                  theme={props.codeEditorIsInLightMode ? 'light' : 'vs-dark'}
                  options={{
                    autoIndent: "advanced",
                    lineNumbers: "off",
                    contextmenu: false,
                    fontFamily: "Fira Code",
                    fontLigatures: true,
                    fontSize: 14,
                    formatOnPaste: true,
                    matchBrackets: "always",
                    scrollbar: {
                      useShadows: false,
                    },
                    minimap: {
                      enabled: false,
                    },
                    padding: {
                      bottom: 12,
                      top: 12,
                    },
                    readOnly: (props.outputTab === 1),
                    quickSuggestions: false,
                    smoothScrolling: true,
                    snippetSuggestions: "none",
                  }}
                />
              )}
              </div>
            </div>
          </div>
      )}
      {(props.currentQuestion.type === 'MC') && (
        <FormControl component="div" style={{ width: '100%' }}>
          <Box sx={{ display: 'none' }}>
            {JSON.stringify(selectedOptions)}
          </Box>
          {props.currentQuestionData?.multiSelect
            ? (
              <FormGroup style={{ width: '100%' }}>
                {props.currentQuestionData?.optionsList?.map?.((option: any, idx: number) => (
                  <FormControlLabel
                    key={idx}
                    classes={{
                      root: styles.inputControl,
                      label: styles.inputControlLabel,
                    }}
                    control={(
                      <Checkbox
                        classes={{
                          root: styles.inputControlInput,
                        }}
                        color="primary"
                        disabled={props.disabled || props.isAdmin === "true"}
                        checked={selectedOptions?.includes(option?.id)}
                        onChange={(_e, checked) => {
                          props.setSelectedOptions?.(
                            props.currentQuestion.id, option?.id,
                            !checked,
                          );
                        }}
                      />
                    )}
                    label={(
                      <div
                        dangerouslySetInnerHTML={{
                          __html: option?.optionValue,
                        }}
                      />
                    )}
                  />
                ))}
              </FormGroup>
            )
            : (
              <RadioGroup
                value={selectedOptions[0] || ''}
                onChange={(e) => {
                  props.setSelectedOption(
                    props.currentQuestion.id,
                    e.target.value,
                  );
                }}
                style={{ width: '100%' }}>
                {(props.currentQuestionData?.optionsList || [])?.map?.((option: any, idx: number) => (
                  <FormControlLabel
                    key={idx}
                    disabled={props.disabled || props.isAdmin === "true"}
                    className={classes.root}
                    classes={{
                      root: cn(styles.inputControl, (option?.id === selectedOptions[0]) && styles.selected),
                      label: styles.inputControlLabel,
                    }}
                    value={option?.id}
                    control={(
                      <Radio
                        classes={{
                          root: styles.inputControlInput,
                        }}
                        color="primary"
                      />
                    )}
                    label={(
                      <div
                        dangerouslySetInnerHTML={{
                          __html: option?.optionValue,
                        }}
                      />
                    )}
                  />
                ))}
              </RadioGroup>
            )}
        </FormControl>
      )}

      {((props.currentQuestion.type === 'DESC' && smallScreen ) &&
      (props.currentTab == 'solution')) &&
      (
        <div className={styles.descriptionAnswer}>
          <div
            className={cn(
              styles.codeEditorWrapper,
              props.outputIsVisible && styles.outputVisible,
            )}>
            <textarea 
              style={{width:"100%", height:"40%", padding:"10px"}}
              value={props.descriptiveAnswer || ""}
              onChange={(
                ev: React.ChangeEvent<HTMLTextAreaElement>,
            ): void => props.setDescriptiveAnswers((cs) => ({
              ...cs,
              [props.currentQuestion.id]: ev.target?.value,
            }))}
            />
          </div>
        </div>
      )}

      {(props.currentQuestion.type === 'DESC' && !smallScreen) && (
      <div className={styles.descriptionAnswer}>
          <div
            className={cn(
              styles.codeEditorWrapper,
              props.outputIsVisible && styles.outputVisible,
            )}>
            <textarea 
              style={{width:"100%", height:"40%", padding:"10px"}}
              value={props.descriptiveAnswer || ""}
              onChange={(
                ev: React.ChangeEvent<HTMLTextAreaElement>,
            ): void => props.setDescriptiveAnswers((cs) => ({
              ...cs,
              [props.currentQuestion.id]: ev.target?.value,
            }))}
            />
          </div>
        </div>
      )}
    </Section>
  );
}

   