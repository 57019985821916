import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { sessionPrivileges } from "privileges";
import apiHelper from "../../api/apiHelper";
import api from "api/baseConfig";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";
import Avatar from "@material-ui/core/Avatar";
import Skeleton from "@material-ui/lab/Skeleton";
import { ToastContainer, toast } from "react-toastify";
import { useParams, Link } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
<link
  rel="stylesheet"
  href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
/>;

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 13,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#794DF5",
  },
}))(LinearProgress);
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export default function CourseReview(props) {
  const userPrivileges = sessionPrivileges();
  const classes = useStyles();
  const [value, setValue] = useState(null);
  const [star, setStar] = useState({});
  const [member, setMember] = useState();
  const [starRating, setStarRating] = useState(starRating);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [ifStar, setIfStar] = useState();
  const [reviewSection, setReviewSection] = useState(true);
  const { courseId } = useParams();

  const postRating = async (starValue) => {
    if (starValue) {
      const url = `${api.baseURL}${api.courseController.courseRating}`;
      const payload = {
        courseId: props.courseId,
        userId: userPrivileges.userName,
        userRating: starValue,
        updatedBy: userPrivileges.userName,
      };
      try {
        const data = await apiHelper.axiosCallPost(url, "post", payload);
        return data;
      } catch (error) {
        throw new Error(`Some error occurred: ${error}`);
      }
    }
  };

  const getRating = async () => {
    const url = `${api.baseURL}${api.courseController.getRating}/${courseId}`;
    try {
      const ratingData = await apiHelper.axiosCallPost(url, "get");
      setMember(ratingData.userCourseList);
      setStar(ratingData.ratings);
      setIfStar(ratingData.ConsolidatedRating);
    } catch (error) {
      throw new Error(`Some error occurred: ${error}`);
    }
  };

  const postReview = async () => {
    const url = `${api.baseURL}${api.courseController.CourseComment}`;
    const payload = {
      courseId: props.courseId,
      userId: userPrivileges.userName,
      userComment: comment,
    };

    try {
      const data = await apiHelper.axiosCallPost(url, "post", payload);
      getRating();
      toast.success("Review added successfully", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
      });
      setComment("");
      setReviewSection(false);
      return data;
    } catch (error) {
      throw new Error(`Some error occurred: ${error}`);
    }
  };

  useEffect(() => {
    getRating();
  }, []);

  const ratingFunction = (e, value) => {
    setValue(value);
    postRating(value);
  };

  // const inputEmpty = (e) => {
  //   setComment("")
  // }
  return (
    <>
      <div className="container-fluid">
        <div className=" justify-content-center">
          {!loading ? (
            ifStar ? (
              <div className="cards">
                <h5 className="text-dark">
                  <strong>Course reviews</strong>
                </h5>
                <div className="row justify-content-left d-flex">
                  <div className="col-md-4 d-flex flex-column">
                    <div className="rating-box">
                      <h1 className="pt-4 main-rating">{`${ifStar}.0`}</h1>
                      <p className="sec-rating text-dark">{`${
                        member?.length || 0
                      } reviews`}</p>
                    </div>
                    <div
                      className="pt-5"
                      style={{ display: "flex", pointer: "cursor" }}
                    >
                      <Rating
                        name="simple-controlled"
                        value={starRating}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="rating-bar0 justify-content-center">
                      <table className="text-left mx-auto">
                        {/* {star.map((data, index) => { */}
                        <tr>
                          <td
                            className="rating-label"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            5 Star
                          </td>
                          <td className="rating-bar">
                            <div className="bar-container">
                              <BorderLinearProgress
                                variant="determinate"
                                value={star.five}
                              />
                            </div>
                          </td>
                          <td className="text-right">{star.five}</td>
                        </tr>
                        <tr>
                          <td
                            className="rating-label"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            4 Star
                          </td>
                          <td className="rating-bar">
                            <div className="bar-container">
                              <BorderLinearProgress
                                variant="determinate"
                                value={star.four}
                              />
                            </div>
                          </td>
                          <td className="text-right">{star.four}</td>
                        </tr>
                        <tr>
                          <td
                            className="rating-label"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            3 Star
                          </td>
                          <td className="rating-bar">
                            <div className="bar-container">
                              <BorderLinearProgress
                                variant="determinate"
                                value={star.three}
                              />
                            </div>
                          </td>
                          <td className="text-right">{star.three}</td>
                        </tr>
                        <tr>
                          <td
                            className="rating-label"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            2 star
                          </td>
                          <td className="rating-bar">
                            <div className="bar-container">
                              <BorderLinearProgress
                                variant="determinate"
                                value={star.two}
                              />
                            </div>
                          </td>
                          <td className="text-right">{star.two}</td>
                        </tr>
                        <tr>
                          <td
                            className="rating-label"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            1 Star
                          </td>
                          <td className="rating-bar">
                            <div className="bar-container">
                              <BorderLinearProgress
                                variant="determinate"
                                value={star.one}
                              />
                            </div>
                          </td>
                          <td className="text-right">{star.one}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              "No Review Found"
            )
          ) : (
            <div>
              <Skeleton height={80} />
              <Skeleton height={80} />
              <Skeleton height={80} />
              <Skeleton height={80} />
              <Skeleton height={80} />
            </div>
          )}
           {!reviewSection && ( 
            <div className="cards">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <label className="pl-1">Your Rating</label>
                  <div
                    className="pt-1"
                    style={{ display: "flex", pointer: "cursor" }}
                  >
                    <Rating
                      name="simple-controlled"
                      value={value}
                      onChange={(e, value) => ratingFunction(e, value)}
                      // onClick={postRating}
                    />
                  </div>
                </div>
                {value && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingTop: "10px",
                    }}
                  >
                    <label>Write a Review</label>
                    <TextField
                      id="outlined-multiline-static"
                      label="Review"
                      multiline
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      rows={6}
                      variant="outlined"
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingTop: "10px",
                      }}
                    >
                      <Button
                        disableElevation
                        onClick={postReview}
                        style={{
                          backgroundColor: "#794DF5",
                          color: "#ffffff",
                          textTransform: "capitalize",
                        }}
                      >
                        Post a Review
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
           )}
          <ToastContainer position="bottom-right" autoClose={3000} />
          {member?.length > 0 &&
            member?.map((data, index) => (
              <div className="cards">
                <div className="row d-flex">
                  <div className="">
                    <Avatar
                      alt={data?.userName ? data?.userName : "user"}
                      src="/broken-image.jpg"
                      style={{
                        backgroundColor: "#794DF5",
                        color: "#ffffff",
                        textTransform: "capitalize",
                      }}
                    />
                  </div>

                  <div className="d-flex flex-column">
                    <h5 className="mt-2 mb-0 pl-3">
                      {data?.userName ? data?.userName : "user"}
                    </h5>
                    <div className="mt-3 mb-0">
                      <p className="text-left">
                        <Rating
                          name="simple-controlled"
                          value={data.userRating}
                          readOnly
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row text-left">
                  <p className="comment-content">{data.userComment}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* <div className="text-center m-5">
        <a>
          <strong>View more</strong>
          &nbsp; <i className="fas fa-arrow-down"></i>
        </a>
      </div> */}
    </>
  );
}
