import React, { useState, useEffect } from "react";
import CustomTable from "./CustomListTableUser";
import { GetAllUsers } from "./utils/GetUsers";
import api from "../../api/baseConfig";
import ApiHelper from "../../api/apiHelper";
import ResetPasswordModel from "./ResetPasswordModel";
import { TrimString } from "pages/utils/TrimString";
import { toast, ToastContainer } from "react-toastify";
import { sessionPrivileges } from "privileges";

export default function CustomersList(props) {
  const userPrivileges = sessionPrivileges();
  const Priv = sessionPrivileges().userprivileges || [];
  const [passChangeId, setPassChangeId] = useState("");
  const [data, setData] = useState([]);
  const [dataLength, setDataLength] = useState("");
  const [modal, setModal] = useState(false);
  const [modalTable, setModalTable] = useState(false);
  const [password, setPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    newPassword: "",
  });

  const activePage = props?.location?.state?.activePage;
  const itemsPerPage = props?.location?.state?.itemsPerPage;

  const setAllUsers = (users) => {
    let tempData = parseRawData(users);

    setData(tempData);
  };
  const stringSeperatedValues = (array) => {
    let value = "";
    array.forEach((item, ind) => {
      if (typeof item === "string") {
        if (ind !== array.length - 1) {
          value += `${item}, `;
        } else {
          value += item;
        }
      }
    });
    return value;
  };
  const parseRawData = (data) => {
    let tempParsed = [];
    data.forEach((datum) => {
      tempParsed.push({
        name: datum.firstName + " " + datum.lastName,
        number: datum.phoneNumber,
        email: datum.emailId,
        role: datum.roles ? datum?.roles[0]?.name : "-",
        reportingTo:
          datum?.reportingTo?.length > 0
            ? stringSeperatedValues(datum?.reportingTo)
            : "-",
        id: datum.id,
        regno: datum?.registrationNo || "-",
      });
    });
    return tempParsed;
  };

  const getUserByCustomer = async (customer) => {
    const users = await GetAllUsers(customer);
    if (users) {
      let tempData = parseRawData(users);

      setData(tempData);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   if (!Priv.includes("MANAGE_CUSTOMER") && Priv.includes("VIEW_USERS_LIST")) {
  //     const customerId = sessionStorage.getItem("customer_id");
  //     getUserByCustomer(customerId);
  //   }
  //   if (props?.location?.state?.customer) {
  //     getUserByCustomer(props?.location?.state?.customer);
  //   }
  // }, []);

  const deleteUser = (id, customer) => {
    ApiHelper.axiosCall(
      `${api.baseURL}${api.userManagement.deleteUser}${id}`,
      "delete"
    )
      .then((d) => {
        getUserByCustomer(customer);
      })
      .catch((e) => {});
  };

  const deleteUsersAll = (id, customer) => {
     ApiHelper.axiosCall(
        `${api.baseURL}${api.userManagement.deleteUsersAll}?id=${id}`,
        "post"
      ).then((res) => {
           getUserByCustomer(customer);     
      })
      .catch((e) => {});
  };

  const updateUserPassword = async () => {
    const email = passChangeId;
    const url = `${api.baseURL}${
      api.userManagement.resetPassword
    }${email}/${password.newPassword}/${password.confirmPassword}`;
    await ApiHelper.axiosCallPost(TrimString(url), "put").then((data) => {
      if (data) {
        toast.success("Password reseted successfully", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
        setModal(false);
      } else {
        toast.error("There was error", {
          position: "bottom-center",
          autoClose: 300000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      }
    });
  };

  return (
    <> 
    {userPrivileges.showUserList && (
    <>
      <ResetPasswordModel
        isOpen={modal}
        onClose={() => {
          setModal(false);
          setPassword({ newPassword: "", confirmPassword: "" });
          // setModalTable(false);
        }}
        newPassword={password.newPassword}
        confirmPassword={password.confirmPassword}
        updateState={(newValue, type) =>
          setPassword({
            ...password,
            [type]: newValue,
          })
        }
        updateErrors={(error, type) => setErrors({ [type]: error })}
        errors={errors}
        update={(e) => {
          e.preventDefault(), updateUserPassword();
          setPassword({ newPassword: "", confirmPassword: "" });
        }}
      />
      <div className="page-content">
        <CustomTable
          onClickChange={(emailId) => {
            setPassChangeId(emailId);
            setModal(true);
          }}
          header="Users"
          createLink="/userManagement"
          data={data}
          deleteDatumAll={deleteUsersAll}
          setDataLength={setDataLength}
          dataHeader={
            data
              ? data.reduce((acc, val) => {
                  acc.push({
                    User_Name: val.name,
                    Contact_No: val.number,
                    email: val.email,
                    role: val.role,
                    reportingTo: val.reportingTo,
                    id: val.id,
                    regno: val.regno,
                  });
                  return acc;
                }, [])
              : []
          }
          dataLength={dataLength}
          tableHeaders={[
            { label: "User Name", val: "name" },
            { label: "Contact No", val: "number" },
            { label: "Email Id", val: "email" },
            { label: "Reg No.", val: "regno" },
            { label: "Reporting to", val: "reportingTo", maxWidth: 130 },
            { label: "Role", val: "role" },
          ]}
          searchPlaceholder="Search Users"
          setUsers={(users) => setAllUsers(users)}
          deleteDatum={deleteUser}
          modalTable={modalTable}
          setModalTable={setModalTable}
          showCustomers={Priv.includes("ADD_USER")}
          setCustomer={props?.location?.state?.customer}
          showAdd={Priv.includes("ADD_USER")}
          showBulk={Priv.includes("ADD_USER")}
          hideDelete={!Priv.includes("DELETE_USER")}
          hideEdit={!Priv.includes("EDIT_USER")}
          hideShowView={!Priv.includes("VIEW_USER_DETAILS")}
          hideResetPass={!Priv.includes("USER_RESET_PASSWORD")}
          activePage={activePage}
          itemsPerPage={itemsPerPage}
        />
      </div>
    </>
    )}
    </>
  );
}