import { useEffect, useState, useRef } from "react";
import Modal from "@material-ui/core/Modal";
import { Collapse, Button } from "reactstrap";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/styles";
import { FormLayout } from "components/FormLayout";
import { ErrorBlock } from "components/ErrorBlock";
import { FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Controller, useForm } from "react-hook-form";
import { ToastContainer, toast, cssTransition } from "react-toastify";

import { RequiredIndicator } from "pages/utils/RequiredIndicator";
import CircularProgress from "@material-ui/core/CircularProgress";
import SelectQuestionsQPModalNew from "pages/virtualLab/SelectQuestionsModal/SelectQuestionsQPModalNew";
// import { SelectQuestionsModal } from "pages/virtualLab/SelectQuestionsModal/SelectQuestionsQPModal";
import "./ViewQ.scss";

import api from "../../api/baseConfig";
import Apihelper from "../../api/apiHelper";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
  },
}));
function getModalStyle() {
  const top = 50,
    left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const CustomFormLabel = withStyles({
  label: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "0.9375rem",
    lineHeight: "1.375rem",
    color: "#323036",
  },
})(FormControlLabel);

const SectionFrom = ({
  index,
  dataKey,
  totalSections,
  initialValues,
  deleteSection,
  existingQuestions,
  questionBanks,
  sectionQuestions,
  setSectionQuestions,
  questionsToWatch,
  setQuestionsToWatch,
  gradingProcess,
  sessionPrivileges,
  handleInitialValues,
  updateInitialValues,
  assessmentQuestionPaperId,
  saveFormData,
  setSaveFormData,
}) => {
  const {
    control,
    setValue,
    register,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({ mode: "onBlur" });
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [loading, setLoading] = useState(true);
  const [toRemove, setToRemove] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [currBankQues, setCurrBankQues] = useState([]);
  const [currQuesBankTC, setCurrQuesBankTC] = useState("");
  const [openAddQuestion, setOpenAddQuestion] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isCollapseOpen, setIsCollapseOpen] = useState(
    index === totalSections - 1 ? true : false
  );
  const [isRandom, setIsRandom] = useState(
    initialValues[`section-${index}`]?.enableRandom ? true : false
  );
  const [errors, setErrors] = useState({
    timeErr: "",
    questionErr: "",
    nameErr: "",
    ind: "",
  });

  const [selectedQB, setSelectedQB] = useState({
    label: "All question bank",
    value: "all",
  });
  const [selectedQuestionId, setSelectedQuestionId] = useState([]);
  const [selectedQuestionList, setSelectedQuestionList] = useState(
    initialValues[`section-${index}`].questionList
  );
  const [toastCount, setToastCount] = useState(0);

  const btnStyle = {
    display: "flex",
    gap: 12,
    alignItems: "flex-start",
  };
  function getFormData(id) {
    return {
      cutOffMark: getValues(`cutOffMark-${index}`),
      description: getValues(`description-${index}`),
      enableRandom: initialValues[`${dataKey}`]?.enableRandom ? true : false,
      id: id,
      mark: getValues(`mark-${index}`),
      name: getValues(`sectionName-${index}`),
      negativeMark: getValues(`negativeMark-${index}`),
      questionList: initialValues[`${dataKey}`]?.questionList
        ? initialValues[`${dataKey}`].questionList
        : [],
      randomQuestionCount: getValues(`randomQuestionCount-${index}`),
      sectionOrder: index,
      timeLimit: getValues(`timeLimit-${index}`),
    };
  }
  const handleOpenAddQuestion = () => {
    setOpenAddQuestion(!openAddQuestion);
  };
  const handleEnableRandom = (event) => {
    const id = event.target.id;
    const enableValue = getValues(`enableRandom-${id}`);
    setValue(`enableRandom-${id}`, !enableValue);
    const temp = { ...initialValues };
    const isRandomQues = initialValues[`section-${index}`]?.enableRandom;
    setValue(`randomQuestionCount-${id}`, isRandomQues ? 0 : 3);
    setValue(`mark-${id}`, isRandomQues ? 0 : 1);
    temp[`section-${index}`] = {
      ...initialValues[`section-${index}`],
      mark: isRandomQues ? 0 : 1,
      randomQuestionCount: isRandomQues ? 0 : 3,
      enableRandom: !(isRandomQues ? true : false),
    };
    handleInitialValues({ ...temp });
  };
  const toastId = useRef(null);
  const handleClick = () => {
    if (initialValues[`section-${index}`]?.questionList?.length < 5) {
      const Zoom = cssTransition({
        enter: 'animate__animated animate__zoomIn',
        exit: 'animate__animated animate__zoomOut',
        collapseDuration: 300
      });
      toastId.current && toast.dismiss(toastId.current);
      toastId.current = toast.error("Please add atleast 5 questions to enable random switch", {
        toastId: `section-${index}${toastCount}`,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        transition: Zoom,
        newestOnTop: true,
      });
      setToastCount(toastCount+1)
    }
  };
  const handleMultiSectionQuestions = (data) => {
    let temp = [];
    for (let i = 0; i <= totalSections; i++) {
      temp.push(data);
    }
    setCurrBankQues(temp);
  };
  const setCurrQuestionBank = async (
    currI,
    idx,
    query,
    filterTopics,
    filterCategorys,
    filterTypes,
    filterComplexitys
  ) => {
    setLoading(true);
    let userId = sessionStorage.getItem("user_id");
    const payLoadParams = {
      search: currI === "all" ? query : "",
      qpId: currI,
      limit:
        currI === "all"
          ? query
            ? ""
            : filterTopics
            ? ""
            : filterCategorys
            ? ""
            : filterTypes
            ? ""
            : filterComplexitys
            ? ""
            : 10
          : "",
      pageIndex: currI === "all" ? idx : "",
      categoryIds: filterCategorys ? filterCategorys : [],
      topicIds: filterTopics ? filterTopics : [],
      types: filterTypes ? filterTypes : "",
      complexity: filterComplexitys ? filterComplexitys : [],
    };
    await Apihelper.getQuestionBankQues(
      `${api.baseURL}${
        api.questionBankController.getQBQuestions
      }${userId}`,
      "post",
      payLoadParams
    )
      .then((datum) => {
        let tempDatum = [];
        datum.questionList.forEach((j) => {
          j.remChecked = false;
          tempDatum.push(j);
        });
        handleMultiSectionQuestions(tempDatum, currI);
        setCurrQuesBankTC(datum.totalCount);
        setHasMore(tempDatum.length > 0);
        setLoading(false);
      })
      .catch((e) => {
        //setError(true);
      });
  };
  const setCheckedQuestions = (secId, questions) => {
    let temp = [...questionsToWatch];
    temp[secId] = questions;
    setQuestionsToWatch(temp);
  };
  const updateSectionQuestion = (list) => {
    const temp = { ...initialValues };
    temp[`section-${index}`] = {
      ...initialValues[`section-${index}`],
      questionList: list,
    };
    handleInitialValues({ ...temp });
  };
  const validate = (data) => {
    let isValidFrom = true;
    let timeErr = "";
    let questionErr = "";
    let nameErr = "";
    let randomQuestionErr = "";
    let marksErr = "";
    if (
      data[`sectionName-${index}`]?.trim().length === 0 ||
      data[`sectionName-${index}`] === undefined
    ) {
      isValidFrom = false;
      nameErr = "Please enter valid name";
    }
    if (initialValues[`section-${index}`]?.questionList?.length == 0) {
      isValidFrom = false;
      questionErr = "Please choose atleast one question";
    }
    if (
      data[`timeLimit-${index}`] == 0 ||
      data[`timeLimit-${index}`] == undefined
    ) {
      isValidFrom = false;
      timeErr = "Please enter valid time limit";
    }
    if (initialValues[`section-${index}`]?.enableRandom) {
      if (
        data?.[`randomQuestionCount-${index}`] == undefined ||
        data?.[`randomQuestionCount-${index}`] == 0
      ) {
        isValidFrom = false;
        randomQuestionErr = "please enter random question";
      }
      if (sectionQuestions[index]?.val?.length > 3) {
        if (
          data?.[`randomQuestionCount-${index}`] >
          sectionQuestions[index]?.val?.length
        ) {
          isValidFrom = false;
          randomQuestionErr = "please enter valid random question";
        }
      } else {
        if (
          data?.[`randomQuestionCount-${index}`] >
          existingQuestions[index]?.length
        ) {
          isValidFrom = false;
          randomQuestionErr = "please enter valid random question";
        }
      }
      if (
        data?.[`mark-${index}`] == undefined ||
        data?.[`mark-${index}`] == 0
      ) {
        isValidFrom = false;
        marksErr = "please enter marks";
      }
    }
    if (timeErr || questionErr || nameErr || randomQuestionErr || marksErr) {
      setErrors({
        timeErr,
        questionErr,
        nameErr,
        randomQuestionErr,
        marksErr,
        ind: dataKey,
      });
    } else if (
      errors.timeErr ||
      errors.questionErr ||
      errors.nameErr ||
      errors.randomQuestionErr ||
      errors.marksErr
    ) {
      setErrors({
        timeErr: "",
        questionErr: "",
        nameErr: "",
        randomQuestionErr: "",
        marksErr: "",
        ind: dataKey,
      });
    }
    return isValidFrom;
  };
  const submitSection = async (formDataValue) => {
    const userPrivileges = sessionPrivileges();
    // const editIndex =
    //   Object.keys(initialValues)?.length > 0 ? dataKey : dataKey - 1;
    if (validate(formDataValue)) {
      let hasDesc = false;
      if (hasDesc) {
        dispatch(change("questionPaperForm", "gradingProcess", "manual"));
      }
      setButtonDisable(true);
      updateInitialValues(
        dataKey,
        getFormData(initialValues[`${dataKey}`]?.id)
      );
      setSaveFormData(index);
      const questionListValue = initialValues[`${dataKey}`]?.questionList;
      const questionIdList = [...questionListValue].reduce((acc, val) => {
        if (val?.id) {
          acc.push(val.id);
        } else if (val[0]?.id) {
          val.map((e) => {
            acc.push(e.id);
          });
        } else {
          acc.push(val);
        }
        return acc;
      }, []);
      const idData = initialValues[`${dataKey}`]?.id
        ? { id: initialValues[`${dataKey}`].id }
        : {};
      let body = {
        // ...data,
        ...idData,
        active: true,
        // id: initialValues[`${dataKey}`]?.id? ,
        assessmentQuestionPaperId,
        name: formDataValue[`sectionName-${index}`],
        description: formDataValue[`description-${index}`],
        timeLimit: formDataValue[`timeLimit-${index}`],
        negativeMark: formDataValue[`negativeMark-${index}`],
        cutOffMark: formDataValue[`cutOffMark-${index}`],
        enableRandom: initialValues[`${dataKey}`]?.enableRandom ? true : false,
        randomQuestionCount: formDataValue?.[`randomQuestionCount-${index}`],
        mark: formDataValue?.[`mark-${index}`],
        createdBy: userPrivileges.userName,
        createdDate: Date.now(),
        updatedBy: userPrivileges.userName,
        updatedDate: Date.now(),
        instruction: "string",
        sectionOrder: index,
        questionIdList,
      };
      const Gradebody = {
        id: assessmentQuestionPaperId,
        gradingProcess: hasDesc ? "manual" : gradingProcess || "manual",
        updatedBy: userPrivileges.userName,
      };
      try {
        const data = await Apihelper.axiosCallPost(
          `${api.baseURL}${api.QuestionPaperController.createSection}`,
          "post",
          body
        );
        setIsCollapseOpen(false);
        toast.success(
          `${formDataValue[`sectionName-${index}`]} section is saved`,
          {
            position: "bottom-right",
            style: { zIndex: 2000 },
          }
        );
        if (data.id) {
          updateInitialValues(dataKey, getFormData(data.id));
        }
      } catch (err) {
        console.log(err);
      }
      try {
        Apihelper.axiosCallPost(
          `${api.baseURL}${api.QuestionPaperController.updateGradingProcess}`,
          "post",
          Gradebody
        );
      } catch (err) {
        console.log(err);
      }
    }
    setButtonDisable(false);
  };
  const openConformModal = () => {
    setSaveFormData(index);
    setDeleteModal(true);
  };
  const deleteButtonValue = () => {
    setIsCollapseOpen(false);
    deleteSection(index);
    setDeleteModal(false);
  };

  useEffect(() => {
    setIsCollapseOpen(index === totalSections - 1 ? true : false);
  }, [totalSections]);

  useEffect(() => {
    setValue(`sectionName-${index}`, initialValues[`section-${index}`]?.name);
    setValue(
      `description-${index}`,
      initialValues[`section-${index}`]?.description
    );
    setValue(
      `timeLimit-${index}`,
      initialValues[`section-${index}`]?.timeLimit
    );
    setValue(
      `negativeMark-${index}`,
      initialValues[`section-${index}`]?.negativeMark
    );
    setValue(
      `cutOffMark-${index}`,
      initialValues[`section-${index}`]?.cutOffMark
    );
    setValue(
      `randomQuestionCount-${index}`,
      initialValues[`section-${index}`]?.randomQuestionCount
    );
    setValue(`mark-${index}`, initialValues[`section-${index}`]?.mark);
    setValue(
      `enableRandom-${index}`,
      initialValues[`section-${index}`]?.enableRandom
    );
  }, []);

  useEffect(() => {
    setIsRandom(initialValues[`section-${index}`]?.enableRandom ? true : false);
  }, [initialValues]);

  // useEffect(() => {
  //   const useUpdateInitialValues = updateInitialValues;
  //   const useGetFormData = getFormData;
  //   if (saveFormData !== "") {
  //     if (saveFormData === index) {
  //       setSaveFormData("");
  //     }
  //     useUpdateInitialValues(dataKey, useGetFormData());
  //   }
  // }, [saveFormData]);

  // useEffect(() => {
  //   const useUpdateInitialValues = updateInitialValues;
  //   const useGetFormData = getFormData;

  //   return () => {
  //     useUpdateInitialValues(dataKey, useGetFormData());
  //   };
  // }, []);

  useEffect(() => {
    updateSectionQuestion(selectedQuestionList);
    const arrId = [];
    selectedQuestionList.length > 0 &&
      selectedQuestionList.map((ques) => arrId.push(ques.id));
    setSelectedQuestionId(arrId);
  }, [selectedQuestionList]);

  return (
    <div key={index}>
      <div style={{ marginBottom: 20 }}>
        <div
          onClick={() => {
            setIsCollapseOpen(!isCollapseOpen);
          }}
          style={{
            fontSize: 15,
            borderRadius: "6px 6px 0px 0px",
            boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.33)",
            cursor: "pointer",
            backgroundColor: "#3A3A3A",
            padding: "12px 16px",
            display: "flex",
            color: "#ffffff",
            justifyContent: "space-between",
          }}
        >
          <div>{`Section ${index + 1}`}</div>
          <div>
            {isCollapseOpen ? (
              <svg
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.05859 10.1216L7.99759 5.18158L12.9366 10.1216L15.0586 7.99958L7.99759 0.939582L0.936593 7.99958L3.05859 10.1216Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.9375 0.938965L7.9985 5.87896L3.0595 0.938965L0.9375 3.06096L7.9985 10.121L15.0595 3.06096L12.9375 0.938965Z"
                  fill="white"
                />
              </svg>
            )}
          </div>
        </div>
        <Collapse isOpen={isCollapseOpen} style={{ margin: 0 }}>
          <div style={{ paddingTop: 24 }}>
            <form
              onSubmit={handleSubmit((data) => {
                submitSection(data);
                // submittedValue(item, data, index)
              })}
            >
              <FormLayout
                description="Enter the section details"
                title="Section Details"
              >
                <div>
                  <label className="terv-label">
                    {RequiredIndicator("Section Name")}
                  </label>
                  <input
                    name={`sectionName`}
                    id={`sectionName-${index}`}
                    {...register(`sectionName-${index}`)}
                    className="terv-formNew terv-form-full"
                    controlledValue={initialValues[dataKey].name}
                  />
                  {errors.nameErr && errors.ind === dataKey ? (
                    <ErrorBlock
                      className="terv-formHelperTextNew"
                      eType="span"
                      errorText={errors.nameErr}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <label className="terv-label">{"Description"}</label>
                  <input
                    id={`description-${index}`}
                    {...register(`description-${index}`)}
                    className="terv-formNew terv-form-full"
                  />
                </div>
                <div style={{ display: "flex", gap: 12 }}>
                  <div>
                    <label className="terv-label">
                      {RequiredIndicator("Time Limit")}
                    </label>
                    <input
                      type="number"
                      min="1"
                      max="100000"
                      placeholder="0"
                      id={`timeLimit-${index}`}
                      {...register(`timeLimit-${index}`)}
                      className="terv-formNew"
                    />
                    {errors.timeErr && errors.ind === dataKey ? (
                      <ErrorBlock
                        className="terv-formHelperTextNew"
                        eType="span"
                        errorText={errors.timeErr}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <label className="terv-label">Negative Mark</label>
                    <input
                      type="number"
                      min="0"
                      max="100000"
                      id={`negativeMark-${index}`}
                      placeholder="0"
                      {...register(`negativeMark-${index}`)}
                      className="terv-formNew"
                    />
                  </div>
                  <div>
                    <label className="terv-label">Cut off</label>
                    <input
                      type="number"
                      placeholder="0"
                      min="0"
                      max="100000"
                      id={`cutOffMark-${index}`}
                      {...register(`cutOffMark-${index}`)}
                      className="terv-formNew terv-form-full"
                    />
                  </div>
                </div>
                <div className="py-2 mb-3">
                  <div className="AlertQp">
                    <span style={{ color: "#2170D8", fontWeight: "500" }}>
                      {`No of questions added : ${
                        initialValues[`section-${index}`]?.questionList?.length
                      }`}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    background: "#FAFBFC",
                    border: "1px solid #F2F1F3",
                    borderRadius: 6,
                    padding: 16,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 16,
                    marginBottom: 16,
                  }}
                  onClick={() => {
                    handleClick();
                  }}
                >
                  <div>
                    <div>
                      <h5 style={{ fontSize: 14, color: "#19181B" }}>
                        Enable Randomly generate questions for assessment
                      </h5>
                      <p style={{ fontSize: 12, color: "#96939F" }}>
                        Random questions will be generated from selected
                        questions during assessment
                      </p>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 12,
                        }}
                      >
                        <div style={{ width: "50%" }}>
                          <h6
                            style={
                              initialValues[`section-${index}`]?.enableRandom
                                ? { fontSize: 14, color: "#323036" }
                                : { fontSize: 14, color: "#D8D6DB" }
                            }
                          >
                            No of random questions
                          </h6>
                          <div>
                            <div>
                              <input
                                placeholder="0"
                                min={
                                  initialValues[`section-${index}`]
                                    ?.enableRandom
                                    ? "1"
                                    : "0"
                                }
                                max="1999999"
                                type="number"
                                id={`randomQuestionCount-${index}`}
                                {...register(`randomQuestionCount-${index}`)}
                                className="terv-formNew"
                                disabled={!isRandom}
                              />
                            </div>
                            {errors.randomQuestionErr &&
                            errors.ind === dataKey ? (
                              <ErrorBlock
                                className="terv-formHelperTextNew"
                                eType="span"
                                errorText={errors.randomQuestionErr}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div style={{ width: "50%" }}>
                          <h6
                            style={
                              initialValues[`section-${index}`]?.enableRandom
                                ? { fontSize: 14, color: "#323036" }
                                : { fontSize: 14, color: "#D8D6DB" }
                            }
                          >
                            Marks for each question
                          </h6>
                          <div>
                            <div>
                              <input
                                placeholder="0"
                                min={
                                  initialValues[`section-${index}`]
                                    ?.enableRandom
                                    ? "1"
                                    : "0"
                                }
                                max="1999999"
                                type="number"
                                id={`mark-${index}`}
                                {...register(`mark-${index}`)}
                                className="terv-formNew"
                                disabled={!isRandom}
                              />
                            </div>
                            {errors.marksErr && errors.ind === dataKey ? (
                              <ErrorBlock
                                className="terv-formHelperTextNew"
                                eType="span"
                                errorText={errors.marksErr}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      {initialValues[`section-${index}`]?.questionList?.length <
                        5 && (
                        <p
                          style={{ fontSize: 12, color: "#96939F", margin: 0 }}
                        >
                          Note: Minimum 5 questions required to enable random
                          question
                        </p>
                      )}
                    </div>
                  </div>
                  <div>
                    <Controller
                      name={`enableRandom-${index}`}
                      id={`enableRandom-${index}`}
                      control={control}
                      render={({ field: { onChange, value, onBlur } }) => (
                        <CustomFormLabel
                          control={
                            <Switch
                              id={index}
                              checked={
                                initialValues[`section-${index}`]?.enableRandom
                                  ? true
                                  : false
                              }
                              disabled={
                                initialValues[`section-${index}`]?.questionList
                                  ?.length >= 5
                                  ? false
                                  : true
                              }
                              onChange={handleEnableRandom}
                              color="primary"
                            />
                          }
                        />
                      )}
                    />
                  </div>
                </div>

                <div style={{ ...btnStyle, justifyContent: "space-between" }}>
                  <div style={{ ...btnStyle }}>
                    <Button
                      color="success"
                      onClick={() => handleOpenAddQuestion()}
                      style={{ minWidth: 120 }}
                    >
                      Questions
                    </Button>
                    <div>
                      <Button
                        color="primary"
                        type="submit"
                        disabled={buttonDisable}
                        style={{ minWidth: 120 }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                  <Button
                    outline
                    color="danger"
                    onClick={() => openConformModal()}
                    style={{ minWidth: 120 }}
                  >
                    Delete Section
                  </Button>
                </div>
                {errors.questionErr && errors.ind === dataKey ? (
                  <div>
                    <ErrorBlock
                      className="terv-formHelperTextBlock"
                      eType="span"
                      errorText={errors.questionErr}
                    />
                  </div>
                ) : (
                  ""
                )}
              </FormLayout>
            </form>
          </div>
        </Collapse>
        {openAddQuestion && (
          <SelectQuestionsQPModalNew
            open={openAddQuestion}
            handleOpenClose={() => {
              handleOpenAddQuestion();
              setCurrBankQues([]);
            }}
            listQB={questionBanks}
            selectedQB={selectedQB}
            changeSelectedQB={(qb) => setSelectedQB(qb)}
            selectedQuestionId={selectedQuestionId}
            setSelectedQuestionId={(q) => setSelectedQuestionId(q)}
            selectedQuestionList={selectedQuestionList}
            setSelectedQuestionList={(list) => setSelectedQuestionList(list)}
            sectionIndex={dataKey}
            initialValues={initialValues}
          />
          // <SelectQuestionsModal
          //   modalState={openAddQuestion}
          //   propsWithFullQuesData={true}
          //   setModal={() => {
          //     handleOpenAddQuestion();
          //     setCurrBankQues([]);
          //   }}
          //   currBankQues={currBankQues[index] ? currBankQues[index] : []}
          //   existingQuestions={existingQuestions[index] || []}
          //   questionsType="all"
          //   loading={loading}
          //   currBankQuesTotalCount={currQuesBankTC}
          //   hasMore={hasMore}
          //   multiSections
          //   onRemove={(cb) => {
          //     cb?.();
          //   }}
          //   toRemove={toRemove}
          //   remove={(list, val) => {
          //     let temp = [...sectionQuestions];
          //     temp[index].val = val;
          //     setSectionQuestions(temp);
          //     if (list.length > 0) {
          //       let temp = [...toRemove];
          //       if (temp.hasOwnProperty(index)) {
          //         temp[index] = [...temp[index], ...list];
          //       } else {
          //         temp[index] = list;
          //       }

          //       setToRemove(temp);
          //     }
          //   }}
          //   sectionIndex={index}
          //   findQuestion={(id) => {
          //     let secObj = {};
          //     let returnValue = null;
          //     sectionQuestions.forEach((sec, ind) => {
          //       secObj[ind] = sec.val;
          //     });
          //     existingQuestions.forEach((item, ind) => {
          //       secObj[ind] = [
          //         ...item.reduce((acc, val) => {
          //           if (!secObj[ind].includes(val.id)) {
          //             acc.push(val.id);
          //           }
          //           return acc;
          //         }, []),
          //         ...secObj[ind],
          //       ];
          //     });
          //     questionsToWatch.forEach((item, ind) => {
          //       secObj[ind] = [
          //         ...item.reduce((acc, val) => {
          //           if (!secObj[ind].includes(val.id)) {
          //             acc.push(val.id);
          //           }
          //           return acc;
          //         }, []),
          //         ...secObj[ind],
          //       ];
          //     });
          //     for (const key in secObj) {
          //       if (secObj[key].includes(id)) {
          //         returnValue = key;
          //       }
          //     }
          //     return returnValue;
          //   }}
          //   setCheckedQuestions={setCheckedQuestions}
          //   setCurrQuestionBank={setCurrQuestionBank}
          //   QBlist={questionBanks}
          //   setQIds={updateSectionQuestion}
          //   // setQuestionsArray={setQuestionsArray}
          //   setNewQuestion={(queslist, cb) => {
          //     updateSectionQuestion(queslist);
          //     typeof cb === "function" && cb();
          //   }}
          // />
        )}
        {deleteModal && (
          <Modal
            open={deleteModal}
            onClose={() => setDeleteModal(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div style={modalStyle} className={classes.paper}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "26px",
                    color: "#19181B",
                  }}
                >
                  {`Are you sure want to Delete this section ?`}
                </p>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "14px",
                    lineHeight: "26px",
                    color: "#63606C",
                    textAlign: "center",
                  }}
                >
                  This section and content inside this section will be deleted
                  and cannot be restored
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ paddingRight: "24px" }}>
                  <button
                    style={{
                      background: "#F2F1F3",
                      paddingLeft: "38px",
                      paddingRight: "37px",
                      paddingTop: "8px",
                      paddingBottom: "7px",
                      border: "none",
                      borderRadius: "3px",
                    }}
                    onClick={() => setDeleteModal(false)}
                  >
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "14px",
                        lineHeight: "26px",
                        color: "#4A4851",
                        textAlign: "center",
                      }}
                    >
                      Cancel
                    </span>
                  </button>
                </div>
                <div>
                  <button
                    style={{
                      background: "#D42216",
                      color: "#ffffff",
                      paddingLeft: "38px",
                      paddingRight: "37px",
                      paddingTop: "8px",
                      paddingBottom: "7px",
                      border: "none",
                      borderRadius: "3px",
                    }}
                    onClick={() => deleteButtonValue()}
                  >
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "14px",
                        lineHeight: "26px",
                        color: "#ffffff",
                        textAlign: "center",
                      }}
                    >
                      Delete
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};
export const SectionArrayTest = ({
  name,
  initialValues,
  handleInitialValues,
  updateInitialValues,
  questionBanks,
  setQuestionBanks,
  sessionPrivileges,
  gradingProcess,
  assessmentQuestionPaperId,
  ...props
}) => {
  const [initialData, setInitialData] = useState({ ...initialValues });
  const [hover, setHover] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState("");
  const [saveFormData, setSaveFormData] = useState("");
  const [existingQuestions, setExistingQuestions] = useState([]);
  const [sectionQuestions, setSectionQuestions] = useState([]);
  const [questionsToWatch, setQuestionsToWatch] = useState([]);
  const [totalSections, setTotalSections] = useState(0);
  const addNewSection = () => {
    const temp = { ...initialData };
    temp[`section-${totalSections}`] = {
      cutOffMark: 0,
      description: "",
      enableRandom: false,
      id: "",
      mark: 0,
      name: "",
      negativeMark: 0,
      questionList: [],
      randomQuestionCount: 0,
      sectionOrder: 0,
      timeLimit: 0,
    };
    handleInitialValues({ ...temp });
  };
  const removeData = (index) => {
    setLoadingState(true);
    const temp = { ...initialData };
    const initialKeys = Object.keys(temp);
    let found = false;
    if (initialKeys.length > 0) {
      initialKeys.map((item, i) => {
        if (found) {
          temp[`section-${i - 1}`] = temp[`section-${i}`];
        } else if (index === i) {
          found = true;
        }
      });
      delete temp[`section-${initialKeys.length - 1}`];
    }
    handleInitialValues({ ...temp });
    toast.error("section deleted sucessfully", {
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
    });
    setTimeout(() => {
      setLoadingState(false);
      setLoadingIndex("");
    });
  };
  const deleteSection = (index) => {
    setLoadingIndex(index);
    const thisSectionId = initialData[`section-${index}`]?.id;
    if (thisSectionId)
      deleteSectionApi(initialData[`section-${index}`]?.id, index);
    else removeData(index);
  };
  const deleteSectionApi = async (id, index) => {
    try {
      const data = await Apihelper.axiosCallPost(
        `${api.baseURL}${api.QuestionPaperController.deleteSection}/${id}`,
        "delete"
      );
      removeData(index);
    } catch (e) {
      setLoadingIndex("");
      toast.error("section delete failed", {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
      });
    }
  };
  const handleMouseIn = () => {
    setHover(true);
  };
  const handleMouseOut = () => {
    setHover(false);
  };
  useEffect(() => {
    const initialKeys = Object.keys(initialData);
    setTotalSections(initialKeys.length);
    if (initialKeys.length > 0) {
      let tempAllQuestions = [],
        tempSection = [],
        tempToWatch = [];
      initialKeys.map((item) => {
        tempSection.push({ val: [] });
        tempToWatch.push([]);
        tempAllQuestions.push(
          initialData[item]?.questionList ? initialData[item].questionList : []
        );
      });
      setQuestionsToWatch([...tempToWatch]);
      setSectionQuestions([...tempSection]);
      setExistingQuestions([...tempAllQuestions]);
    }
  }, [initialData]);

  useEffect(() => {
    setInitialData(initialValues);
  }, [initialValues]);

  return (
    <div>
      {!loadingState &&
        Object.keys(initialData).map((key, index) => (
          <SectionFrom
            key={key}
            index={index}
            dataKey={key}
            totalSections={totalSections}
            initialValues={initialData}
            deleteSection={deleteSection}
            handleInitialValues={handleInitialValues}
            updateInitialValues={updateInitialValues}
            existingQuestions={existingQuestions}
            sectionQuestions={sectionQuestions}
            setSectionQuestions={setSectionQuestions}
            questionsToWatch={questionsToWatch}
            setQuestionsToWatch={setQuestionsToWatch}
            questionBanks={questionBanks}
            sessionPrivileges={sessionPrivileges}
            gradingProcess={gradingProcess}
            assessmentQuestionPaperId={assessmentQuestionPaperId}
            saveFormData={saveFormData}
            setSaveFormData={(index) => setSaveFormData(index)}
          />
        ))}
      <hr />
      <br />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 8,
          justifyContent: "center",
        }}
      >
        <Button
          block
          outline
          size="lg"
          color="primary"
          onClick={() => addNewSection()}
          style={{ maxWidth: 480 }}
          onMouseOver={handleMouseIn}
          onMouseOut={handleMouseOut}
        >
          <span
            style={{
              display: "flex",
              gap: 8,
              fontSize: 14,
              fontWeight: 500,
              alignItems: "center",
              justifyContent: "center",
              padding: "3px 0",
            }}
          >
            <svg
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.25 5.25V0H5.75V5.25H0.5V6.75H5.75V12H7.25V6.75H12.5V5.25H7.25Z"
                fill={hover ? "#ffffff" : "#794DF5"}
              />
            </svg>
            {totalSections == 0 ? "Add Section" : "Add Another Section"}
          </span>
        </Button>
      </div>
      <br />
      {loadingIndex !== "" && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0, 0, 0, 0.2)",
            zIndex: 100,
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}
    </div>
  );
};
