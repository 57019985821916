import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import style from "./viewSolutionModal.module.scss";
import ReactHtmlParser from "react-html-parser";

const closeIcons = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
      fill="#323036"
    />
  </svg>
);

const wrongIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99977 0.76001C7.54917 0.76001 5.19894 1.73351 3.4661 3.46634C1.73326 5.19918 0.759766 7.54941 0.759766 10C0.759766 12.4506 1.73326 14.8008 3.4661 16.5337C5.19894 18.2665 7.54917 19.24 9.99977 19.24C12.4504 19.24 14.8006 18.2665 16.5334 16.5337C18.2663 14.8008 19.2398 12.4506 19.2398 10C19.2398 7.54941 18.2663 5.19918 16.5334 3.46634C14.8006 1.73351 12.4504 0.76001 9.99977 0.76001ZM15.2677 13.3671L13.3658 15.269L9.99977 11.9019L6.63267 15.2679L4.73077 13.366L8.09897 10L4.73187 6.63291L6.63377 4.73211L9.99977 8.09811L13.3669 4.73101L15.2688 6.63291L11.9006 10L15.2677 13.3671Z"
      fill="#E22B12"
    />
  </svg>
);

const rightIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10H0ZM9.42933 14.28L15.1867 7.08267L14.1467 6.25067L9.23733 12.3853L5.76 9.488L4.90667 10.512L9.42933 14.2813V14.28Z"
      fill="#5AC62F"
    />
  </svg>
);

const emptyIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="18"
      height="18"
      rx="4"
      stroke="#848484"
      strokeWidth="2"
    />
  </svg>
);

const ViewSolutionModal = ({ isOpen, close, questionData }) => {
  const [solution, setSolution] = useState("yourCode");
  const [language, setLanguage] = useState("");
  return (
    <Dialog open={isOpen} onClose={close} fullWidth maxWidth="md">
      <DialogTitle
        onClose={close}
        style={{ textAlign: "center", backgroundColor: "#F9F9F9" }}
      >
        Solution
        <span onClick={close} style={{ float: "right", cursor: "pointer" }}>
          {closeIcons}
        </span>
      </DialogTitle>
      <DialogContent dividers style={{ height: "900px" }}>
        <div>
          <div className={style.questionTitle}>Question</div>
          <div
            className={style.questionSection}
            dangerouslySetInnerHTML={{ __html: questionData?.question }}
          ></div>
        </div>
        {questionData?.type === "CD" && (
          <>
            <div className={style.questionCodeSec}>
              <div style={{ display: "flex" }}>
                <div
                  className={`${
                    solution === "yourCode"
                      ? style.activeTab
                      : style.questionTitle
                  }`}
                  onClick={() => setSolution("yourCode")}
                >
                  Your code
                </div>
                <div
                  className={`${
                    solution === "solution"
                      ? style.activeTab
                      : style.questionTitle
                  }`}
                  onClick={() => setSolution("solution")}
                >
                  Solution
                </div>
                <div
                  className={`${
                    solution === "sampleOutput"
                      ? style.activeTab
                      : style.questionTitle
                  }`}
                  onClick={() => setSolution("sampleOutput")}
                >
                  Sample output
                </div>
                <div
                  className={`${
                    solution === "sampleInput"
                      ? style.activeTab
                      : style.questionTitle
                  }`}
                  onClick={() => setSolution("sampleInput")}
                >
                  Sample input
                </div>
              </div>
              {solution === "yourCode" ? (
                <div className={style.questionBox}>
                  <label>
                    Language:&nbsp;&nbsp;
                    <select>
                      <option>{questionData?.userLanguage}</option>
                    </select>
                  </label>
                  <div>
                    {questionData?.codeSolution != null
                      ? window.atob(questionData?.codeSolution)
                      : questionData?.codeSolution}
                  </div>
                </div>
              ) : solution === "solution" ? (
                <div className={style.questionBox}>
                  <label>
                    Language:&nbsp;&nbsp;
                    <select
                      value={language}
                      onChange={(e) => setLanguage(e.target.value)}
                    >
                      {questionData?.questionSolutionList.map((list, i) => (
                        <option key={i} value={list.language}>
                          {list.language}
                        </option>
                      ))}
                    </select>
                  </label>
                  <div>
                    {
                      questionData?.questionSolutionList.find(
                        (e) => e.language === language
                      )?.questionSolution
                    }
                  </div>
                </div>
              ) : solution === "sampleOutput" ? (
                <div className={style.questionBox}>
                  {ReactHtmlParser(questionData?.sampleOutput)}
                </div>
              ) : (
                <div className={style.questionBox}>
                  {ReactHtmlParser(questionData?.sampleInput)}
                </div>
              )}
            </div>
            {questionData?.status === "wrong" ? (
              <div className={style.wrong}>
                <span>You answered</span>
                <span className={style.bold}>{wrongIcon} Wrong</span>
              </div>
            ) : questionData?.status === "correct" ? (
              <div className={style.right}>
                <span>You answered</span>
                <span className={style.bold}>{rightIcon} Right</span>
              </div>
            ) : questionData?.status === "unAttempted" ? (
              <div className={style.unAttempted}>
                <span>You answered</span>
                <span className={style.bold}>{emptyIcon} Un attempted</span>
              </div>
            ) : (
              ""
            )}
          </>
        )}
        {questionData?.type === "MC" && (
          <div className={style.mcQuestions}>
            <div className={style.questionTitle}>Solution</div>
            <div className={style.questionBoxTwo}>
              {questionData?.questionOptions.map((d, i) => (
                <div className={style.optionRow}>
                  <div
                    className={`${style.option} ${
                      d.answer
                        ? style.optionsRight
                        : questionData?.selectedOptions.indexOf(d.id) > -1
                        ? style.optionsWrong
                        : ""
                    }`}
                    key={i}
                  >
                    <p dangerouslySetInnerHTML={{ __html: d.optionValue }}></p>
                    {questionData?.selectedOptions.indexOf(d.id) > -1 && (
                      <span>
                        {d.answer ? rightIcon : wrongIcon} Your choice
                      </span>
                    )}
                  </div>
                  {d.answer && <span>Correct answer</span>}
                </div>
              ))}
            </div>
          </div>
        )}
        {questionData?.type === "DESC" && (
          <div className={style.mcQuestions}>
            <div className={style.questionTitle}>Solution</div>
            <div className={style.questionBoxTwo}>
              {questionData?.codeSolution != null
                ? questionData?.codeSolution
                : questionData?.codeSolution}
            </div>
            <div className="pt-3">
              {questionData?.status === "wrong" ? (
                <div className={style.wrong}>
                  <span>You answered</span>
                  <span className={style.bold}>{wrongIcon} Wrong</span>
                </div>
              ) : questionData?.status === "correct" ? (
                <div className={style.right}>
                  <span>You answered</span>
                  <span className={style.bold}>{rightIcon} Right</span>
                </div>
              ) : questionData?.status === "unAttempted" ? (
                <div className={style.unAttempted}>
                  <span>You answered</span>
                  <span className={style.bold}>{emptyIcon} Un attempted</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ViewSolutionModal;
