import React, { Component } from "react";
import {
  Button,
  Table,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import DataTable from "../Utility/communicationMod/DataTable";
import styles from "../Utility/communicationMod/DataTable.module.scss";
import { SortData } from "../utils/SortData";
import * as CustomIcons from "../../assets/icons/icons.js";
import CustomSearch from "../Tasks/CustomSearch";
import Pagination from "react-js-pagination";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { paginationClasses } from "../utils/constants";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import BulkQuestionUpload from "../CustomerManagement/BulkQuestionUpload";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { ToastContainer } from "react-toastify";
import api from "../../api/baseConfig";
import Apihelper from "../../api/apiHelper";
import { SelectQuestionsModal } from "../virtualLab/SelectQuestionsModal/SelectQuestionsModal";
import { SelectQuestionsModalQB } from "../virtualLab/SelectQuestionsModal/SelectQuestionsModalQB";
import { Paper } from "@material-ui/core";
import { uid } from "react-uid";
import { sessionPrivileges } from "privileges";

const arrowStyle = {
  color: "#adb5bd",
  cursor: "pointer",
};

const tableHeaders = [
  { label: "Name", val: "name" },
  { label: "Type", val: "type" },
  { label: "Complexity", val: "complexity" },
];
const entriesCount = [5, 10, 15, 20];

const setFilters = ['all', 'easy', 'medium', 'hard'];

const setFiltersType = ['all', 'MC', 'CD', 'DESC']

const questionTypes = [
  { value: "MC", label: "Multi Choice" },
  { value: "CD", label: "Coding" },
  { value: "DESC", label: "Descriptive" },
  { value: "all", label: "All" },
];

export default class ViewQuestionList extends Component {
  constructor(props) {
    super(props);
    this.userPrivileges = sessionPrivileges(true);
    this.userPriv = this.userPrivileges.userprivileges || [];
    this.Priv = {
      edit: this.userPriv.includes("EDIT_QUESTIONBANK"),
      author: this.userPriv.includes("AUTHOR_ROLE"),
      admin: this.userPriv.includes("ADMIN_ROLE"),
      SA: this.userPriv.includes("MANAGE_CUSTOMER"),
      adminAdd: this.userPriv.includes("ADD_QUESTION_BANK_QUESTIONS"),
      adminEdit: this.userPriv.includes("EDIT_QUESTION_BANK_QUESTIONS"),
    };
    this.state = {
      bulkUploadVisibility: false,
      breadName:
        this.props.location?.state?.viewQues === true
          ? this.props.location?.state?.viewQuesTableNameandId?.name
          : this.props.location?.state?.name,
      from:
        this.props.location?.state?.viewQues === true
          ? this.props.location?.state?.viewQuesTableNameandId?.from
          : this.props.location?.state?.from,
      questions: [],
      questionIdList: [],
      realQuestions: [],
      activePage: 1,
      modal: false,
      downArrow: false,
      copyModal: false,
      itemsPerPage: 10,
      deleteId: "",
      QuestionTypeVal: [],
      QuestionTypeValues: "",
      ComplexityValue: [],
      allValue: [],
      easyValue: [],
      mediumValue: [],
      hardValue: [],
      ComplexityValues: "",
      quesBankId:
        this.props.location?.state?.viewQues === true
          ? this.props.location?.state?.viewQuesTableNameandId?.id
          : this.props.location?.state?.id,
      dataTable: false,
      modal: false,
      listQB: [],
      loading: true,
      error: false,
      hasMore: false,
      currBankQues: [],
      localBanks: {},
      currBank: "",
      SQM_reset: false,
      customerId: props?.location?.state?.customerId,
      activeTab: props?.location?.state?.activeTab,
      activePagee: props?.location?.state?.activePagee
        ? props?.location?.state?.activePagee
        : props?.location?.state?.activePage,
      currentPage: props?.location?.state?.currentPage,
      customerName: props?.location?.state?.currentName,
    };
  }
  componentDidMount() {
    this.getQBQuestionList();
    this.getAllQuestionBanks();
    if (this.props.location?.state?.activePage) {
      this.setState({ activePage: this.props.location?.state?.activePage });
    }
    if (this.props.location?.state?.defaultPage) {
      this.setState({ activePage: this.props.location?.state?.defaultPage });
    }
    if (this.props.location?.state?.itemsPerPage) {
      this.setState({ itemsPerPage: this.props.location?.state?.itemsPerPage });
    }
    for (const pageProperty in this.state.activePagee) {
      if (pageProperty === `${this.state.activeTab}` + "List") {
        this.setState({
          currentPage: {
            tabName: pageProperty,
            pageNo: this.state.activePagee[pageProperty],
          },
        });
      }
    }
  }

  getAllQuestionBanks = async () => {
    await Apihelper.axiosCall(
      `${api.baseURL}${api.questionBankController.ApproveBank}`,
      "get"
    ).then((datum) => {
      const allFilterTypes = [{ label: "All question bank", value: "all" }];
      let tempList = allFilterTypes.filter((item) => item.value === "all");
      datum?.forEach((i) => {
        if (i.id !== this.state.quesBankId) {
          tempList.push({ value: i.id, label: i.name });
        }
      });
      this.setState({ listQB: tempList });
    });
  };

  toggleArrow = () => this.setState({ downArrow: !this.state.downArrow });

  sortData = (value, sortType) => {
    const sortArray = SortData(this.state.questions, value, sortType);
    this.setState({ questions: sortArray });
  };

  setCurrQuestionBank = async (
    currI,
    idx,
    query,
    filterTopics,
    filterCategorys,
    filterTypes,
    filterComplexitys
  ) => {
    this.setState({ loading: true, error: false });
    let userId = sessionStorage.getItem("user_id"); //sessionStorage.getItem("applicable_authors")
    const payLoadParams = {
      search: currI === "all" ? query : "",
      qpId: this.state.quesBankId,
      selectedQBId: currI,
      limit:
        currI === "all"
          ? query
            ? ""
            : filterTopics
            ? ""
            : filterCategorys
            ? ""
            : filterTypes
            ? ""
            : filterComplexitys
            ? ""
            : 10
          : "",
      pageIndex: currI === "all" ? idx : "",
      categoryIds: filterCategorys ? filterCategorys : [],  
      topicIds: filterTopics ? filterTopics : [],
      complexity: filterComplexitys ? filterComplexitys : [],
      type: filterTypes ? filterTypes : [],
    };
    await Apihelper.getQuestionBankQues(  
      `${api.baseURL}${ 
        api.questionBankController.getQBUnselectedQuestions 
      }${userId}`,  
      "post", 
      payLoadParams 
    ) 
      .then((datum) => {  
        let tempDatum = []; 
        datum.questionList.forEach((j) => { 
          j.remChecked = false; 
          tempDatum.push(j);  
        }); 
        this.setState({ 
          currBankQues: tempDatum,  
        }); 
        this.setState({ 
          currBankQuesTotalCount: datum.totalCount, 
        }); 
        this.setState({ 
          hasMore: tempDatum.length > 0,  
          loading: false, 
        }); 
      })  
      .catch((e) => { 
        this.setState({ error: true }); 
      }); 
  };  

  setCurrQuestionBankValue = async (
    currI,
    idx,
    query,
    filterTypes,
    filterComplexitys
  ) => {
    this.setState({ loading: true, error: false });
    this.setState({ activePage: 1 });
    let userId = sessionStorage.getItem("user_id"); //sessionStorage.getItem("applicable_authors")
    const payLoadParams = {
      search: currI === "all" ? query : "",
      qpId: this.state.quesBankId,
      selectedQBId: currI,
      categoryIds:[],
      limit:
        currI === "all"
          ? query
            ? ""
            : filterTopics
            ? ""
            : filterCategorys
            ? ""
            : filterTypes
            ? ""
            : filterComplexitys
            ? ""
            : 10
          : "",
      pageIndex: currI === "all" ? idx : "",
      complexity: filterComplexitys ? filterComplexitys : [],
      type: filterTypes ? filterTypes : [],
    };
    try {
      await Apihelper.getQuestionBankQues(
        `${api.baseURL}${api.questionBankController.getQBQuestions}${userId}`,
        "Post",
        payLoadParams,
      ).then((data) => {
        this.setState({
          questions: data.questionList,
          realQuestions: data.questionList,
        });
      });
    } catch (e) {}
  };

  getQBQuestionList = async () => {
    let userId = sessionStorage.getItem("user_id"); 
    const payLoadParams = {
      search: "",
      qpId:this.state.quesBankId,
      limit:null,
      type:[],
      categoryIds:[],
      pageIndex:null,
      complexity:[],
      topicIds:[]
    };
    try {
      await Apihelper.getQuestionBankQues(
        `${api.baseURL}${api.questionBankController.getQBQuestions}${userId}`,
        "Post",
        payLoadParams,
      ).then((data) => {
        this.setState({
          questions: data.questionList,
          realQuestions: data.questionList,
        });
      });
    } catch (e) {}
  };

  addQuestions = async (questionIds) => {
    let questionBody = {
      questionIds,
      questionBankId: this.state.quesBankId,
      createdBy: this.userPrivileges.userName,
    };
    await Apihelper.axiosCallPost(
      `${api.baseURL}${api.questionBankController.copyQuestions}`,
      "post",
      questionBody
    )
      .then((data) => {
        this.getQBQuestionList();
        this.setState({
          currBankQues: [],
          SQM_reset: true,
        });
      })
      .catch((err) => {});
  };

  deleteQuestion = async (quesId) => {
    let url = `${api.baseURL}${api.questionBankController.deleteQuestionFromQB}`;
    let postData = {
      questionId: quesId,
      questionBankId: this.state.quesBankId,
      createdBy: this.userPrivileges.userName,
    };
    try {
      Apihelper.axiosCallPost(url, "post", postData).then(() => {
        if (this.props.location) {
          this.setState({ breadName: this.state.breadName });
        }
        this.getQBQuestionList();
        this.setState((prevState) => ({ modal: !prevState.modal }));
      });
    } catch (e) {}
  };

  searchQuestions = (query) => {
    let arr = [];
    const validKeys = ["name", "type", "complexity"];
    if (query) {
      this.setState({ activePage: 1 });
      this.state.realQuestions.forEach((e) => {
        for (const key in e) {
          if (
            e[key] &&
            validKeys.includes(key) &&
            e[key].toLowerCase().includes(query.toLowerCase())
          ) {
            arr.push(e);
          }
        }
      });
      this.setState({
        activePagee: 1,
      });
    }
    if (arr.length > 0) {
      this.setState({ questions: arr });
      this.setState({
        activePagee: 1,
      });
    } else {
      this.setState({ questions: this.state.realQuestions });
    }
  };

  render() {
    const lastIndex = this.state.activePage * this.state.itemsPerPage;
    const firstIndex = lastIndex - this.state.itemsPerPage;
    return (
      <>
        <div className="page-content">
          <Modal isOpen={this.state.modal} centered>
            <ModalHeader>Delete Question</ModalHeader>
            <ModalBody>Are you sure you want to delete this Question</ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => this.deleteQuestion(this.state.deleteId)}
              >
                Yes
              </Button>
              <Button
                onClick={() =>
                  this.setState((prevState) => ({ modal: !prevState.modal }))
                }
              >
                No
              </Button>
            </ModalFooter>
          </Modal>
          <ToastContainer position="bottom-right" autoClose={3000} />
          <Breadcrumbs
            title="Question List"
            breadcrumbItem={this.state.breadName}
          />
          <Paper>
            <div
              style={{
                padding: 20,
                position: "relative",
                borderBottom: "1px solid lavender",
              }}
            >
              <h4>Questions</h4>
              <Button
                color="primary"
                style={{
                  position: "absolute",
                  right: 130,
                  bottom: 15,
                  display:
                    this.props.location?.state?.from !== "approved" &&
                    // this.Priv.edit
                    this.Priv &&
                    this.Priv.adminAdd
                      ? "block"
                      : "none",
                }}
                onClick={() =>
                  this.props.history.push({
                    pathname: `/create-question`,
                    state: {
                      id: this.state.quesBankId,
                      name: this.state.breadName,
                      customerId: this.state.customerId,
                      activeTab: this.state.activeTab,
                      activePage: this.state.activePage,
                      customerName: this.state.customerName,
                    },
                  })
                }
              >
                <CustomIcons.PlusIcon size="17" color="#fff" /> Add New
              </Button>
              <Button
                color="primary"
                outline
                onClick={() => {
                  this.setState({ bulkUploadVisibility: true });
                }}
                style={{
                  position: "absolute",
                  right: 15,
                  bottom: 15,
                  display:
                    this.state.from !== "approved" &&
                    // this.Priv.edit
                    this.Priv.adminAdd
                      ? "block"
                      : "none",
                }}
              >
                {"Bulk Upload"}
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 15,
                paddingBottom: 5,
              }}
            >
              <CustomSearch
                placeholder="Search Questions"
                secondary
                handlesearch={(e) => this.searchQuestions(e)}
              />
              <div style={{ display: "flex", gap: 5 }}>
                <div style={{paddingLeft:"50px" }}>
              <SelectQuestionsModalQB
          questionTypes={questionTypes}
          loading={this.state.loading}
          hasMore={this.state.hasMore}
          currBankQuesTotalCount={this.state.currBankQuesTotalCount}
          quesBankId={this.state.quesBankId}
          questionsType="all"
          currBankQues={this.state.currBankQues}
          setCurrQuestionBank={(
            val,
            idx,
            query,
            filterTypes,
            filterComplexitys
          ) =>
            this.setCurrQuestionBankValue(
              val,
              idx,
              query,
              filterTypes,
              filterComplexitys
            )
          }
          QBlist={this.state.listQB}
          isCopy={true}
          setQIds={(val) => {
            this.addQuestions(val);
          }}
          reset={this.state.SQM_reset}
          setReset={(boo) =>
            this.setState({
              SQM_reset: boo,
            })
          }
        /> 
        </div>
                {/* <div
                  style={{
                    border: "1px solid #D7D7D7",
                    backgroundColor: "#F9F9F9",
                    color: "#3A3A3A",
                    borderRadius: 5,
                    height: 36,
                    padding: "3px 16px",
                    alignItems: "center",
                    display:
                      this.props.location?.state?.from !== "approved" &&
                      // this.Priv.edit
                      this.Priv.adminAdd
                        ? "flex"
                        : "none",
                  }}
                  onClick={() =>
                    this.setState({
                      copyModal: !this.state.copyModal,
                })
                  }
                >
                  <span style={{ float: "left", marginRight: 6 }}>
                    <i className="far fa-clone" />
                  </span>
                  <button style={{ border: 0, background: "none" }}>
                    Copy
                  </button>
                </div> */}
                {this.Priv.SA && (
                  <CSVLink
                    data={this.state.questions || []}
                    filename={` List.csv`}
                  >
                    <div
                      style={{
                        border: "1px solid #D7D7D7",
                        backgroundColor: "#F9F9F9",
                        color: "#3A3A3A",
                        display: "flex",
                        height: 36,
                        alignItems: "center",
                        borderRadius: 5,
                        padding: "3px 16px",
                      }}
                    >
                      <span style={{ float: "left", marginRight: 6 }}>
                        {CustomIcons.uploadIconTwo}
                      </span>
                      <button style={{ border: 0, background: "none" }}>
                        Export
                      </button>
                    </div>
                  </CSVLink>
                )}
              </div>
            </div>
            <div style={{ backgroundColor: "#fff", padding: 10 }}>
              <Table striped>
                <thead style={{ backgroundColor: "#C5EAFC" }}>
                  <tr>
                    <th style={{ color: "#000" }}>S.No</th>
                    {tableHeaders.map((item, ind) => (
                      <th style={{ color: "#000" }}>
                        {item.label}
                        {this.state.downArrow ? (
                          <KeyboardArrowDownIcon
                            style={arrowStyle}
                            onClick={() => {
                              this.sortData(item.val, "dsc");
                              this.toggleArrow();
                            }}
                          />
                        ) : (
                          <KeyboardArrowUpIcon
                            style={arrowStyle}
                            onClick={() => {
                              this.sortData(item.val, "asc");
                              this.toggleArrow();
                            }}
                          />
                        )}
                      </th>
                    ))}
                    <th style={{ color: "#000" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.questions &&
                    this.state.questions.length > 0 &&
                    this.state.questions
                      .slice(firstIndex, lastIndex)
                      .map((datum, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          {tableHeaders.map((item, ind) => (
                            <td key={ind}>
                              {item.val === "type" ? (
                                datum[item.val] === "MC" ? (
                                  "Multi-choice"
                                ) : datum[item.val] === "CD" ? (
                                  "Coding"
                                ) : datum[item.val] === "DESC" ? (
                                  "Descriptive"
                                ) : (
                                  ""
                                )
                              ) : item.val === "complexity" ? (
                                <div
                                  style={{
                                    backgroundColor:
                                      datum[item.val] === "easy"
                                        ? "#dcf0de"
                                        : datum[item.val] === "medium"
                                        ? "#fcf3da"
                                        : datum[item.val] === "hard"
                                        ? "#fbdcdc"
                                        : "",
                                    padding: "5px 15px 5px 15px",
                                    width: 91,
                                    textAlign: "center",
                                    fontWeight: 500,
                                    borderRadius: 5,
                                    color:
                                      datum[item.val] === "easy"
                                        ? "#00B11A"
                                        : datum[item.val] === "medium"
                                        ? "#D4A207"
                                        : datum[item.val] === "hard"
                                        ? "#EB5757"
                                        : "",
                                  }}
                                >
                                  {datum[item.val] &&
                                    datum[item.val].slice(0, 1).toUpperCase() +
                                      datum[item.val].slice(
                                        1,
                                        datum[item.val].length
                                      )}
                                </div>
                              ) : (
                                datum[item.val]
                              )}
                            </td>
                          ))}
                          <td>
                            {this.state.from !== "approved" &&
                            this.props.location?.state?.type !== "Approved" &&
                            // this.Priv.author
                            this.Priv.adminEdit ? (
                              <>
                                <span
                                  style={{
                                    marginRight: 15,
                                    color: "#51B960",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.props.history.push({
                                      pathname: `/edit-question/${datum.id}`,
                                      state: {
                                        id: this.state.quesBankId,
                                        name: this.state.breadName,
                                        activePage: this.state.activePage,
                                        itemsPerPage: this.state.itemsPerPage,
                                        customerId: this.state.customerId,
                                        activeTab: this.state.activeTab,
                                        activePagee: this.state.activePagee,
                                        customerName: this.state.customerName,
                                      },
                                    })
                                  }
                                >
                                  {CustomIcons.penIcon("#51B960")}
                                </span>
                                <span
                                  style={{
                                    color: "#EB5757",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    this.setState((prevState) => ({
                                      modal: !prevState.modal,
                                    }));
                                    if (this.state.deleteId !== datum.id)
                                      this.setState({ deleteId: datum.id });
                                  }}
                                >
                                  {CustomIcons.circleCrossIcon({
                                    size: 19,
                                    color: "#EB5757",
                                  })}
                                </span>
                              </>
                            ) : this.Priv.admin ||
                              this.props.location?.state?.from ===
                                "approved" ? (
                              <span
                                style={{ color: "#EB5757", cursor: "pointer" }}
                                onClick={() =>
                                  this.props.history.push({
                                    pathname: "/viewquestion",
                                    state: {
                                      activePage: this.state.activePage,
                                      currentPage: this.state.currentPage,
                                      activeTab: this.state.activeTab,
                                      itemsPerPage: this.state.itemsPerPage,
                                      customerId: this.state.customerId,
                                      data: datum,
                                      viewQuesTableNameandId:
                                        this.props.location?.state.hasOwnProperty(
                                          "viewQuesTableNameandId"
                                        )
                                          ? this.props.location?.state
                                              ?.viewQuesTableNameandId
                                          : this.props.location?.state,
                                    },
                                  })
                                }
                              >
                                {CustomIcons.outlinedEye}
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ))}
                </tbody>
              </Table>
              {this.state?.realQuestions?.length > 0 ? (
              <div
                style={{
                  borderTop: "1px solid lavender",
                  padding: 20,
                  backgroundColor: "#fff",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  Showing{" "}
                  <select
                    style={{
                      height: 30,
                      width: 100,
                      borderRadius: 5,
                      backgroundColor: "#F5F5F5",
                      border: "1px solid lavender",
                    }}
                    onChange={(e) => {
                      let val = +e.target.value.match(/\d+/g);
                      this.setState({
                        itemsPerPage: val ? val : props.data.length,
                        activePage: 1,
                      });
                    }}
                  >
                    {entriesCount.map((count, ind) => (
                      <option
                        key={uid(ind)}
                        selected={
                          !this.state?.realQuestions?.length
                            ? 0
                            : this.state.itemsPerPage === count
                            ? count
                            : ""
                        }
                      >
                        {count}
                      </option>
                    ))}
                  </select>{" "}
                  out of {this.state?.realQuestions?.length} entries
                </div>
                {this.state?.questions?.length > 0 && (
                <div
                  style={{
                    marginRight: 10,
                  }}
                >
                  <Pagination
                    {...paginationClasses}
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.itemsPerPage}
                    totalItemsCount={this.state?.questions?.length || 0}
                    pageRangeDisplayed={5}
                    onChange={(pageNo) => this.setState({ activePage: pageNo })}
                  />
                </div>
                )}
              </div>
                ) : (
                  <div style={{
                    fontSize: 16.5, 
                    textAlign: "center",
                  }}> No questions to display </div>
                )}            
            </div>
          </Paper>
          <Link
            to={{
              pathname: "/create-question-bank",
              state: {
                customer: this.state.customerId,
                activeTab: this.state.activeTab,
                activePage: this.state.currentPage,
              },
            }}
            className="terv-back"
          >
            <i className="mdi mdi-arrow-left mr-1" /> Back to Question Banks{" "}
          </Link>
        </div>
        <BulkQuestionUpload
          questionBankId={this.props.location?.state?.id}
          onComplete={this.getQBQuestionList}
          isOpen={this.state.bulkUploadVisibility}
          onClose={() => {
            this.setState({ bulkUploadVisibility: false });
          }}
        />
        <SelectQuestionsModal
          questionTypes={questionTypes}
          loading={this.state.loading}
          hasMore={this.state.hasMore}
          currBankQuesTotalCount={this.state.currBankQuesTotalCount}
          modalState={this.state.copyModal}
          quesBankId={this.state.quesBankId}
          questionsType="all"
          currBankQues={this.state.currBankQues}
          setCurrQuestionBank={(
            val,
            idx,
            query,
            filterTopics,
            filterCategorys,
            filterTypes,
            filterComplexitys
          ) =>
            this.setCurrQuestionBank(
              val,
              idx,
              query,
              filterTopics,
              filterCategorys,
              filterTypes,
              filterComplexitys
            )
          }
          QBlist={this.state.listQB}
          isCopy={true}
          setQIds={(val) => {
            this.addQuestions(val);
          }}
          setModal={() => this.setState({ copyModal: !this.state.copyModal })}
          reset={this.state.SQM_reset}
          setReset={(boo) =>
            this.setState({
              SQM_reset: boo,
            })
          }
        />
      </>
    );
  }
}
