import { InputField } from "../QuestionWizard/InputField";
import { useState, useEffect } from "react";
import { Row, Col, Label } from "reactstrap";
import Select from "react-select";
import LanguagesDropdown from "../Tasks/LanguagesDropdown";
import RichTextEditor from "../../components/RichTextEditor";
import { GetObjectFromString } from "pages/utils/GetObjectFromString";
import ReactHtmlParser from "react-html-parser";

const packageTags = [
  { value: "premium", label: "Premium" },
  { value: "special", label: "Special" },
  { value: "latest", label: "Latest" },
];

const featuredTag = { value: "featured", label: "Featured" };

const PackageDetails = ({
  packageData,
  updateState,
  descValue,
  updateErrors,
  errors,
  questionTypes,
  isSA,
  checkIfNameExists,
  customerId,
  fromPrepare,
  isEdit,
  setDesc,
  description,
}) => {
  const [value, setValue] = useState(packageData.description);
  return (
  <div style={{ display: "flex" }}>
    <div style={{ flex: 2, padding: "20px 0 0 20px", width: "50%" }}>
      <h5>Package Details</h5>
      <p>Enter the necessary package details</p>
    </div>
    <div style={{ flex: 3, width: "50%" }}>
      <Row style={{ marginBottom: "20px" }}>
        <InputField
          len={12}
          id="packageName"
          label="Package Name"
          showValid
          value={packageData.name}
          changeFunc={(val) => updateState(val, "name")}
          validation={(err) => updateErrors(err, "_packError")}
          validationMessage="Please enter a valid Name within 100 characters"
          error={errors._packError}
          maxLength={100}
          onBlur={() => {
            if (packageData.name && customerId) {
              // const encodedName = window.btoa(packageData.name);
              checkIfNameExists(packageData.name, customerId);
            }
          }}
        />
      </Row>
      <Col lg={13} style={{ marginBottom: "20px" }}>
        <Label>Description</Label>
        <span style={{ color: "red" }}>*</span>
        <br />
          <textarea 
              onChange={(e)=> {
                setDesc(e.target.value);
                descValue(true);
              }}
              defaultValue={packageData.description}
              className="terv-form terv-form-lg"
              style={{ height: "100px", width:"100%" }}
            />
          <p style={{color: "red"}}>{errors._packDescError}</p>
      </Col>
      <Row style={{ marginBottom: "20px", paddingTop: "20px" }}>
        {!fromPrepare && (
          <>
            <InputField
              len={6}
              type="date"
              id="startDate"
              label="Start Date"
              showValid
              value={packageData.startDate}
              changeFunc={(val) => updateState(val, "startDate")}
              validation={(err) => updateErrors(err, "_startDateError")}
              error={isEdit ? "" : errors._startDateError}
              validationMessage="please select date"
            />
            <InputField
              len={6}
              type="date"
              id="endDate"
              label="End Date"
              showValid
              value={packageData.endDate}
              changeFunc={(val) => updateState(val, "endDate")}
              validation={(err) => updateErrors(err, "_endDateError")}
              error={errors._endDateError}
              validationMessage="please select date"
              allData={packageData}
            />
          </>
        )}
      </Row>
      <Row style={{ marginBottom: "20px" }}>
        <Col lg={6}>
          <Label>Type</Label>
          <Select
            placeholder="Choose a Type"
            options={questionTypes}
            value={GetObjectFromString(questionTypes, packageData.type)}
            onChange={(val) => updateState(val.value, "type")}
          />
        </Col>
        {packageData.type === "CD" || packageData.type === "all" ? (
          <Col lg={6}>
            <Label>Languages</Label>
            <LanguagesDropdown
              value={packageData.languagesList}
              onChange={(languages) => updateState(languages, "languagesList")}
            />
          </Col>
        ) : (
          ""
        )}
        {!fromPrepare && (
          <Col
            lg={6}
            style={{
              marginTop:
                packageData.type === "CD" || packageData.type === "all"
                  ? 20
                  : "",
            }}
          >
            <Label>Tag</Label>
            <Select
              placeholder="Choose a Tag"
              options={isSA ? [...packageTags, featuredTag] : packageTags}
              value={GetObjectFromString(
                [...packageTags, featuredTag],
                packageData.tags
              )}
              onChange={(val) => updateState(val.value, "tags")}
            />
          </Col>
        )}
      </Row>
    </div>
  </div>
);
              };

export default PackageDetails;
