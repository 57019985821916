import React from "react"
import {
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Link } from "react-router-dom"
import api from "../../api/baseConfig"
import Apihelper from "../../api/apiHelper"
import ReactHtmlParser from "react-html-parser"
import Webcam from "react-webcam"
import Loader from "react-loader-spinner"
import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap"
import { FlareSharp } from "@material-ui/icons"
import { uploadFile, browserCheck, getSafe } from "./common/testHelper"
export default class TestDesc extends React.Component {
  constructor(props) {
    super(props)
    const params = new URLSearchParams(window.location.search)
 
    let sate = JSON.parse(params.get("state"))
     
    let userId =  params.get("userId")
     
    this.state = {
      modal: false,
      taketestdisable: true,
      response: sate,
      assessment: {},
      userId: userId,
      sectionDetails: "",
      userVerfied: false,
      userphoto: "",
      imageEnabled: true,
      validBrowser: "",
      passCode: false,
      passwordtyped: "",
    }
  }
  setRef = webcam => {
    this.webcam = webcam
  }
  capture = async () => {
    const imageSrc = this.webcam?.getScreenshot()
    let val;
    
    if (imageSrc) {
      let g = imageSrc.split(",")
      val = await uploadFile(g[1], this.state)
    }
    
    this.setState({ userVerfied: true, userphoto: val })
  }
  verify() {
    try {
      let body = {
        assessmentId: this.state.response.id,
        passCode: this.state.passwordtyped,
      }
      Apihelper.axiosCallPost(
        `${api.baseURL}${api.userManagement.verifyPass}`,
        "post",
        body
      ).then(res => {
        if (res === false) {
          toast.error("Wrong password Try again", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else if (res === true) {
          this.setState({ passCode: false })
        }
      })
    } catch (e) {}
  }
  componentDidMount() {
    try {
      Apihelper.axiosCall(
        `${api.baseURL}${api.userManagement.getInfo}${this.state.response.id}`,
        "get"
      ).then(res => {
        let defpassCode = false
        defpassCode = getSafe(() => res.settings.passcodeEnabled, false)
        let imageEnabledD = getSafe(() => res.settings.imageEnabled, true)
        this.setState({
          sectionDetails: res,
          passCode: defpassCode,
          imageEnabled: imageEnabledD,
          userVerfied: !imageEnabledD,
        })
      })
    } catch (e) {}
    document.addEventListener("contextmenu", e => {
      e.preventDefault()
    })
  }
  modalToggle = () => {
    this.state.imageEnabled === true
      ? this.setState({ modal: !this.state.modal, userVerfied: false })
      : this.setState({ modal: !this.state.modal })
  }
  render() {
    const videoConstraints = {
      width: 1280,
      height: 720,
      facingMode: "user",
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <Modal isOpen={this.state.modal}>
              <ModalHeader>Start Assessment</ModalHeader>
              <ModalBody>
                <h6>You are about to start this Assessment</h6>
                {this.state.imageEnabled === true ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Webcam
                      audio={false}
                      height={350}
                      ref={this.setRef}
                      screenshotFormat="image/jpeg"
                      width={350}
                      videoConstraints={videoConstraints}
                    />
                  </div>
                ) : (
                  ""
                )}
              </ModalBody>
              <ModalFooter>
                {this.state.userVerfied === false ? (
                  <Button color="primary" onClick={this.capture}>
                    Capture photo
                  </Button>
                ) : (
                  <Link
                    to={{
                      pathname: "/taketest",
                      state: this.state.assessment,
                      data: this.state.response,
                      userId: this.state.userId,
                      userPhoto: this.state.userPhoto,
                    }}
                    className="btn btn-primary waves-effect waves-light btn-sm"
                    style={{ width: 70, height: 37, fontSize: 16 }}
                  >
                    <p style={{ marginTop: 2 }}>Go!</p>
                  </Link>
                )}{" "}
                <Button color="secondary" onClick={this.modalToggle}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
            <h2> Hi, Student</h2>
            <div className="card" style={{ position: "relative" }}>
              <div style={{ padding: 10, listStyleType: "none" }}>
                {}
                &nbsp;
                <li>
                  <strong>Description: </strong>
                  {ReactHtmlParser(
                    this.state.response && this.state.response.description
                      ? this.state.response.description
                      : ""
                  )}
                </li>
                &nbsp;
                {this.state.response &&
                Array.isArray(this.state.response.instruction) &&
                this.state.response.instruction.length > 0 ? (
                  this.state.response.instruction.map((ele, i) => (
                    <li key={i}>{ReactHtmlParser(ele)}</li>
                  ))
                ) : this.state.response && this.state.response.instruction ? (
                  <li>
                    <strong>Instructions: </strong>
                    {ReactHtmlParser(this.state.response.instruction)}
                  </li>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="card" style={{ position: "relative" }}>
              <div style={{ padding: 10, listStyleType: "none" }}>
                {}
                &nbsp;
                <li>
                  <h4>SECTION DETAILS : </h4>
                </li>
                &nbsp;
                {this.state.sectionDetails !== "" ? (
                  <Table>
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Section Name</th>
                        <th>Section Description</th>
                        <th>No. of Questions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.sectionDetails.sectionList.length !== 0
                        ? this.state.sectionDetails.sectionList.map(
                            (ele, i) => (
                              <tr key={i}>
                                <th scope="row">{i + 1}</th>
                                <td>{ele.name}</td>
                                <td>{ele.description}</td>
                                <td>{ele.questionIdList.length}</td>
                              </tr>
                            )
                          )
                        : ""}
                    </tbody>
                  </Table>
                ) : (
                  ""
                )}
              </div>
            </div>
            {this.state.passCode === true ? (
              <div>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Enter passCode</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Passcode"
                    type="password"
                    onChange={e => {
                      this.setState({ passwordtyped: e.target.value })
                    }}
                  />
                  <Button
                    color="primary"
                    style={{
                      paddingRight: 20,
                      paddingLeft: 20,
                      marginLeft: 30,
                    }}
                    onClick={() => {
                      this.verify()
                    }}
                  >
                    Verify
                  </Button>
                </InputGroup>
              </div>
            ) : (
              ""
            )}
            {this.state.passCode === false ? (
              <span>
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <Input
                        addon
                        type="checkbox"
                        aria-label="Checkbox for following text input"
                        onClick={async () => {
                          let value = await browserCheck()
                          this.setState({
                            taketestdisable: !this.state.taketestdisable,
                            validBrowser: value,
                          })
                        }}
                      />
                      <h5 style={{ marginLeft: 10, marginTop: 5 }}>
                        I have read all the above Instructions and agree to
                        start test.
                      </h5>
                    </InputGroupText>
                  </InputGroupAddon>
                </div>
                <div style={{ position: "relative", marginTop: 20 }}>
                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Button
                      color="primary"
                      onClick={() => {
                        try {
                          Apihelper.axiosCall(
                            `${api.baseURL}${api.userManagement.getAssessmentQuestions}${this.state.response.id}`,
                            "get"
                          ).then(res => {
                            this.setState({ assessment: res })
                            this.modalToggle()
                          })
                        } catch (e) {}
                      }}
                      style={{
                        bottom: "1.5em",
                        right: 20,
                        width: 200,
                        height: 50,
                        fontSize: 16,
                      }}
                      disabled={this.state.taketestdisable}
                    >
                      Start Assessment
                    </Button>
                  </div>
                </div>
              </span>
            ) : (
              ""
            )}
            {this.state.validBrowser === true ? (
              <span>
                <div style={{ width: "30vh" }}>
                  <Button
                    color="primary"
                    style={{ width: "20vh", textAlign: "left" }}
                  >
                    <i className="bx bx-check" style={{ color: "#ffffff" }}></i>
                    BrowserValid
                  </Button>
                </div>
                <div>
                  <Button
                    color="primary"
                    style={{ width: "20vh", textAlign: "left", marginTop: 20 }}
                  >
                    <i className="bx bx-check" style={{ color: "#ffffff" }}></i>
                    Javascript enabled
                  </Button>
                </div>
                <div>
                  <Button
                    color="primary"
                    style={{ width: "20vh", textAlign: "left", marginTop: 20 }}
                  >
                    <i className="bx bx-check" style={{ color: "#ffffff" }}></i>
                    Internet Availability
                  </Button>
                </div>
              </span>
            ) : (
              ""
            )}
            {}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
