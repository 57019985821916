import styles from "./GradingCD.module.scss";
import { useState } from "react";
import { Button } from "reactstrap";
import Tabs from "./Tabs";
import { uid } from "react-uid";
import apiHelper from "api/apiHelper";
import api from "api/baseConfig";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

const tabItemStyle = {
  color: "#000",
  border: "1px solid #000",
  padding: "1rem",
  borderRadius: 5,
};

const GradingCD = ({ currentQuestion }) => {
  const [selectedtab, setSelectedTab] = useState("student code");
  const [output, setOutput] = useState("");
  const [loading, setLoading] = useState(false);
  const executeCode = async () => {
    setLoading(true);
    let body = {
      languageId: currentQuestion?.solutionMap?.languageId,
      questionId: currentQuestion?.id,
      solution: currentQuestion?.solutionMap?.codeSolution,
    };
    try {
      const res = await apiHelper.axiosCallPost(
        `${api.baseURL}${api.userManagement.executeCodingResults}`,
        "post",
        body
      );
      if (res) {
        setOutput(
          res?.message ||
            res?.submissions?.[0]?.compile_output ||
            res?.submissions?.[0]?.stdout ||
            res?.submissions?.[0]?.stderr
        );
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {
        currentQuestion?.type == 'CD' &&
    <div className={styles.main}>
      <Tabs
        items={["Student code", "Testcases", "Solution"]}
        selectedtab={selectedtab}
        setSelectedTab={setSelectedTab}
      />
      <div
        className={`${styles.codingArea} ${
          (selectedtab === "solution" || selectedtab === "testcases") &&
          styles.codingFlex
        }`}
      >
        {selectedtab === "student code" ? (
          <pre>
            {currentQuestion.solutionMap != null
              ? window.atob(currentQuestion?.solutionMap?.codeSolution)
              : currentQuestion?.solutionMap?.codeSolution}
          </pre>
        ) : selectedtab === "testcases" ? (
          (currentQuestion?.testCaseList || []).map((tc, ind) => (
            <div key={uid(ind)} style={tabItemStyle}>
              <p style={{ fontSize: "1.1rem" }}>Test Case {ind + 1}</p>
              Input : <pre>{tc?.inputText}</pre>
              Output : <pre>{tc?.outputText}</pre>
            </div>
          ))
        ) : selectedtab === "solution" ? (
          (currentQuestion?.solutionList || []).map((sol, ind) => (
            <div key={uid(ind)} style={tabItemStyle}>
              <p style={{ fontSize: "1.1rem" }}>Solution {ind + 1}</p>
              <pre>{sol?.solution}</pre>
            </div>
          ))
        ) : (
          ""
        )}
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          color="primary"
          outline
          className={styles.runCode}
          disabled={!currentQuestion?.solutionMap?.codeSolution}
          onClick={executeCode}
        >
          Run code
        </Button>
        &nbsp;&nbsp;
        {loading && (
          <Loader type="ThreeDots" color="#000" height={50} width={50} />
        )}
      </div>
      <div className={styles.outputTab}>Output</div>
      <div className={styles.outputArea}>{output}</div>
      </div>
      }
      {
        currentQuestion?.type == 'DESC' &&
        <div>
          <Tabs
        items={["Student Answer"]}
        selectedtab={selectedtab}
        setSelectedTab={setSelectedTab}
      />
      <div
        className={`${styles.codingArea} ${
          (selectedtab === "Student Answer") &&
          styles.codingFlex
        }`}
      >
          <p>
            {currentQuestion.solutionMap != null
              ? currentQuestion?.solutionMap?.codeSolution : ''}
          </p>
          </div>
      </div>
      }
    </div>
  );
};

export default GradingCD;
