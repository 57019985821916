import { useState, useEffect } from "react";
import QuestionValidations from "./utils/Validations";
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import QuestionMedia from "./dropzoneQuestion";
import { QuestionImage } from "./QuestionImage";
import Checkbox from "@material-ui/core/Checkbox";
import * as CustomIcons from "../../assets/icons/icons";
import { Field, reduxForm, getFormValues } from "redux-form";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { TervButtonFilled } from "./../../components/TervButtons"; 
import EditorField from "./EditorField";
import { saveQuestion } from "../../store/question/actions";
import api from "../../api/baseConfig";
import {
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import {
  Table,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import Apihelper from "../../api/apiHelper";
import CustomNumber from "./NumberInput";
import SolutionModal from "./SolutionsModal";
import DefaultSolutions from "./DefaultSolutions";
import { TypeTables } from "./TypeTables.js";
import SelectInput from "./SelectInput";
import { DisableCheck } from "./utils/DisableCheck";
import QuestionTypeModal from "./QuestionTypeModal";
import MasterAPIs from "../utils/MasterAPIs";
import { ModalToggle } from "pages/utils/ModalToggle";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { win32 } from "path";

const ModalLabels = {
  MC: {
    header: "Option",
    FF: "Option",
  },
  CD: {
    header: "Test Case",
    FF: "Input Testcase",
    SF: "Output Testcase",
  },
  DESC: {
    header: "Answer Key",
    FF: "Answer Key",
    SF: "Answer Key Points",
  },
  HINT: {
    header: "Add Hint",
    FF: "Hint",
  },
};

const defaultValues = {
  opValue: {
    MC: {
      optionIdentifier: "",
      answer: false,
      id: 0,
    },
    CD: {
      inputText: "",
      outputText: "",
      id: 0,
    },
    DESC: {
      answerKey: "",
      answerKeyPoints: "0",
      id: 0,
    },
  },
  hintValue: {
    desc: "",
    id: 0,
  },
  solValue: {
    val: "",
    lang: {
      label: "",
      value: "",
    },
    id: 0,
  },
  defSolValue: {
    val: "",
    lang: {
      label: "",
      value: "",
    },
    id: 0,
  },
  modalToggle: (prevState) => !prevState,
};

const customStyles = {
  menu: (styles) => ({ ...styles, zIndex: 9999 }),
};

let initialValues = {};
let QBid = "";

const renderNumberField = ({
  input,
  label,
  checkNegative = false,
  meta: { touched, error },
}) => (
  <CustomNumber
    input={{ ...input }}
    len={4}
    label={label}
    checkNegative={checkNegative}
    error={touched && error ? error : ""}
  />
);

const renderCustomCheckField = ({
  input,
  label,
  allOptions,
  setOptions,
  setPropsOptions,
}) => (
  <Row style={{ marginLeft: 5 }}>
    <Label style={{ marginTop: 11 }}>{label}</Label>
    <Col style={{ width: 300 }}>
      <Checkbox
        onChange={(e) => {
          input.onChange(e);
          if (allOptions.filter((op) => op.answer).length > 1 && input.value) {
            setOptions();
            setPropsOptions();
          }
        }}
        checked={input.value}
        color="primary"
      />
    </Col>
  </Row>
);

const setInitialValue = (val) => {
  initialValues = val;
};

const CreateQueComponentSub = (props) => {
  const { handleSubmit, initialValues, formValues, handleCallBack } = props;
  const [show, setShow] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [hints, setHints] = useState([]);
  const [solutions, setSolutions] = useState([]);
  const [defaultSolutions, setDefaultSolutions] = useState([]);
  const [allProficiencies, setAllProficiencies] = useState([]);
  const [allTaxonomies, setAllTaxonomies] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allTopics, setAllTopics] = useState([]);
  const [custName, setCustName] = useState([])

  const [allSubTaxonomies, setAllSubTaxonomies] = useState([]);
  setInitialValue(initialValues);

  useEffect(() => {
    window.scrollTo(0, 0);
    getLanguages();
    getAllProficiencies();
    getAllTaxonomies();
    getAllCategories();
  }, []);

  const validIndicator = (value) => (
    <>
      {value}
      <span style={{ color: "red" }}>*</span>
    </>
  );

  const getAllProficiencies = async () => {
    const profs = await MasterAPIs.ALL_PROFICIENCIES();
    setAllProficiencies(profs);
  };

  const getAllTaxonomies = async () => {
    const taxonomies = await MasterAPIs.ALL_TAXONOMIES();
    setAllTaxonomies(taxonomies);
  };

  const getAllSubTaxonomies = async (id) => {
    const subTaxonomies = await MasterAPIs.ALL_SUB_TAXONOMIES(id);
    setAllSubTaxonomies(subTaxonomies);
  };

  const getAllCategories = async () => {
    const categories = await MasterAPIs.ALL_CATEGORIES();
    setAllCategories(categories);
  };

  const getAllTopics = async (id) => {
    const topics = await MasterAPIs.ALL_TOPICS(id);
    setAllTopics(topics);
  };

  useEffect(() => {
    if (props.typeValues && props.typeValues.length && !options.length)
      setOptions(props.typeValues);
  }, [props?.typeValues]);

  useEffect(() => {
    if (props.hints && props.hints.length && !hints.length)
      setHints(props.hints);
  }, [props?.hints]);

  useEffect(() => {
    if (props?.solutions && props?.solutions.length && !solutions.length)
      setSolutions(props?.solutions);
  }, [props?.solutions]);

  useEffect(() => {
    if (
      props?.defaultSolutions &&
      props?.defaultSolutions.length &&
      !defaultSolutions.length
    )
      setDefaultSolutions(props?.defaultSolutions);
  }, [props?.defaultSolutions]);

  useEffect(() => {
    if (formValues?.taxonomyId) {
      getAllSubTaxonomies(formValues?.taxonomyId);
    }
  }, [formValues?.taxonomyId]);

  useEffect(() => {
    if (formValues?.categoryId) {
      getAllTopics(formValues?.categoryId);
      props?.setCategoryName(custName.length > 0 ? custName : formValues?.category)
    }
  }, [formValues?.categoryId]);

  const getLanguages = async () => {
    if (!languages.length) {
      await Apihelper.axiosCall(
        `${api.baseURL}${api.LanguageController.currentLanguage}`,
        "get"
      ).then((data) => {
        let option = [];
        data?.forEach?.((element) => {
          option.push({
            label: element.languageNotation,
            value: element.id,
          });
        });
        setLanguages(option);
      });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Dialog  open={open}  >
          <div style={{
            paddingLeft:"42%",
            background:"#F9F9F9",
            width:"100%",
            paddingTop:"1%",
            paddingBottom:"1%",
            display:"flex",
            gap:"65%"
          }}>
          <span style={{
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "27px",
          }}>Add option</span> 
          <sapn onClick = {() => setOpen(false)}><a><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="black"/>
</svg></a>
</sapn>
          </div>
      <DialogContent>
       
        
          <div>
          <div>
          <label className="terv-label">
             {"Short description "}
            </label>
            <textarea
              className="terv-form terv-form-lg"
              style={{ height: "65px", width: "550px" }}
            />
          </div>
        </div>
          <div style={{display: "flex", }}>
          <span style={{ marginTop: 10 }}>
            
          <Field
                            name="multiSelect"
                            component={renderCustomCheckField}
                            label="Is the answer is right"
                            onChange={() => {
                              setShow(!show);
                            }}
                          />
                          </span>
          <Button
                            style={{ marginTop: 10 }}
                            color="success"
                            onClick={handleClickOpen}
                          >
                            <CustomIcons.PlusIcon size="17" color="#fff" />{" "}  
                            Add answer
                          </Button>
          </div>
        <div style={{ marginTop: 20 }}>
        <div style={{width:"550px"}}>
                        <Table responsive>
                <thead style={{ backgroundColor: "#C5EAFC", }}>
                  <tr>  
                    <th style={{ color: "#000"}}>S.NO</th>
                    <th style={{ color: "#000",}}>Options</th>
                    <th style={{ color: "#000"}}>IS</th>
                    <th style={{ color: "#000"}}>Actions</th>
                  </tr>
                </thead>
                <tbody style={{ backgroundColor: "#F9FDFF" }}>
        <tr>
          <td >1</td>
          <td>5.2</td>
          <td>Wrong</td>
          <td >
          <span
                                  style={{
                                    marginRight: 15,
                                    color: "#51B960",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.penIcon("#51B960")}
                                </span>
                                <span
                                  style={{
                                    color: "#EB5757",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.circleCrossIcon({
                                    size: 19,
                                    color: "#EB5757",
                                  })}
                                </span>
          </td>
          
        </tr>
        <tr>
          <td>2</td>
          <td>5.12</td>
          <td>Wrong</td>
          <td>
                                <span
                                  style={{
                                    marginRight: 15,
                                    color: "#51B960",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.penIcon("#51B960")}
                                </span>
                                <span
                                  style={{
                                    color: "#EB5757",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.circleCrossIcon({
                                    size: 19,
                                    color: "#EB5757",
                                  })}
                                </span>
          </td>
        </tr>
                </tbody>
              </Table>
              </div>
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
      <TervButtonFilled
            text="Save"
          />    
      </DialogActions>
    </Dialog>
    <div className="page-content">
    
      <Breadcrumbs title="Assessment" breadcrumbItem="Create Sub Question" />
      <Row>
        <Col lg="12">
          <Card>
            <CardBody style={{ position: "relative" }}>
              <form className="outer-repeater" onSubmit={handleSubmit}>
                <div data-repeater-list="outer-group" className="outer">
                  <div data-repeater-item className="outer">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          padding: 12,
                          paddingRight: 5,
                          flex: 2,
                          width: "50%",
                        }}
                      >
                        <h4>Sub Question details</h4>
                        <p style={{width:"320px" ,color:"#A6A6AA"}}>Enter details of question and choose its types, difficulty and category</p>
                      </div>
                      <div style={{ flex: 4, marginLeft: -20, width: "50%" }}>
                         <Field
                            name="type"
                            placeholder="Choose a Type"
                            id="type"
                            label={validIndicator("Type")}
                            options={[
                              { label: "Multi Choice", value: "MC" },
                              { label: "Coding", value: "CD" },
                              { label: "Descriptive", value: "DESC" },
                            ]}
                            component={SelectInput}
                            validate={[QuestionValidations.CATEGORY]}
                            // props={{
                            //   allOptions: options,
                            //   setOptions: () => setOptions([]),
                            //   setPropsOptions: () => props.setTypeValues([]),
                            // }}
                          />
                        <div style={{ marginLeft: 20, paddingTop:"20px"}}>
                          {/* {formValues?.type === "MC" && ( */}
                       <Field
                          name="Sub Question 1"
                          label="Sub Question 1"
                          id="question"
                          disabled={false}
                          placeholder="Type here"
                          validate={[QuestionValidations.QUESTIONS]}
                          component={EditorField}
                        />
                          {/* )} */}
                        </div>
                      </div>
                    </div>
                    <hr />
                    {formValues?.type === "CD" && (
                    <>
                          <div style={{ display: "flex" }}>
                            <div
                              style={{
                                padding: 12,
                                marginRight: 4,
                                flex: 2,
                                width: "50%",
                              }}
                            >
                              <h4>
                                Add Input Format, Input Constraint and Output
                                Format
                              </h4>
                            </div>
                            <div
                              style={{
                                flex: 4,
                                marginTop: 10,
                                display: "flex",
                                flexDirection: "column",
                                gap: 10,
                              }}
                            >
                              <Field
                          name="Sub Question 1"
                          label="Input Format"
                          id="question"
                          disabled={false}
                          placeholder="Type here"
                          validate={[QuestionValidations.QUESTIONS]}
                          component={EditorField}
                        />
                         <Field
                          name="Sub Question 1"
                          label="Input Constraint"
                          id="question"
                          disabled={false}
                          placeholder="Type here"
                          validate={[QuestionValidations.QUESTIONS]}
                          component={EditorField}
                        />
                         <Field
                          name="Sub Question 1"
                          label="Output Format"
                          id="question"
                          disabled={false}
                          placeholder="Type here"
                          validate={[QuestionValidations.QUESTIONS]}
                          component={EditorField}
                        />
                            </div>
                          </div>
                          <hr />
                        </>
                    )}
                    {formValues?.type === "CD" && (
                        <>
                          <div style={{ display: "flex" }}>
                            <div
                              style={{
                                padding: 12,
                                marginRight: 4,
                                flex: 2,
                                width: "50%",
                              }}
                            >
                              <h4>Add Sample Input and Output</h4>
                            </div>
                            <div
                              style={{
                                flex: 4,
                                marginTop: 10,
                                display: "flex",
                                flexDirection: "column",
                                gap: 10,
                              }}
                            >
                              <Field
                          name="Sub Question 1"
                          label="Sample Input"
                          id="question"
                          disabled={false}
                          placeholder="Type here"
                          validate={[QuestionValidations.QUESTIONS]}
                          component={EditorField}
                        />
                         <Field
                          name="Sub Question 1"
                          label="Sample Output"
                          id="question"
                          disabled={false}
                          placeholder="Type here"
                          validate={[QuestionValidations.QUESTIONS]}
                          component={EditorField}
                        />
                            </div>
                          </div>
                          <hr />
                        </>
                    )}
                    <div>
                    <div>
                    {formValues?.type !== "CD" && (
                    <div>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          padding: 12,
                          paddingRight: 14,
                          flex: 2,
                          width: "50%",
                        }}
                      >
                         <h4>Add options</h4> 
                        {/* <p>Create a new sub question</p> */}
                      </div>
                      <div style={{ flex: 4, marginTop: 10, width: "50%" }}>
                      
                        <div
                          style={{
                            marginBottom: 10,
                          }}
                        >
                          <div style={{display: "flex", gap:"50px"}}>
                          <Button
                            style={{ marginBottom: 10 }}
                            color="success"
                            onClick={handleClickOpen}
                          >
                            <CustomIcons.PlusIcon size="17" color="#fff" />{" "}  
                            Add options
                          </Button>
                          <Field
                            name="multiSelect"
                            component={renderCustomCheckField}
                            label="Multi question"
                            onChange={() => {
                              setShow(!show);
                            }}
                          />
                          </div> 
                        </div>
                        <div style={{width:"600px"}}>
                        <Table responsive style={{textAlign:"center"}}>
                <thead style={{ backgroundColor: "#C5EAFC", }}>
                  <tr>  
                    <th style={{ color: "#000"}}>S.NO</th>
                    <th style={{ color: "#000",}}>Options</th>
                    <th style={{ color: "#000"}}>IS</th>
                    <th style={{ color: "#000"}}>Action</th>
                  </tr>
                </thead>
                <tbody style={{ backgroundColor: "#F9FDFF" }}>
        <tr>
          <td >1</td>
          <td>5.2</td>
          <td>Wrong</td>
          <td >
          <span
                                  style={{
                                    marginRight: 15,
                                    color: "#51B960",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.penIcon("#51B960")}
                                </span>
                                <span
                                  style={{
                                    color: "#EB5757",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.circleCrossIcon({
                                    size: 19,
                                    color: "#EB5757",
                                  })}
                                </span>
          </td>
          
        </tr>
        <tr>
          <td>2</td>
          <td>5.12</td>
          <td>Wrong</td>
          <td>
                                <span
                                  style={{
                                    marginRight: 15,
                                    color: "#51B960",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.penIcon("#51B960")}
                                </span>
                                <span
                                  style={{
                                    color: "#EB5757",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.circleCrossIcon({
                                    size: 19,
                                    color: "#EB5757",
                                  })}
                                </span>
          </td>
        </tr>
                </tbody>
              </Table>
              </div>
                        {/* )} */}
                      
                      </div>
                    </div>
                    <hr />
                    </div>
                    )}
                   {formValues?.type === "CD" && ( 
                    <div>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          padding: 12,
                          paddingRight: 14,
                          flex: 2,
                          width: "50%",
                        }}
                      >
                         <h4>Add testcases</h4> 
                        <p style={{color:"#A6A6AA"}}>Add input and output testcases how much you want</p>
                      </div>
                      <div style={{ flex: 4, marginTop: 10, width: "50%" }}>
                      
                        <div
                          style={{
                            marginBottom: 10,
                          }}
                        >
                          <div style={{display: "flex", gap:"50px"}}>
                          <Button
                            style={{ marginBottom: 10 }}
                            color="success"
                            onClick={handleClickOpen}
                          >
                            <CustomIcons.PlusIcon size="17" color="#fff" />{" "}  
                            Add testcase
                          </Button>
                          </div>
                        
                        </div>
                        <div style={{width:"600px"}}>
                        <Table responsive>
                <thead style={{ backgroundColor: "#C5EAFC", }}>
                  <tr>  
                    <th style={{ color: "#000", paddingLeft:"50px"}}>Test cases</th>
                    <th style={{ color: "#000", paddingLeft:"330px"}}>Action</th>
                  </tr>
                </thead>
                <tbody style={{ backgroundColor: "#F9FDFF" }}>
        <tr>
          <td style={{ color: "#000", paddingLeft:"50px"}}>Test case 1</td>
          <td style={{ color: "#000", paddingLeft:"330px"}}>
          <span
                                  style={{
                                    marginRight: 15,
                                    color: "#51B960",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.penIcon("#51B960")}
                                </span>
                                <span
                                  style={{
                                    color: "#EB5757",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.circleCrossIcon({
                                    size: 19,
                                    color: "#EB5757",
                                  })}
                                </span>
          </td>
          
        </tr>
        <tr>
          <td style={{ color: "#000", paddingLeft:"50px"}}>Test case 2</td>
          <td style={{ color: "#000", paddingLeft:"330px"}}>
                                <span
                                  style={{
                                    marginRight: 15,
                                    color: "#51B960",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.penIcon("#51B960")}
                                </span>
                                <span
                                  style={{
                                    color: "#EB5757",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.circleCrossIcon({
                                    size: 19,
                                    color: "#EB5757",
                                  })}
                                </span>
          </td>
        </tr>
                </tbody>
              </Table>
              </div>
                      
                      </div>
                    </div>
                    <hr />
                    </div>
                    )}

                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          padding: 12,
                          paddingRight: 14,
                          flex: 2,
                          width: "50%",
                        }}
                      >
                         <h4>Hints and solutions</h4> 
                        <p style={{color:"#A6A6AA"}}>Add hints and solutions for the question</p>
                      </div>
                      <div style={{ flex: 4, marginTop: 10, width: "50%" }}>
                      
                        <div
                          style={{
                            marginBottom: 10,
                          }}
                        >
                          <Button
                            style={{ marginBottom: 10 }}
                            color="success"
                            onClick={handleClickOpen}
                          >
                            <CustomIcons.PlusIcon size="17" color="#fff" />{" "}  
                            Add hints
                          </Button>
                        
                        </div>
                        <div style={{width:"600px"}}>
                        <Table responsive>
                <thead style={{ backgroundColor: "#C5EAFC", }}>
                  <tr>  
                    <th style={{ color: "#000", paddingLeft:"50px"}}>Hints</th>
                    <th style={{ color: "#000", paddingLeft:"330px"}}>Action</th>
                  </tr>
                </thead>
                <tbody style={{ backgroundColor: "#F9FDFF" }}>
        <tr>
          <td style={{ color: "#000", paddingLeft:"50px"}}>Hint 1</td>
          <td style={{ color: "#000", paddingLeft:"330px"}}>
          <span
                                  style={{
                                    marginRight: 15,
                                    color: "#51B960",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.penIcon("#51B960")}
                                </span>
                                <span
                                  style={{
                                    color: "#EB5757",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.circleCrossIcon({
                                    size: 19,
                                    color: "#EB5757",
                                  })}
                                </span>
          </td>
          
        </tr>
        <tr>
          <td style={{ color: "#000", paddingLeft:"50px"}}>Hint 2</td>
          <td style={{ color: "#000", paddingLeft:"330px"}}>
                                <span
                                  style={{
                                    marginRight: 15,
                                    color: "#51B960",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.penIcon("#51B960")}
                                </span>
                                <span
                                  style={{
                                    color: "#EB5757",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.circleCrossIcon({
                                    size: 19,
                                    color: "#EB5757",
                                  })}
                                </span>
          </td>
        </tr>
                </tbody>
              </Table>
              {formValues?.type === "CD" && (
              <div>
              <Button
                            style={{ marginBottom: 10 }}
                            color="success"
                            onClick={handleClickOpen}
                          >
                            <CustomIcons.PlusIcon size="17" color="#fff" />{" "}  
                            Add Solution
                          </Button>
                          <Table responsive style={{textAlign:"center"}}>
                <thead style={{ backgroundColor: "#C5EAFC", }}>
                  <tr>  
                    <th style={{ color: "#000"}}>Solutions</th>
                    <th style={{ color: "#000",}}>Language</th>
                    <th style={{ color: "#000"}}>Action</th>
                  </tr>
                </thead>
                <tbody style={{ backgroundColor: "#F9FDFF" }}>
        <tr>
          <td >Solution 1</td>
          <td>Python</td>
          <td >
          <span
                                  style={{
                                    marginRight: 15,
                                    color: "#51B960",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.penIcon("#51B960")}
                                </span>
                                <span
                                  style={{
                                    color: "#EB5757",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.circleCrossIcon({
                                    size: 19,
                                    color: "#EB5757",
                                  })}
                                </span>
          </td>
          
        </tr>
        <tr>
          <td>Solution 2</td>
          <td>Java</td>
          <td>
                                <span
                                  style={{
                                    marginRight: 15,
                                    color: "#51B960",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.penIcon("#51B960")}
                                </span>
                                <span
                                  style={{
                                    color: "#EB5757",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.circleCrossIcon({
                                    size: 19,
                                    color: "#EB5757",
                                  })}
                                </span>
          </td>
        </tr>
                </tbody>
              </Table>
              </div>
              )}
              {formValues?.type !== "CD" && (
              <div style={{ paddingTop:"20px", }}>
                       <Field
                          name="Sub Question 1"
                          label="Solution"
                          id="question"
                          disabled={false}
                          placeholder="Type here"
                          validate={[QuestionValidations.QUESTIONS]}
                          component={EditorField}
                        />
                        </div>
              )}
              </div>

                      
                      </div>
                    </div>
                    <hr />
                    
                    {formValues?.type === "CD" && (
                    <div>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          padding: 12,
                          paddingRight: 14,
                          flex: 2,
                          width: "50%",
                        }}
                      >
                         <h4>Default solution</h4> 
                        {/* <p style={{color:"#A6A6AA"}}>Add input and output testcases how much you want</p> */}
                      </div>
                      <div style={{ flex: 4, marginTop: 10, width: "50%" }}>
                      
                        <div
                          style={{
                            marginBottom: 10,
                          }}
                        >
                          <div style={{display: "flex", gap:"50px"}}>
                          <Button
                            style={{ marginBottom: 10 }}
                            color="success"
                            onClick={handleClickOpen}
                          >
                            <CustomIcons.PlusIcon size="17" color="#fff" />{" "}  
                            Add  default Solution
                          </Button>
                          </div>
                        
                        </div>
                        <div style={{width:"600px"}}>
                        <Table responsive style={{textAlign:"center"}}>
                <thead style={{ backgroundColor: "#C5EAFC", }}>
                  <tr>  
                    <th style={{ color: "#000"}}>S.NO</th>
                    <th style={{ color: "#000",}}>Language</th>
                    <th style={{ color: "#000"}}>Solutions</th>
                    <th style={{ color: "#000"}}>Action</th>
                  </tr>
                </thead>
                <tbody style={{ backgroundColor: "#F9FDFF" }}>
        <tr>
          <td >1</td>
          <td>Python</td>
          <td>Solution 1</td>
          <td >
          <span
                                  style={{
                                    marginRight: 15,
                                    color: "#51B960",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.penIcon("#51B960")}
                                </span>
                                <span
                                  style={{
                                    color: "#EB5757",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.circleCrossIcon({
                                    size: 19,
                                    color: "#EB5757",
                                  })}
                                </span>
          </td>
          
        </tr>
        <tr>
          <td>2</td>
          <td>Java</td>
          <td>Solution 2</td>
          <td>
                                <span
                                  style={{
                                    marginRight: 15,
                                    color: "#51B960",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.penIcon("#51B960")}
                                </span>
                                <span
                                  style={{
                                    color: "#EB5757",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.circleCrossIcon({
                                    size: 19,
                                    color: "#EB5757",
                                  })}
                                </span>
          </td>
        </tr>
                </tbody>
              </Table>
              </div>
                      
                      </div>
                    </div>
                    <hr />
                    </div>
                    )}
                    
                    {formValues?.type !== "MC" && (
                    <div>
                    <div style={{ display: "flex" }}>
                      <div style={{ padding: 15, flex: 2 }}>
                        <h4>Solution Media</h4>
                        <p>Upload Solution Media if any</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flex: 3,
                          flexWrap: "wrap",
                          marginTop: 10,
                          paddingRight:"260px",
                        }}
                      >
                        <Field
                          name="solutionMedia"
                          component={QuestionMedia}
                          label="Solution Media"
                          media={
                            initialValues?.solutionMedia
                              ? initialValues?.solutionMedia
                              : ""
                          }
                          len={10}
                          props={{
                            type: ["video/*"],
                            sizeLimit: 10000000,
                          }}
                        />
                      </div>
                    </div>
                    <hr />
                    </div>
                    )}
                    </div>
                    </div>

                    <div>
                    <div>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          padding: 12,
                          paddingRight: 14,
                          flex: 2,
                          width: "50%",
                        }}
                      >
                       <h4>Attributes</h4>
                        <p style={{color:"#A6A6AA"}}>Enter question weightages, marks and duration</p>
                      </div>
                      <div style={{ flex: 4, marginLeft: -25, width: "50%" , }}>
                    
                        <div
                          style={{
                            marginTop: 10, 
                          }}
                        >
                          <div style={{display: "flex", gap:"10px"}}>
                          <Field
                          name="skillWeightage"
                          component={renderNumberField}
                          placeholder="e.g.,2"
                          label="Marks"
                        />

                        <Field
                          name="knowledgeWeightage"
                          component={renderNumberField}
                          placeholder="e.g.,2"
                          label="Negative marks"
                        />
                          </div>
                        
                        </div>
                      
                      </div>
                    </div>
                    <hr />
                    </div>
                    </div>
                    </div>
                    </div>
                   
 
                  <div style={{ textAlign: "center" }}>
                    <Button
                      type="submit"
                      color="primary"
                      style={{
                        fontSize: 16,
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}
                    >
                    Save
                    </Button>
                  </div>
           
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Link
        to={{ pathname: "/view-questions", state: props.idAndName }}
        className="btn text-muted d-none d-sm-inline-block btn-link"
      >
        <i className="mdi mdi-arrow-left mr-1" /> Back to create new question {" "}
      </Link>
    </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  initialvalues: initialValues || {},
  formValues: getFormValues("crqForm")(state),
});
const crqForm = reduxForm({
  form: "crqForm",
  asyncBlurFields: ["name"],
  enableReinitialize: true,
})(CreateQueComponentSub);
export default connect(mapStateToProps, { saveQuestion })(crqForm);