import React ,{useState}from 'react'
import ReactHtmlParser from "react-html-parser";
import { IconButton, useMediaQuery } from "@material-ui/core";

import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import InfoIcon from '@material-ui/icons/Info';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import RemoveCircleOutlineSharpIcon from '@material-ui/icons/RemoveCircleOutlineSharp';

export default function HackathonFAQs(props) {
const [show,setShow] = useState({})
const smallScreen = useMediaQuery("(max-width: 767px)");
return (
<div>
    <div>
        <div className={smallScreen ? '' : "pt-3" }>
            <div style={smallScreen ? {display:"flex"} : {display:"flex", paddingLeft: "3rem" }}>
                <h1 className="hackathonCardHeader" style={{fontSize: "20px"}}>Frequently Asked Questions (FAQs)</h1>
                <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 5L7 0V10L14 5ZM0 0V10L7 5L0 0Z" fill="#2170D8" />
                </svg>
            </div><br />
            {props.overview.faq.map((data, index)=> (
            <p className={smallScreen ? '' :"paragraphreg"}>
                <h3 className="hackathonCardHeader" style={{
                height: '26px',
                left: '34px',
                top: '0px',
                fontSize: "16px",
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '26px',
                color: '#19181B',
            }}>
                    <IconButton onClick={()=> setShow(index)}>
                        {show == index ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <rect width="24" height="24" rx="12" fill="#F8F5FF" />
                            <path d="M7.33301 11.332H16.6663V12.6654H7.33301V11.332Z" fill="#794DF5" />
                        </svg>:null}

                        {show !== index ?
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="24" height="24" rx="12" fill="#F8F5FF" />
                            <path
                                d="M16.6663 11.332H12.6663V7.33203H11.333V11.332H7.33301V12.6654H11.333V16.6654H12.6663V12.6654H16.6663V11.332Z"
                                fill="#794DF5" />
                        </svg>:null}
                    </IconButton>
                    {data?.question || ""}
                </h3>
                <div>
                    <br></br>
                    {show == index ?
                    <p style={{
                height: '52px',
                left: '0px',
                fontSize: "16px",
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '26px',
                color: '#323036',
            }}>
                        {data?.answer || ""}
                    </p>:null}
                </div>
            </p>
            ))}






        </div>
    </div>
</div>
)
}