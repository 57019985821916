// import { Hackathon } from "assets/icons/icons";
// import hackathonRegister from "../pages/hackathon/hackathonStudent/hackathonRegister";

// function getDomainApi() {
//   let siteUrl = window.location.host;
//   let apiUrl = "";
//   if (siteUrl.indexOf("localhost") > -1)
//     apiUrl = "https://campus-api.terv.pro/api/";
//   else {rl.replace("http://www.", "");
//     siteUrl = siteUrl.replace("https://", "");
//     siteUrl = siteUrl.replace("http://", "");
//     siteUrl = siteUrl.replace("www.", "");
//     apiUrl = siteUrl.split(".")[0];
//     apiUrl = `https://${apiUrl}-api.docsauth.io/`;
//   }
//   return apiUrl;
// }


const apiList = {
  baseURL: "https://api.terv.pro/api/",
  // baseURL: getDomainApi(),
  homePage: {
    findCustomerByDomainName: "customer/findCustomerByDomainName/",
    getTopPackages: "homepage/getTopPackages",
    getTopCourses: "homepage/getTopAssessments",
    getCustomerLogo: "homepage/getLogo",
    getHomePageRole: "homepage/getHomePageRole",
    getHomePageSkills: "homepage/getHomePageSkills",
    addHomePageUser:"homepage/addHomePageUser",
    findAssessmentLinkByRole: `homepage/findAssessmentLinkByRole`,
    saveQuickAssessmentDetails: 'homepage/saveQuickAssessmentDetails'
  },
  cart: {
    add(productType, productId) {
      return `payment/saveProductCart/${productType}/${productId}`;
    },
    remove(productId) {
      return `payment/removeProductCart/${productId}`;
    },
    get(userId) {
      return `payment/getCartProduct/${userId}`;
    },
  },
  customBatchMgmt: {
    create: "batch/createBatch",
    readAll: "batch/getBatch/",
    update: "batch/updateBatch",
    delete: "batch/deleteBatch/",
    checkDuplicate: "batch/checkBatchExist/",
  },
  checkout: {
    getAmount({ userId, type, entireCart }, ...productIds) {
      return `payment/getPaymentAmount?userId=${userId}${
        !entireCart
          ? `&productId=${productIds.join(",")}&productType=${type}`
          : ""
      }`;
    },
    getPackages({ userId, type, entireCart }, ...productIds) {
      return `payment/getPracticeLabList?userId=${userId}${
        !entireCart
          ? `&productId=${productIds.join(",")}&productType=${type}`
          : ""
      }`;
    },
    order: "payment/orders",
    pay: "payment/saveUserTransaction",
  },
  prepare: "prepare/",
  saveLoginActivity: "terv/saveLoginActivity",
  authTokenURL: "http://139.59.33.158:8083/oauth/token",
  loginValidation: "terv/userLogin",
  adminDashboard: "dashboard/getAdminDashBoardDisplayDetails/",
  studentDashboard: "dashboard/getStudentDashBoardDisplayDetails/",
  lcUserManagement: {
    getUserProfile: "users/getuserprofile/",
    getclassess: "users/getclasses",
    getstudent: "users/getstudentsbyclass",
    getAttachment: "users/getAttachment/",
    uploadAttachment: "users/uploadAttachment",
    getApplicableAuthors: "users/getapplicableauthors",
  },
  tervAPI: {
    getUserProfile: "api/session/user",
    getcolleges: "api/getCollegesByUser",
    getcollegeStudents: "api/admin/session/students/",
  },
  customerManagement: {
    create: "users/createCustomer",
    upload: (customer) => `users/upload/${customer}`,
    uploadStatus: (id) => `upload-status/${id}`,
    update: "users/updateCustomer/",
    delete: "users/deleteCustomer/",
    get: "users/getCustomerById/",
    getAll: "users/getAllCustomers/",
    getBatches: "users/getBatchesByCustomer/",
    getStudentsByBatch: "users/getStudentsByCustomBatch/",
    checkIfBatchExists: "users/checkIfBatchNameExists/",
    checkCustomerEmail: "users/checkCustomerEmail/",
    checkCustomerName: "users/checkCustomerName/",
  },
  userManagement: {
    getUserInfo: "users/getUserInfo/",
    saveUserinfo: "users/saveUserinfo/",
    fileUpload: "fileManagement/upload",
    executeCodingResults: "userAssessment/executeCodingResults",
    executeCode: "userAssessment/executeCode",
    executeCodingTestCase: "lab/saveCodingQuestionSolution",
    getExecutionStatus(rId, qId) {
      return `lab/getTestCaseStatus/${rId}/${qId}`;
    },
    // executeCodingTestCase: "userAssessment/executeCodingTestCase",
    getAssessmentQuestions: "userAssessment/getAssessmentQuestions/",
    getAssementReview: "userAssessment/getAssessmentReview/",
    getAssessmentGradingStatus:
      "userAssessment/getAssessmentStudentGradingStatus/",
    getUserAssessment: "userAssessment/getUserAssessments/",
    saveUserAssessment: "userAssessment/saveUserAssessmentStatus/",
    getUserAssessmentStatus(reqId, assessmentId, labId) {
      return labId
        ? `userAssessment/getUserAssessmentStatus/${reqId}/${assessmentId}?labId=${labId}`
        : `userAssessment/getUserAssessmentStatus/${reqId}/${assessmentId}`;
    },
    userSolution: "userAssessment/saveUserSolution",
    getInfo: "userAssessment/getAssessmentInfo/",
    getCustomAssessmentInfo: "userAssessment/getCustomAssessmentInfo/",
    verifyPass: "userAssessment/validateUserPasscode",
    getPrivileges: "user/getPrivilegesList/",
    getPrivilegesV2: "user/getPrivilegesByUserId/",
    getUserRolesList: "user/getUserRoleList",
    saveNewRole: "user/saveUserRole",
    createUser: "users",
    updateUser: "users/updateUser/",
    deleteUser: "users/deleteUserById/",
    getUser: "users/getUserById/",
    getUserByEmail: "users/getUserByEmail/",
    getAllUsers: "users/getAllUsersByCustomer/",
    deleteUsersAll:
      "users/delete/User",
    getRoles: "roles/customers/",
    getStudentsByUser: "users/getStudentsByCustomer/",
    getStudentReport: "/students-report",
    changePassword: "users/change_password",
    creatNewUser: "users/addUser",
    resetPassword: "users/resetpassword/",
    changePassword: "users/changePasswordUsers/",
    getDepartments: "users/getDepartmentByCustomer/",
    saveMarks: "userAssessment/saveAdminAssessmentMarks",
    publishGrade: "userAssessment/publishStudentGradingReport/",
    getReportingTos: "user/getReportingToUser/",
    checkUserEmail: "users/checkUserEmail/",
    getUserAssessmentInfo: "userAssessment/getUserAssessmentInfo/",
  },
  roleManagement: {
    getPrivileges: "roles/getPrivilegesListByRole/",
    getAllRoles: "roles/getrolesByCustomer/",
    createRole: "roles/createRoleWithTypes/",
    deleteRole: "roles/deleteRole/",
    getCreatedRoles: "roles/getroleListByCustomer/",
    checkRoleAlreadyCreated: "roles/checkRoleExists/",
  },
  dashboard: {
    getDashboard: "user/getUserDashBoardData/",
    getAdminData: "user/getAdminDashBoardData/",
    getUserReport: "user/getStudentDashBoardDetails/",
    getPackageCount: "dashboard/getAdminDashBoardPackageCount/",
    getCourseCount: "dashboard/getAdminDashBoardCourseCount/",
    getAssessmentCount: "dashboard/getAdminDashBoardAssessmentCount/",
    getUserCount: "dashboard/getAdminDashBoardDetails/",
    getCustomerExpired:"dashboard/getCustomerExpire/",
    getEvents: "dashboard/getAdminDashBoardCalendar/",
  },
  Prepare: {
    getAllByCustomer: "course/getAllCoursesByCustomer/",
    getAllByAdmin: "course/getAllCourses",
    getCourse: "course/getCourse/",
    getAllModules: "course/getAllCourseModules/",
    create: "course/saveCourse",
    deleteCourse: "course/deleteCourse/",
    saveSections: "course/saveCourseModule",
    saveOverview: "course/saveCourseOverviews",
    saveSettings: "course/saveCourseSettings",
    savePricings: "course/saveCoursePricings",
    updateStatus: "course/updateCourseStatus",
    courseNameCheck: "course/checkCourseNameExists/",
    getAdminCourseReportDashboard: "adminReport/getAdminCourseReportDashboard/",
    getCertificateTemplateList: "course/getCertificateTemplateList/",
    filter: "userCourse/searchUserCourses",
    externalCourse: "userCourse/startClientCourse/",
    externalCourseApi: "userCourse/samlTokenGenerate/",
    getQuestionList: `question/getQuestionList/`,
    videoQuizzSolution: `userCourse/videoQuizzSolution`,
    getBasePdf: `fileManagement/mediaEncode`,
    studentVideoQuizReport: "userCourse/getvideoQuizzDetails/",
  },
  assessmentController: {
    getMockAssessmentList: "assessment/admin/getMockAssessmentList/",
    getMockAssessmentListNew: "assessment/admin/getMockAssessment",
    activeUsersList: "terv/getActiveUserList/",
    assessment: "assessment/admin/createOrUpdate/assessment",
    deleteAsessment: "assessment/admin/delete/assessment",
    getAssessmentReportData: "assessmentReport/getAssessmentReport",
    getAllAssessment: "assessment/admin/getAllAssessments",
    getAssessmentbyId: "assessment/admin/getAssessment/byId",
    getAssessmentbyCustomer: "assessment/admin/getAssessmentByCustomerId/",
    getsummary: "assessment/admin/getAssessmentSummary/",
    saveImage: "userAssessment/saveUserAssessmentImage",
    checkIfNameExixts: "assessment/admin/checkIfNameExists/",
    checkIfLinkExists: "assessment/admin/checkIfAssessmentLinkExists/",
    getAssessmentReport: "assessment/getStudentReport/",
    errorLogger: "userAssessment/saveUserAssessmentStatusTrack/",
    getLanguagesByAssessment: "api/languages/",
    checkForCD: "assessment/checkQPCodingQuestions/",
    updatePendingUserAssessment:
      "userAssessment/updatePendingUserAssessmentStatus/",
    getAdminOverallReport: "adminReport/getAdminOverallReport/",
    getAdminPurchaseReport:"adminReport/getAdminPurchaseReport/",
    getAdminEnquiryReport:"adminReport/getAdminEnquiryReport/",
    // http://localhost:8087/api/adminReport/getAdminPurchaseReport/60b34374a94b5e3a7072b19b?userId=605b53fdd0fc911042558ffe
    getPublicAssessments: "userAssessment/getPublicAssessments",
    getAssessmentNameListByCustomer:
      "assessment/getAssessmentNameListByCustomer/",
    getAssessmentSectionSummary:
      "assessmentReport/getAssessmentSectionSummary/",
    getAssessmentUsersDetail: "userAssessment/getAssessmentUsersDetail/",
    getLiveAssessmentDetail: "assessment/admin/getLiveAssessmentView/",
    getStudentsByAssessment: "users/getStudentsByAssessment/",
    getAssessmentScoreSummary: "assessmentReport/getAssessmentScoreSummary/",
    getAssessmentSectionPerformance:
      "assessmentReport/getAssessmentSectionPerformance/",
    getAssessmentPerformanceSummary:
      "assessmentReport/getAssessmentPerformanceSummary/",
    getAssessmentImprovementAreas:
      "assessmentReport/getAssessmentImprovementAreas/",
    getCollegeName: "master/getRefCollegeNames",
    getDept:"master/getRefDetails/department",
    getDegree:"master/getRefDetails/degree",
    getGraduate:"master/getRefDetails/graduated",
    getQuestionSection: "assessmentQuestionPaper/admin/getQPSectionsList/",
    lastQuestionUpdate: "userAssessment/saveLatestQuestionId/",
  },
  masterController: {
    getAllCompetency: "master/getCompetencies",
    createCompetency: "master/saveCompetency",
    deleteCompetency: "master/deleteCompetency/",
    getSubCompetency: "master/getSubCompetency/",

    getAllDomain: "master/getAllDomain",
    createDomain: "master/saveDomain",
    deleteDomain: "master/deleteDomain/",
    getSubDomain: "master/getSubDomain/",

    getAllProficiency: "master/getAllProficiency",
    createProficiency: "master/saveProficiency",
    deleteProficiency: "master/deleteProficiency/",

    getAllTaxonomy: "master/getAllTaxonomy",
    createTaxonomy: "master/saveTaxonomy",
    deleteTaxonomy: "master/deleteTaxonomy/",
    getSubTaxonomy: "master/getSubTaxonomy/",

    getAllCategory: "master/getAllCategory",
    getAllCourseCategory: "master/getAllCourseCategory",
    createCategory: "master/saveCategory",
    deleteCategory: "master/deleteCategory/",

    getAllTopic: "master/getTopicByCategory/",
    createTopic: "master/saveTopicByCategory/",
    deleteTopic: "master/deleteTopicByCategory/",
    getSubTopic: "master/getSubTopicByCategory/",

    getAllCourseCategory: "master/getAllCourseCategory",
    getAllCourseType: "master/getAllCourseTypes",
  },
  QuestionPaperController: {
    assessmentQuestionPaperCreate:
      "assessmentQuestionPaper/admin/createOrUpdate/assessmentQuestionPaper",
    createSection: "assessmentQuestionPaper/admin/createOrUpdate/section",
    deleteAssemmentQuestionpaper:
      "assessmentQuestionPaper/admin/delete/assessmentQuestionPaper",
    getallQuestionPaper:
      "assessmentQuestionPaper/admin/getAllAssessmentQuestionPapers",
    getQPbyCustomer: "assessmentQuestionPaper/admin/getAssessmentQPByCustomer/",
    getValidQuestionPapers:
      "assessmentQuestionPaper/admin/getValidQuestionPapers/",
    getAssessmentQuestionpaperbyId:
      "assessmentQuestionPaper/admin/getAssessmentQuestionPaper/byId",
    checkIfNameExists: "assessmentQuestionPaper/admin/checkIfNameExists/",
    deleteQuestionFromSection:
      "assessmentQuestionPaper/deleteQuestionFromSection/",
    displaySectionQuestions: "question/admin/getSectionQuestions/",
    updateGradingProcess: "assessmentQuestionPaper/updateGradingProcess",
    saveAdminAssessmentMarks: "userAssessment/saveAdminAssessmentMarks",
    createQPcopy: "assessmentQuestionPaper/createQPcopy",
    deleteSection: "assessmentQuestionPaper/admin/deleteSection",
    checkQPActive: "assessmentQuestionPaper/admin/checkQPActiveAssessment",
  },
  LanguageController: {
    currentLanguage: "api/languages",
  },
  questionController: {
    createQuestionCoding: "question/admin/createOrUpdate/CDQuestion",
    createQuestionMCQ: "question/admin/createOrUpdate/MCQuestion",
    createQuestionDescriptive: "question/saveQuestion",
    deleteQuestion: "question/admin/delete/Question",
    getallQuestion: "question/admin/getAllquestions",
    getQuestionById: "question/admin/getQuestion/byId",
    checkIfNameExists: "question/admin/checkIfNameExists/",
    getProficiencies: "master/getAllProficiency",
    getTaxonomies: "master/getAllTaxonomy",
    getSubTaxonomy: "master/getSubTaxonomy/",
  },
  questionBankController: {
    bulkUploadQuestions: "question/bulkupload/",
    getQuestionBank: "question/getQuestionBank/",
    getQuestionBanksByCollege: "question/getQuestionBankListByUser/",
    getQuestionBanksByCustomerUser: "question/getQuestionBankListByCustomer/",
    getAllQuestionBanks: "question/getQuestionBankList/",
    getAllCompetencies: "master/getCompetencies",
    getSubCompetencies: "master/getSubCompetency/",
    getAllDomains: "master/getAllDomain",
    getSubDomains: "master/getSubDomain/",
    saveQuestionBank: "question/saveQuestionBank",
    getQBQuestions: "question/getQuestionBankQuestionsList/",
    getQBFilterList: "question/getQuestionBankFilterList/",
    getQBUnselectedFilterList: "question/getUnSelectedQBFilterList/",
    getQBUnselectedQuestions: "question/getUnSelectedQBQuestionsListWithAll/",
    getQPUnselectedQuestions: "question/getUnSelectedQBQuestionsList/QP",
    getLabUnselectedQuestions: "question/getUnSelectedLabQuestionsList/Lab",
    deleteQuestionFromQB: "question/deleteQuestionFromQB",
    copyQuestions: "question/copyQuestions",
    checkName: "question/admin/checkIfQBNameExists/",
    UpdateStatus: "question/updateQuestionBankStatus",
    ApproveBank: "question/getQuestionBankList/approved",
    deleteQuestionBank: "question/admin/delete/QuestionBank/",
  },
  headers: {
    authorization:
      "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJUZXJ2IiwiZXhwIjoyNTUzMTQ5MDE5LCJpYXQiOjE2MDY0NjQyMTl9.pK5jP6BjuKtHPrZrf3CNSHQMRZsvRbumiSal0rfcJv8",
  },
  courseController: {
    getCourseFaq: "course/getCourseFAQ/",
    getUserCourses: "userCourse/getUserCourses/",
    getCourseDetails: "userCourse/getCourseDetails/",
    startCourses: "userCourse/startCourse/",
    updateProgress: "userCourse/updateProgress/",
    resourceActivity: "userCourse/create/resourceActivityLog",
    saveDiscussion: "course/saveDiscussion",
    getDiscussion: "userCourse/getDiscussion/",
    submitQuiz: "userCourse/submitQuiz/",
    getUserQuizList: "userCourse/getUserQuizList/",
    courseReport: "course/getCourseReport/",
    courseRating: "userCourse/saveUserCourseRating",
    CourseComment: "userCourse/saveUserCourseComment",
    courseDetails: "course/viewReportDetails/",
    getRelatedCourses: "userCourse/getRelatedCoursesByCategory/",
    getCourseInstruction: "course/getCourseInstruction/",
    getCourseDiscussion: "course/getCourseDiscussion/",
    getCourseResourceDiscussion: "course/getCourseResourceDiscussion/",
    getCourseNotes: "course/getCourseNotes/",
    createCourseNotes: "course/createCourseNotes",
    updateCourseNotes: "course/updateCourseNotes",
    deleteCourseNotes: "course/deleteCourseNotes/",
    getRating: "userCourse/getCourseRatingAndComment",
    getcertificate: "userCourse/downloadCertificate/",
  },
  CoursesManagement: {
    saveCourseresource: "course/saveCourseResource",
    saveCourse: "course/saveCourse",
    saveCourseModule: "course/saveCourseModule",
    saveCourseOutcomes: "course/saveCourseOutcomes",
    saveCourseFAQ: "/course/saveCourseFAQ",
    getallcourses: "course/getCourses/",
    getCourse: "course/getCourse/",
    getAllCourseModules: "course/getAllCourseModules/",
    getCourseModule: "course/getCourseModule/",
    getAllResources: "course/getAllResources/",
    getCourseResource: "course/getResource/",
    getCourseOutcomes: "course/getCourseOutcomes/",
    getCourseFAQ: "course/getCourseFAQ/",
    deleteCourse: "course/deleteCourse/",
    deleteModule: "course/deleteModule/",
    deleteResource: "course/deleteResource/",
    deleteFaq: "course/deleteFaq/",
    getCourseStatusSummary: "course/admin/getCourseStatusSummary/",
    updateCourseStatus: "course/updateCourseStatus/",
  },
  professorAssignment: {
    getAllAssignment: "assignment/getAssignments",
    createAssignment: "assignment/saveAssignment",
    deleteAssignment: "assignment/deleteAssignment/",
    assignmentById: "assignment/getAssignment/",
    mapStudent: "assignment/mapStudents",
    studentList: "assignment/getStudentList/",
    evaluateAssignment: "assignment/evaluateAssignment",
  },

  userAssignment: {
    getUserAssignment: "userAssignment/getUserAssignments/",
    getAssignmentDetails: "userAssignment/getAssignmentDetails/",
    saveUserAssignment: "userAssignment/saveUserAssignment",
  },
  practicePackage: {
    getLearningPathData: "question/admin/getCategoryByQuestions/",
  },
  lcFileUplod: {
    uploadAttachment: "users/uploadAttachment",
  },
  lab: {
    getUserPracticeLabGuide: "lab/getUserPracticeLabGuide/",
    getPracticeSummary: "lab/getUserPracticeLabSummary/",
    saveActivity: "lab/saveUserLabActivityInfo",
    getCustomerLabs: "lab/getAllPracticeLabByCustomer/",
    getFreePracticeLabByCustomer: "lab/getFreePracticeLabByCustomer/",
    getUserLabActivity: "lab/getUserLabActivityInfo/",
    getAllLab: "lab/getAllPracticeLab",
    getTopprepare: "homepage/getTopPrepare",
    deleteLab: "lab/deletePracticeLab/",
    saveUserSolution: "lab/saveUserLabSolution",
    saveLab: "lab/savePracticeLab",
    getLab: "lab/getPracticeLab/",
    getAllLanguages: "api/languages",
    userPracticeLabList: "lab/getUserPracticeLabList/",
    userPracticeLab: "lab/getUserPracticeLab/",
    userPracticeLabById: "lab/getUserPracticeLabByLabId/",
    getUserPracticeLabForThirdParty: "lab/getUserPracticeLabForThirdParty/",
    getSelectedQuestionsList: "lab/getSelectedQuestionsList/",
    getQuestionList: "lab/getQuestionsList/",
    checkIfNameExists: "lab/checkIfNameExists/",
    getStudentsByPractice: "users/getStudentsByPractice/",
    labLanguage: "api/labLanguages/",
    downloadCertificate: "lab/downloadCertificate/",
    getCertificateTemplateList: "lab/getCertificateTemplateList/",
    questionCustomCondition: "lab/questionCustomCondition",
  },

  communicationModule: {
    sendEmail: "communication/sendEmail",
    saveTemplate: "communication/saveTemplate",
    getTemplate: "communication/getTemplateList",
    getSentMailList: "communication/getSentMailList/",
    getScheduledMailList: "communication/getScheduledMailList/",
    getDraftMailList: "communication/getDraftMailList/",
    getTemplateList: "communication/getTemplateList/",
    getTotalCount: "communication/getCommunicationCount/",
    deleteSentMail: "communication/deleteSentMail/",
    deleteScheduledMail: "communication/deleteScheduledMail/",
    deleteDraftMail: "communication/deleteDraftMail/",
    deleteTemplate: "communication/deleteTemplate/",
    getMailById: "communication/getMailById/",
  },
  CloudLab: {
    Create: "cloudLab/createOrUpdate/cloudLab",
    CloudlabGet: "cloudLab/getCloudLabList",
    CloudLabDelete: "cloudLab/deleteColudLab/",
    CloudLabStudent: "cloudLab/getParticipantCloudLabList",
    CloudLabNameCheck: "cloudLab/checkCloudLabExist",
    CloudLabGtDetails: "cloudLab/getCloudLabDetails",
  },
  Hackathon: {
    CreateOrUpdate: "hackathon/saveHackathonInfo",
    UpdateOverviews: "hackathon/saveHackathonContent",
    GetAllCourses: "hackathon/getHackathonList",
    saveSettings: "hackathon/saveHackathonSettings",
    savePrize: "hackathon/saveHackathonPrizes",
    deleteHackathon: "hackathon/deleteHackathon/",
    getCourse: "hackathon/GetCourse",
    getUserHackathon: "userHackathon/getUserHackathonList",
    getHackathonDetails: "userHackathon/getUserHackathonDetails/",
    hackathonRegister: "userHackathon/registerHackathon/",
    hackathonSubmission: "userHackathon/saveUserSubmission",
    getHackathonSubmissionList: "userHackathon/getUserSubmissionsList",
    submissionDelete: "userHackathon/deleteUserSubmission",
    projectSubmit: "userHackathon/submitUserHackathon",
    projectFormDetails: "userHackathon/getUserSubmissionDetails/",
    getHackathonReview: "userHackathon/getUserSubmissionGrading/",
    postProjectMarks: "userHackathon/saveSubmissionGrading",
    saveTopperRank: "userHackathon/saveTopperRank",
  },
  CustomChecker: {
    AddCustomCheck: "api/question/admin/createOrUpdate/CDQuestion",
    EnabkeCustomCheck: "/api/lab/savePracticeLab",
    RunCode: " /api/userAssessment/executeCodingResults",
    Submit: " /api/lab/questionCustomCondition",
  },
  AiProctoring: {
    AiSaveCall: "userAssessment/saveUserProctoring/",
  },
};
export default apiList;
