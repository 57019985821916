import { useEffect, useState } from "react";
import cn from "classnames";
import Apihelper from "api/apiHelper";
import api from "api/baseConfig";
import { Collapse, CircularProgress } from "@material-ui/core";
import { ArrowDropUp, ArrowDropDown } from "@material-ui/icons";
import Loader from "react-loader-spinner";
import Section from "../../components/section/section";
import styles from "./output.module.scss";
import { throws } from "assert";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const CodeExecResult = ({
  customInput,
  execution,
  idx,
  isCustomInput,
  isLightMode,
  isLoading,
  isTestCase,
  codeErr,
  messageRes,
  // resValue,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <div
      className={cn(
        styles.codeExecResult,
        isLightMode ? styles.light : styles.dark,
        {
          [styles.customInput]: isCustomInput,
          ...(execution?.status?.id && {
            [styles.error]: execution?.status?.id !== 3,
            [styles.success]: execution?.status?.id === 3,
          }),
        }
      )}
    >
    {/* {console.log(resValue)}
      {resValue ? (
      <div
        className={styles.codeExecResultHeading}
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => {
            setOpen((o) => !o);
        }}
      >
        {`${
          isTestCase
            ? `Test case #${idx}`
            : isCustomInput
            ? "Custom Input"
            : "Sample input"
        }`}
        <span style={{ margin: "auto 0 auto auto", height: 24 }}>
          {open ? (
            <ArrowDropUp style={{ marginTop: -6 }} />
          ) : (
            <ArrowDropDown style={{ marginTop: -6 }} />
          )}
        </span>
      </div>
      ):( */}
        <div
        className={styles.codeExecResultHeading}
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => {
          if (!isLoading) {
            setOpen((o) => !o);
          }
        }}
      >
        {`${
          isTestCase
            ? `Test case #${idx}`
            : isCustomInput
            ? "Custom Input"
            : "Sample input"
        }`}
        <span style={{ margin: "auto 0 auto auto", height: 24 }}>
          {isLoading ? (
            <CircularProgress
              variant="indeterminate"
              color="inherit"
              size={24}
            />
          ) : open ? (
            <ArrowDropUp style={{ marginTop: -6 }} />
          ) : (
            <ArrowDropDown style={{ marginTop: -6 }} />
          )}
        </span>
      </div>
      {/* <div>
       <br />
       <span className={styles.title} style={{paddingLeft:"2%"}}>Invalid response</span>
       <br /> 
      </div>
      )} */}

      {/* {!resValue ? (
      <div> */}
      {!isLoading && (
        <Collapse in={open}>
          <div className={styles.codeExecResultContent}>
           {isCustomInput ? (
              <>
                <span className={styles.title}>Execution Input:</span>
                <br />
                <span className={styles.description}>{customInput || ""}</span>
                <br />
                <span className={styles.title}>Execution Output:</span>
                <br />
                {/* {idx == 3 && */}
                <span className={styles.description}>
                  {execution?.stdout ? execution?.stdout : "No Output"}</span>
                {/* } */}
                <br />
                <br />
              </>
            ) : (
              <>
              {idx == 1 ?
              (
                <>
                <span className={styles.title}>Execution Status</span>
                  &nbsp; &nbsp; -&nbsp;&nbsp;
                {codeErr ? (
                    <span className={styles.description}>
                    {execution?.compile_output
                      ? execution?.compile_output
                      : execution?.stderr
                      ? window.atob(execution?.stderr)
                      : execution?.status?.description}
                  </span>
                ) : (
                  <span className={styles.description}>
                  {execution?.status?.description}
                </span>
                )}
                <br />
                <br />
                <span className={styles.title}>Execution Time</span>
            &nbsp;&nbsp;-&nbsp;&nbsp;
            <span className={styles.description}>
              {execution?.time || "--"} sec
            </span>
            <br />
            <br />
            <span className={styles.title}>Execution Memory</span>
            &nbsp;&nbsp;-&nbsp;&nbsp;
            <span className={styles.description}>
              {execution?.memory || "--"}
            </span>
                </>
              ) :
              (idx == 2 &&
                <>
                <span className={styles.title}>Execution Status</span>
                &nbsp;&nbsp;-&nbsp;&nbsp;
                <span className={styles.description}>
                  {execution?.status?.description}
                </span>
                <br />
                <br />
                <span className={styles.title}>Execution Time</span>
            &nbsp;&nbsp;-&nbsp;&nbsp;
            <span className={styles.description}>
              {execution?.time || "--"} sec
            </span>
            <br />
            <br />
            <span className={styles.title}>Execution Memory</span>
            &nbsp;&nbsp;-&nbsp;&nbsp;
            <span className={styles.description}>
              {execution?.memory || "--"}
            </span>
                </>
              )
              }
              </>
            )}
            {idx == 3 &&
                <>
                <span className={styles.title}>Execution Status</span>
                  &nbsp; &nbsp; -&nbsp;&nbsp;
                  <span className={styles.description}>
                  {execution?.status?.description}
                </span>
                <br />
                <br />
                <span className={styles.title}>Execution Time</span>
            &nbsp;&nbsp;-&nbsp;&nbsp;
            <span className={styles.description}>
              {execution?.time || "--"} sec
            </span>
            <br />
            <br />
            <span className={styles.title}>Execution Memory</span>
            &nbsp;&nbsp;-&nbsp;&nbsp;
            <span className={styles.description}>
              {execution?.memory || "--"}
            </span>
                </>
            }
            {idx == 4 &&
             <>
             <span className={styles.title}>Execution Status</span>
               &nbsp; &nbsp; -&nbsp;&nbsp;
               <span className={styles.description}>
                  {execution?.status?.description}
                </span>
             <br />
             <br />
             <span className={styles.title}>Execution Time</span>
         &nbsp;&nbsp;-&nbsp;&nbsp;
         <span className={styles.description}>
           {execution?.time || "--"} sec
         </span>
         <br />
         <br />
         <span className={styles.title}>Execution Memory</span>
         &nbsp;&nbsp;-&nbsp;&nbsp;
         <span className={styles.description}>
           {execution?.memory || "--"}
         </span>
             </>
            }
            {idx == 5 &&
               <>
               <span className={styles.title}>Execution Status</span>
                 &nbsp; &nbsp; -&nbsp;&nbsp;
                 <span className={styles.description}>
                  {execution?.status?.description}
                </span>
               <br />
               <br />
               <span className={styles.title}>Execution Time</span>
           &nbsp;&nbsp;-&nbsp;&nbsp;
           <span className={styles.description}>
             {execution?.time || "--"} sec
           </span>
           <br />
           <br />
           <span className={styles.title}>Execution Memory</span>
           &nbsp;&nbsp;-&nbsp;&nbsp;
           <span className={styles.description}>
             {execution?.memory || "--"}
           </span>
               </>
            }
          </div>
        </Collapse>
      )}
      {/* </div>
      ):(
      <div>
        <Collapse in={open}>
          <div className={styles.codeExecResultContent}>

                <span className={styles.title}>Execution Output:</span>
                <br />
                <br />
                <span className={styles.description}>{resValue}</span>
                <br /> 
          </div>
        </Collapse> 
      </div>
      )} */}
    </div>
  );
};

export default function OutputSection({
  codeExecs = [],
  codeExecTestCasesCount,
  codeExecType = "",
  customInput,
  executingCode,
  isLightMode,
  codeErr,
  questionId,
  // resValue,
  debugcCodeConform,
  messageRes,
  ...props
}) {
  const [testCase, setTestCase] = useState(true);
  const classes = useStyles();
  
  const tempTestCasesCount = codeExecTestCasesCount;

  return (
    <Section
      questionId={questionId}
      loadingDetails={executingCode && <p>Compiling code</p>}
      sucessfully={`Code compiled Successfully`}
      debugSetup={true}
      debugcCodeConform={debugcCodeConform}
      loadingDetails1={
        !(!tempTestCasesCount && executingCode) &&
        codeExecType === "test-case" &&
        tempTestCasesCount !== 0 && (
          <>
            <div>
              {tempTestCasesCount !== 0 &&
              tempTestCasesCount === codeExecs?.length ? (
                <div style={{ paddingTop: "56px", paddingRight: "20px" }}>
                  <>
                    {`Your code was compiled,`}
                    &nbsp;
                    <b>{`${
                      codeExecs?.filter(
                        (execution) => execution?.status?.id === 3
                      ).length
                    }/${tempTestCasesCount} test cases passed`}</b>
                  </>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    paddingTop: "60px",
                    paddingRight: "20px",
                  }}
                >
                  <p style={{ paddingTop: "14px", paddingRight: "18px" }}>
                    Running test cases{" "}
                    {codeExecs?.filter(
                      (execution) =>
                        execution?.status?.id === 4 ||
                        execution?.status?.id === 3
                    ).length + 1}
                  </p>
                  <Loader
                    type="ThreeDots"
                    color="#fff"
                    height={50}
                    width={50}
                  />
                </div>
              )}
            </div>
            <br />
            <br />
          </>
        )
      }
      compiledInfo={
        codeExecs.length > 0 && (
          <p
            style={{
              fontWeight: 500,
              paddingTop: "15px",
              paddingRight: "37px",
            }}
          >
            Code Compiled Successfully
          </p>
        )
      }
      testCaseLoading={
        codeExecTestCasesCount < 2 && (
          <p
            style={{
              fontWeight: 500,
              paddingTop: "15px",
              paddingRight: "37px",
            }}
          >
            Running test cases
          </p>
        )
      }
      rightSection={
        executingCode && (
          <div style={{ paddingTop: 6 }}>
            <Loader type="ThreeDots" color="#fff" height={50} width={50} />
          </div>
        )
      }
      sectionId="practice-lab-output"
      title="Output"
      {...props}
    >
      {!codeExecTestCasesCount && !executingCode && (
        <p className={styles.noHintText}>Run code to see output</p>
      )}
      {messageRes.length > 0 ? (
        <div className={classes.root}>
          <Alert severity="error">{messageRes[0]}</Alert>
        </div>
      ) : (
        <div>
        <div>
          {!(!codeExecTestCasesCount && executingCode) &&
            codeExecType === "test-case" &&
            codeExecTestCasesCount !== 0 && (
              <>
                <div className={styles.executionInfo}>
                  {codeExecTestCasesCount !== 0 &&
                  codeExecTestCasesCount === codeExecs?.length ? (
                    <>
                      {`Your code was compiled,`}
                      &nbsp;
                      <b>{`${
                        codeExecs?.filter(
                          (execution) => execution?.status?.id === 3
                        ).length
                      }/${codeExecTestCasesCount} test cases passed`}</b>
                    </>
                  ) : (
                    "Running your Code..."
                  )}
                </div>
                <br />
                <br />
              </>
            )}
        </div>
      
      {new Array(codeExecTestCasesCount).fill("").map((_, idx) => (
        <CodeExecResult
          customInput={customInput}
          execution={codeExecs[idx]}
          codeErr={codeErr}
          idx={idx + 1}
          messageRes={messageRes}
          isCustomInput={codeExecType === "custom-input"}
          isLightMode={isLightMode}
          isLoading={!codeExecs?.[idx]}
          isTestCase={codeExecType === "test-case"}
          // resValue={resValue}
        />
      ))}
      </div>
      )}
    </Section>
  );
}
