import { convertFromHTML } from "draft-js";
import { Table } from "reactstrap";
import * as CustomIcons from "../../../assets/icons/icons";
import "../../Tasks/QuestionForm.scss";

const getOnlyStrings = (str) =>
  convertFromHTML(str) &&
  convertFromHTML(str).contentBlocks &&
  convertFromHTML(str).contentBlocks.length > 0
    ? convertFromHTML(str).contentBlocks[0].text
    : str;

export const TypeTables = ({ values, initialValues, ...props }) => {
  return (
  <>
      <div>
        <Table striped bordered>
          <thead style={{ backgroundColor: "#DAF2FD" }}>
            <tr>
              {/* {props?.labelName > 0 &&
              props?.labelName?.map((header, ind) => ( */}
                
              {/* ))} */}
              <th style={{ width: 1000 }}>FAQs Questions</th>
              <th style={{ width: 100 }}>Faqs Answer</th>
              <th style={{ width: 100 }}>Action</th>
            </tr>
          </thead>
          <tbody className="typeTableBody">
            {/* {props?.length > 0 &&
            props?.labelName?.map((item, index) => ( */}
              <tr style={{ backgroundColor: "#F9FDFF" }} className="TableRow">
              <td>
                  <p>{props?.labelName?.FaqQuestion}</p>
                </td>
                <td>
                  <p>{props?.labelName?.FaqAnswer}</p>
                </td>
                <td style={{ width: 100 }}>
                  <div style={{display:'flex'}}>
                  <span
                    onClick={() => props.editItem(index)}
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    {CustomIcons.penIcon("#51B960")}
                  </span>
                  &nbsp; &nbsp; &nbsp;
                  <span
                    onClick={() => props.removeItem(index)}
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    {CustomIcons.circleCrossIcon({
                      size: 19,
                      color: "#EB5757",
                    })}
                  </span>
                  </div>
                </td>
              </tr>
            {/* ))} */}
          </tbody>
        </Table>
      </div>
  </>
  )
}
