import React, { Component } from "react";
import { getSafe } from "../../Taketest/common/testHelper";
import ReactHtmlParser from "react-html-parser";
import { Input, Row, Button } from "reactstrap";
import { uid } from "react-uid";
export default function Mcqreport(props) {
  const [acccordian, setAccordian] = React.useState(false);

  try {
    if (getSafe(() => props.data.solutionMap.selectedOption, null)) {
      let strCon = JSON.parse(
        JSON.stringify(props.data.solutionMap.selectedOption)
      );

      let v = strCon.substr(1, strCon.length - 2);
      let carry = v.split(",");
      carry.forEach((element) => {
        if (getSafe(() => props.data.optionsList, null)) {
          props.data.optionsList.forEach((option, i) => {
            if (option.id == element) {
              props.data.optionsList[i].userSelect = true;
            }
          });
        }
      });
    }
  } catch (e) {}

  return (
    <div style={{ padding: 10 }}>
      <div
        onClick={() => {
          setAccordian(!acccordian);
        }}
      >
        {" "}
        <h5>
          Question {props.num + 1}{" "}
          {acccordian ? (
            <i style={{ marginLeft: 10 }} className="fas fa-chevron-down"></i>
          ) : (
            <i style={{ marginLeft: 10 }} className="fas fa-chevron-right"></i>
          )}{" "}
        </h5>{" "}
      </div>

      {acccordian ? (
        <div style={{ padding: 10 }}>
          {ReactHtmlParser(props.data.question)}
          {props.from && props.from === "assessment" ? (
            props.grading === "manual" ? (
              <Row style={{ marginLeft: 6 }}>
                <label>Marks</label>{" "}
                <Input
                  type="number"
                  style={{ width: 100, marginBottom: 20, marginLeft: 10 }}
                  value={
                    props.grade
                      ? props.grade
                      : props.data.solutionMap?.marksGained
                  }
                  onChange={(e) => props.updateGrade(e.target.value)}
                  disabled={props.disabled}
                />
              </Row>
            ) : (
              <span>
                Marks Gained: &nbsp;{props.data.solutionMap?.marksGained}
              </span>
            )
          ) : (
            <span>
              Marks Gained: &nbsp;{props.data.solutionMap?.marksGained}
            </span>
          )}
        </div>
      ) : (
        ""
      )}
      <div style={{ padding: 15 }}>
        {acccordian &&
          props.data.optionsList &&
          props.data.optionsList.map((options, indx) => (
            <div
              key={uid(indx)}
              style={
                props.data?.solutionMap?.selectedOption?.includes(options.id) &&
                props.data?.solutionMap?.isCorrectOption
                  ? {
                      padding: 15,
                      marginTop: 10,
                      borderRadius: 10,
                      border: "2px solid #4ba868",
                      backgroundColor: "#e1efda",
                    }
                  : {
                      padding: 15,
                      marginTop: 10,
                      borderRadius: 10,
                      border: "2px solid #ff6666",
                      backgroundColor: "#ffcccc",
                    }
              }
            >
              <span dangerouslySetInnerHTML={{ __html: options.optionValue }} />
              {options.userSelect === true ? (
                <span style={{ float: "right" }}>
                  <i size="lg" className="bx bx-check-circle"></i>User's Choice
                </span>
              ) : (
                ""
              )}
            </div>
          ))}
      </div>
    </div>
  );
}
