import React, { useRef, useCallback } from "react";
import { Button } from "reactstrap";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { uploadFile } from "../Taketest/common/testHelper";
import Webcam from "react-webcam";
import { Link } from "react-router-dom";
import "./CheckModal.css";
import { sessionPrivileges } from "privileges";

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};

export function CheckModal(props) {
  const userPrivileges = sessionPrivileges();
  const webcamRef = useRef(null);

  const capture = useCallback(async () => {
    const imageSrc = webcamRef.current?.getScreenshot();

    if (imageSrc) {
      let g = imageSrc.split(",");
      let val = await uploadFile(g[1], {
        userId: userPrivileges.userName,
        qpId: props.assessmentData.assessmentQuestionPaperId,
      });
      props.setUserVerifiedPhoto(val);
    }
  }, [webcamRef]);

  const goToTest = () => {
    props.callback({
      id: props?.assessmentData?.id || props?.assessmentId,
      userId: userPrivileges.userName,
    });
  };
  return (
    <Dialog open={props.isOpen} onClose={props.close}>
      <DialogTitle
        style={{
          padding: 10,
          paddingBottom: 2,
          backgroundColor: "#000",
          fontSize: 17,
          fontWeight: "medium",
        }}
      >
        <span
          style={{
            color: "#fff",
          }}
        >
          Start Assessment
        </span>
      </DialogTitle>
      <DialogContent style={{ marginTop: 15, fontSize: 15, marginBottom: 15 }}>
        You are about to start this Assessment
        {props.authDetails.imageEnabled && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Webcam
              audio={false}
              height={350}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={350}
              videoConstraints={videoConstraints}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {!props.authDetails.userVerified ? (
          <Button color="primary" onClick={capture}>
            Capture photo
          </Button>
        ) : props.hackathonSubmission ? (
          <Link
            to={{
              pathname: "/taketest",
              search: `?id=${props.assessmentId}&userId=${userPrivileges.userName}`,
              state: { isHackathon: true, hackathonId: props?.hackathonId },
            }}
            className="btn btn-primary waves-effect waves-light btn-sm"
            style={{ width: 70, height: 37, fontSize: 16 }}
          >
            <p style={{ marginTop: 2 }}>Go!</p>
          </Link>
        ) : (
          <Link
            onClick={() => {
              goToTest();
              props.onTakeTest();
              props.close();
            }}
            className="btn btn-primary waves-effect waves-light btn-sm"
            style={{ width: 70, height: 37, fontSize: 16 }}
          >
            <p style={{ marginTop: 2 }}>Go!</p>
          </Link>
        )}{" "}
        <Button color="secondary" onClick={props.close}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
