import { useEffect, useState } from "react";
import cn from "classnames";
import api from "../../api/baseConfig";
import Apihelper from "../../api/apiHelper";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { trackActivity } from "../../api";
import { AssessmentDetails } from "./sections/admin/assessment-details/AssessmentDetails";
import { StudentEnrollment } from "./sections/admin/student-enrollment/StudentEnrollment";
import { Cards as AdminCards } from "./sections/admin/cards/Cards";
import { AssessmentPerformance } from "./sections/admin/assessment-performance/AssessmentPerformance";
import { QLinksAndRActivity } from "./sections/admin/QuickLinksRecentAct/QLinksAndRActivity";
import { EventCalender } from "./sections/admin/eventCalender/EventCalender";
import { AssessmentMarks } from "./sections/admin/assessment-marks/AssessmentMarks";
import { Leaderboard } from "./sections/admin/leaderboard/Leaderboard";
import { AvgAssessmentMarks } from "./sections/admin/avg-assessment-marks/AvgAssessmentMarks";
import { Cards as StudentCards } from "./sections/student/cards/Cards";
import { Topics } from "./sections/student/topics/Topics";
import { QuestionsSolved } from "./sections/student/questions-solved/QuestionsSolved";
import { Level } from "./sections/student/level/Level";
import { connect, useSelector } from "react-redux";
import { BuyPackages } from "./sections/student/buy-packages/BuyPackages";
import { Assessments } from "./sections/student/assessments/Assessments";
import { Leaderboard as StudentLeaderboard } from "./sections/student/leaderboard/Leaderboard";
import { DeleteModule } from "../../components/DeleteModule";
import { PracticePackages } from "./sections/student/practice-packages/PracticePackages";
import styles from "./Dashboard.module.scss";
import DashboardHeroCard from "./dashboardHeroCard";
import { GetObjectFromString } from "../utils/GetObjectFromString";
import Select from "react-select";
import { AntInfo } from "../../assets/icons/icons";
import { TervButtonFilled } from "components/TervButtons";
import { ModalToggle } from "pages/utils/ModalToggle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";
import { sessionPrivileges } from "privileges";
import { useLogoContext, handleLogoContext } from "../../context/logoContext";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import {
  Table,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";

const paraStyle = {
  fontSize: 20,
  fontWeight: 700,
  color: "#794DF5",
  marginTop: 12,
  textAlign: "center",
};

const customStyles = {
  control: (styles) => ({
    ...styles,
    width: "207px",
    backgroundColor: "#fafbfc",
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
};

function DashBoard() {
  const userPrivileges = sessionPrivileges();
  const Priv = userPrivileges.userprivileges || [];
  const logoImage = useLogoContext();
  const [currentCustomer, setCurrentCustomer] = useState("all");
  const { t } = useTranslation();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalAd, setDeleteModalAd] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const [dataIsLoading, setIfDataIsLoading] = useState(false);

  const CustomizeTooltip = withStyles({
    tooltip: {
      padding: "5px ",
      color: "white",
      backgroundColor: "#252525",
      fontSize: "13px",
      lineHeight: "18px",
      maxWidth: 700,
      border: "none",
    },
  })(Tooltip);

  const allCustomers = useSelector((state) =>
    state.StudentAndCustomerReducer.Customers
      ? state.StudentAndCustomerReducer.Customers.reduce((acc, val) => {
          acc.push({
            label: val.name,
            value: val.id,
          });
          return acc;
        }, [])
      : []
  );

  const allCus = [{ label: "All", value: "all" }];

  const tempCustomers = [...allCus, ...allCustomers];

  const ShowRealDataDiv = ({ handleShowReal }) => (
    <div
      className={
        Priv?.includes("GET_ADMIN_DASHBOARD")
          ? styles.adminLoadDataContainer
          : styles.loadDataContainer
      }
    >
      <div className={styles.loadDataMain}>
        <div className={styles.loadDataText}>
          <AntInfo size={24} color="#FDBC1F" />{" "}
          <p>
            The below dashboard data contains sample data, for real data click
            load button
          </p>
        </div>
      </div>
      <TervButtonFilled text="Load Data" onClick={handleShowReal} />
    </div>
  );

  const [showReal, setShowReal] = useState(false);

  const isMobile = useMediaQuery("(max-width: 767px)");

  const getExpireCustomer = async () => {
    try {
      const data = await Apihelper.getExpireCustomer(
        `${api.baseURL}${api.dashboard.getCustomerExpired}`,
        "get"
      );
      if (data !== []) {
        setUserInfo(data);
      }
    } catch (e) {}
  };

  useEffect(() => { 
    if (Priv?.includes("GET_ADMIN_DASHBOARD") && !Priv?.includes("MANAGE_CUSTOMER")) {
      getExpireCustomer();
      setDeleteModalAd(ModalToggle);
    }
  }, []);

  useEffect(() => { 
    if (Priv?.includes("MANAGE_CUSTOMER")) {
      getExpireCustomer();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!Priv?.includes("GET_ADMIN_DASHBOARD")) {
      trackActivity("-", "Dashboard", "viewed-dashboard");
    }
  }, []);

  const handleShowReal = () => setShowReal(ModalToggle);

  return (
    <div className={styles.page}>
 <div>
    <div>
    {userInfo.map((data, index) => (
      <div>
      {userInfo?.length > 0 && (
             <Dialog 
             open={deleteModalAd}  
             >
          <div style={{
            textAlign:"center",
            background:"#F9F9F9",
            width:"100%",
            height:"35px",
            paddingTop:"1%",
          }}>
          <p style={{
            fontSize:"16px",
            fontWeight:"600",
            lineHeight:"20px"
          }}>Upgrade your Subscription</p> 
          </div>
      <DialogContent
       classes={{ root: styles.dialogContent }}>
          <div className={styles.dialogMain}>
          <div className={styles.dialogTitle}>
          <svg width="139" height="144" viewBox="0 0 139 144" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M32.5144 27.2282C35.6069 22.7925 34.518 16.6897 30.0823 13.5973C25.6467 10.5048 19.5439 11.5937 16.4515 16.0293C13.359 20.465 14.4479 26.5677 18.8835 29.6602C23.3192 32.7527 29.4219 31.6638 32.5144 27.2282Z" fill="#99D9E5"/>
<path d="M23.0352 34.4533C22.4519 38.8596 18.4135 41.9647 13.9982 41.3904C9.59183 40.807 6.48674 36.7686 7.06109 32.3533C7.63544 27.938 11.6828 24.8419 16.0981 25.4162C20.5135 25.9906 23.6186 30.038 23.0352 34.4533Z" fill="#99D9E5"/>
<path d="M14.1333 20.2742C13.7922 22.8409 11.441 24.6447 8.88332 24.3037C6.31669 23.9626 4.51287 21.6114 4.85389 19.0537C5.19491 16.4871 7.54616 14.6833 10.1038 15.0243C12.6615 15.3563 14.4653 17.7076 14.1333 20.2742Z" fill="#99D9E5"/>
<path d="M11.4651 47.0023C13.5132 45.4051 13.8787 42.4499 12.2815 40.4019C10.6843 38.3538 7.72913 37.9883 5.68105 39.5855C3.63296 41.1827 3.26747 44.1378 4.8647 46.1859C6.46192 48.234 9.41704 48.5995 11.4651 47.0023Z" fill="#99D9E5"/>
<path d="M38.1139 8.13994C39.4854 6.38745 39.1765 3.85493 37.424 2.48342C35.6715 1.11191 33.139 1.42076 31.7675 3.17326C30.396 4.92576 30.7049 7.45827 32.4574 8.82978C34.2099 10.2013 36.7424 9.89244 38.1139 8.13994Z" fill="#99D9E5"/>
<path d="M23.7714 14.8446C26.8146 14.8446 29.2816 12.3776 29.2816 9.3344C29.2816 6.29121 26.8146 3.82422 23.7714 3.82422C20.7282 3.82422 18.2612 6.29121 18.2612 9.3344C18.2612 12.3776 20.7282 14.8446 23.7714 14.8446Z" fill="#99D9E5"/>
<path d="M39.0095 26.5658C43.4057 26.5658 46.9696 23.0019 46.9696 18.6057C46.9696 14.2094 43.4057 10.6455 39.0095 10.6455C34.6132 10.6455 31.0493 14.2094 31.0493 18.6057C31.0493 23.0019 34.6132 26.5658 39.0095 26.5658Z" fill="#99D9E5"/>
<path d="M5.57142 34.9739C5.37399 36.5085 3.96503 37.5855 2.43044 37.379C0.895847 37.1816 -0.181062 35.7727 0.0253458 34.2381C0.222779 32.7035 1.63173 31.6266 3.16633 31.833C4.70092 32.0394 5.77783 33.4483 5.57142 34.9739Z" fill="#99D9E5"/>
<path d="M13.6124 9.73811C13.415 11.2727 12.0061 12.3496 10.4715 12.1432C8.93686 11.9368 7.85995 10.5368 8.06636 9.00221C8.26379 7.46762 9.67275 6.39072 11.2073 6.59712C12.733 6.80353 13.8188 8.20351 13.6124 9.73811Z" fill="#99D9E5"/>
<path d="M128.554 73.2584C128.554 83.2646 126.266 92.7414 122.174 101.177C111.799 122.626 89.8305 137.415 64.3975 137.415C43.7478 137.415 25.3776 127.66 13.6482 112.503C5.23937 101.653 0.240723 88.0389 0.240723 73.2584C0.240723 37.8281 28.9673 9.10156 64.3975 9.10156C99.8368 9.10156 128.554 37.8281 128.554 73.2584Z" fill="#99D9E5"/>
<path d="M24.754 103.932C27.6032 101.083 27.6032 96.4637 24.754 93.6144C21.9047 90.7651 17.2852 90.7651 14.4359 93.6144C11.5866 96.4637 11.5866 101.083 14.4359 103.932C17.2852 106.782 21.9047 106.782 24.754 103.932Z" fill="#72B8C1"/>
<path d="M61.5706 102.353C64.0735 102.353 66.1026 100.324 66.1026 97.8206C66.1026 95.3176 64.0735 93.2886 61.5706 93.2886C59.0676 93.2886 57.0386 95.3176 57.0386 97.8206C57.0386 100.324 59.0676 102.353 61.5706 102.353Z" fill="#72B8C1"/>
<path d="M31.9562 91.4759C33.2548 91.4759 34.3075 90.4232 34.3075 89.1247C34.3075 87.8261 33.2548 86.7734 31.9562 86.7734C30.6577 86.7734 29.605 87.8261 29.605 89.1247C29.605 90.4232 30.6577 91.4759 31.9562 91.4759Z" fill="#72B8C1"/>
<path d="M116.285 80.3749C116.442 76.2937 113.261 72.8581 109.18 72.7013C105.099 72.5445 101.663 75.7258 101.506 79.8069C101.349 83.8881 104.531 87.3237 108.612 87.4805C112.693 87.6373 116.128 84.456 116.285 80.3749Z" fill="#72B8C1"/>
<path d="M122.173 101.177C111.799 122.625 89.8302 137.415 64.3972 137.415C43.7475 137.415 25.3773 127.66 13.6479 112.502C16.3133 108.329 20.9889 105.565 26.3016 105.565C31.1387 105.565 35.4374 107.872 38.1745 111.425C39.404 106.184 44.0975 102.281 49.7154 102.281C55.2974 102.281 59.964 106.14 61.2293 111.336C63.2127 109.936 65.537 108.984 68.0408 108.59C68.0228 108.338 68.0049 108.087 68.0049 107.827C68.0049 101.545 73.1023 96.4474 79.3842 96.4474C82.3098 96.4474 84.9662 97.5602 86.9854 99.373C87.9277 93.2347 93.2225 88.5232 99.6301 88.5232C106.702 88.5232 112.436 94.2577 112.436 101.329C112.436 101.554 112.418 101.769 112.409 101.985C114.195 101.114 116.197 100.611 118.323 100.611C119.661 100.611 120.953 100.809 122.173 101.177Z" fill="#72B8C1"/>
<path d="M36.8379 111.04C36.3712 111.623 35.8956 112.206 35.4199 112.781" stroke="#264C51" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M32.4762 116.271C30.7711 118.344 29.1648 120.489 27.8994 122.957C26.1584 126.358 25.1263 130.235 25.7097 134.013C27.4237 145.087 39.6018 144.486 47.2658 139.891C50.4337 137.998 53.2067 135.53 55.8362 132.963C58.4208 130.441 61.2297 128.314 64.1284 126.143C70.1232 121.647 76.45 117.554 83.2435 114.36C89.8037 111.281 98.4728 109.137 105.688 110.617C111.351 111.775 114.312 116.065 114.276 121.745C114.267 123.63 113.873 125.488 113.208 127.264" stroke="#264C51" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="5.09 5.09"/>
<path d="M112.311 129.356C111.97 130.03 111.602 130.676 111.208 131.304" stroke="#264C51" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.0513 41.2007L51.3777 61.6799L53.1097 102.943L72.1531 78.713L101.283 99.7576L138.796 6.28198L17.0513 41.2007Z" fill="#FFAF34"/>
<path d="M51.3774 61.6798L52.212 81.3424L53.1095 102.943L72.1528 78.7129L66.9208 74.6925L105.698 30.1533L51.3774 61.6798Z" fill="#FF9135"/>
<path opacity="0.3" d="M52.2124 86.5653L52.6791 92.7127L53.1098 102.943L72.1532 78.7129L66.9212 74.6925L105.699 30.1533L52.2124 86.5653Z" fill="#735414"/>
<path opacity="0.4" d="M51.3777 61.6799L26.3037 42.9238L138.796 6.28198L17.0513 41.2007L51.3777 61.6799Z" fill="white"/>
<path opacity="0.4" d="M105.699 30.1533L66.9209 74.6925L72.1529 78.7129L105.699 30.1533Z" fill="white"/>
</svg>
          </div>
        </div>
        <p style={paraStyle}>
          <div>Your Subscription will expire in {data.daysLeft} days</div>
        </p>
        <div>
          <div className={styles.paraText5}>
            <span>But don't worry, you can continue your journey with {logoImage.productName}, please contact our super admin</span>
          </div>

        </div>
      </DialogContent>
      <DialogActions classes={{ root: styles.dialogActions }}>
        <Button
          variant="contained"
          onClick = {() => 
            setDeleteModalAd(!ModalToggle)}
          classes={{ root: styles.buttonYes }}
        >
          
          I understand
        </Button>
      </DialogActions>
    </Dialog>
      )}
      </div>
    ))}

       <Dialog  open={deleteModal}  >
       <div style={{
            textAlign:"center",
            background:"#F9F9F9",
            width:"100%",
            height:"35px",
            paddingTop:"1.4%",
          }}>
                   <p style={{
            fontSize:"16px",
            fontWeight:"600",
            lineHeight:"20px"
          }}>Take action</p> 
          </div>
      <DialogContent
       classes={{ root: styles.dialogContent }}>
       
        
          <div className={styles.dialogMain}>
          <div className={styles.dialogTitle}>
          <svg width="139" height="144" viewBox="0 0 139 144" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M32.5144 27.2282C35.6069 22.7925 34.518 16.6897 30.0823 13.5973C25.6467 10.5048 19.5439 11.5937 16.4515 16.0293C13.359 20.465 14.4479 26.5677 18.8835 29.6602C23.3192 32.7527 29.4219 31.6638 32.5144 27.2282Z" fill="#99D9E5"/>
<path d="M23.0352 34.4533C22.4519 38.8596 18.4135 41.9647 13.9982 41.3904C9.59183 40.807 6.48674 36.7686 7.06109 32.3533C7.63544 27.938 11.6828 24.8419 16.0981 25.4162C20.5135 25.9906 23.6186 30.038 23.0352 34.4533Z" fill="#99D9E5"/>
<path d="M14.1333 20.2742C13.7922 22.8409 11.441 24.6447 8.88332 24.3037C6.31669 23.9626 4.51287 21.6114 4.85389 19.0537C5.19491 16.4871 7.54616 14.6833 10.1038 15.0243C12.6615 15.3563 14.4653 17.7076 14.1333 20.2742Z" fill="#99D9E5"/>
<path d="M11.4651 47.0023C13.5132 45.4051 13.8787 42.4499 12.2815 40.4019C10.6843 38.3538 7.72913 37.9883 5.68105 39.5855C3.63296 41.1827 3.26747 44.1378 4.8647 46.1859C6.46192 48.234 9.41704 48.5995 11.4651 47.0023Z" fill="#99D9E5"/>
<path d="M38.1139 8.13994C39.4854 6.38745 39.1765 3.85493 37.424 2.48342C35.6715 1.11191 33.139 1.42076 31.7675 3.17326C30.396 4.92576 30.7049 7.45827 32.4574 8.82978C34.2099 10.2013 36.7424 9.89244 38.1139 8.13994Z" fill="#99D9E5"/>
<path d="M23.7714 14.8446C26.8146 14.8446 29.2816 12.3776 29.2816 9.3344C29.2816 6.29121 26.8146 3.82422 23.7714 3.82422C20.7282 3.82422 18.2612 6.29121 18.2612 9.3344C18.2612 12.3776 20.7282 14.8446 23.7714 14.8446Z" fill="#99D9E5"/>
<path d="M39.0095 26.5658C43.4057 26.5658 46.9696 23.0019 46.9696 18.6057C46.9696 14.2094 43.4057 10.6455 39.0095 10.6455C34.6132 10.6455 31.0493 14.2094 31.0493 18.6057C31.0493 23.0019 34.6132 26.5658 39.0095 26.5658Z" fill="#99D9E5"/>
<path d="M5.57142 34.9739C5.37399 36.5085 3.96503 37.5855 2.43044 37.379C0.895847 37.1816 -0.181062 35.7727 0.0253458 34.2381C0.222779 32.7035 1.63173 31.6266 3.16633 31.833C4.70092 32.0394 5.77783 33.4483 5.57142 34.9739Z" fill="#99D9E5"/>
<path d="M13.6124 9.73811C13.415 11.2727 12.0061 12.3496 10.4715 12.1432C8.93686 11.9368 7.85995 10.5368 8.06636 9.00221C8.26379 7.46762 9.67275 6.39072 11.2073 6.59712C12.733 6.80353 13.8188 8.20351 13.6124 9.73811Z" fill="#99D9E5"/>
<path d="M128.554 73.2584C128.554 83.2646 126.266 92.7414 122.174 101.177C111.799 122.626 89.8305 137.415 64.3975 137.415C43.7478 137.415 25.3776 127.66 13.6482 112.503C5.23937 101.653 0.240723 88.0389 0.240723 73.2584C0.240723 37.8281 28.9673 9.10156 64.3975 9.10156C99.8368 9.10156 128.554 37.8281 128.554 73.2584Z" fill="#99D9E5"/>
<path d="M24.754 103.932C27.6032 101.083 27.6032 96.4637 24.754 93.6144C21.9047 90.7651 17.2852 90.7651 14.4359 93.6144C11.5866 96.4637 11.5866 101.083 14.4359 103.932C17.2852 106.782 21.9047 106.782 24.754 103.932Z" fill="#72B8C1"/>
<path d="M61.5706 102.353C64.0735 102.353 66.1026 100.324 66.1026 97.8206C66.1026 95.3176 64.0735 93.2886 61.5706 93.2886C59.0676 93.2886 57.0386 95.3176 57.0386 97.8206C57.0386 100.324 59.0676 102.353 61.5706 102.353Z" fill="#72B8C1"/>
<path d="M31.9562 91.4759C33.2548 91.4759 34.3075 90.4232 34.3075 89.1247C34.3075 87.8261 33.2548 86.7734 31.9562 86.7734C30.6577 86.7734 29.605 87.8261 29.605 89.1247C29.605 90.4232 30.6577 91.4759 31.9562 91.4759Z" fill="#72B8C1"/>
<path d="M116.285 80.3749C116.442 76.2937 113.261 72.8581 109.18 72.7013C105.099 72.5445 101.663 75.7258 101.506 79.8069C101.349 83.8881 104.531 87.3237 108.612 87.4805C112.693 87.6373 116.128 84.456 116.285 80.3749Z" fill="#72B8C1"/>
<path d="M122.173 101.177C111.799 122.625 89.8302 137.415 64.3972 137.415C43.7475 137.415 25.3773 127.66 13.6479 112.502C16.3133 108.329 20.9889 105.565 26.3016 105.565C31.1387 105.565 35.4374 107.872 38.1745 111.425C39.404 106.184 44.0975 102.281 49.7154 102.281C55.2974 102.281 59.964 106.14 61.2293 111.336C63.2127 109.936 65.537 108.984 68.0408 108.59C68.0228 108.338 68.0049 108.087 68.0049 107.827C68.0049 101.545 73.1023 96.4474 79.3842 96.4474C82.3098 96.4474 84.9662 97.5602 86.9854 99.373C87.9277 93.2347 93.2225 88.5232 99.6301 88.5232C106.702 88.5232 112.436 94.2577 112.436 101.329C112.436 101.554 112.418 101.769 112.409 101.985C114.195 101.114 116.197 100.611 118.323 100.611C119.661 100.611 120.953 100.809 122.173 101.177Z" fill="#72B8C1"/>
<path d="M36.8379 111.04C36.3712 111.623 35.8956 112.206 35.4199 112.781" stroke="#264C51" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M32.4762 116.271C30.7711 118.344 29.1648 120.489 27.8994 122.957C26.1584 126.358 25.1263 130.235 25.7097 134.013C27.4237 145.087 39.6018 144.486 47.2658 139.891C50.4337 137.998 53.2067 135.53 55.8362 132.963C58.4208 130.441 61.2297 128.314 64.1284 126.143C70.1232 121.647 76.45 117.554 83.2435 114.36C89.8037 111.281 98.4728 109.137 105.688 110.617C111.351 111.775 114.312 116.065 114.276 121.745C114.267 123.63 113.873 125.488 113.208 127.264" stroke="#264C51" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="5.09 5.09"/>
<path d="M112.311 129.356C111.97 130.03 111.602 130.676 111.208 131.304" stroke="#264C51" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M17.0513 41.2007L51.3777 61.6799L53.1097 102.943L72.1531 78.713L101.283 99.7576L138.796 6.28198L17.0513 41.2007Z" fill="#FFAF34"/>
<path d="M51.3774 61.6798L52.212 81.3424L53.1095 102.943L72.1528 78.7129L66.9208 74.6925L105.698 30.1533L51.3774 61.6798Z" fill="#FF9135"/>
<path opacity="0.3" d="M52.2124 86.5653L52.6791 92.7127L53.1098 102.943L72.1532 78.7129L66.9212 74.6925L105.699 30.1533L52.2124 86.5653Z" fill="#735414"/>
<path opacity="0.4" d="M51.3777 61.6799L26.3037 42.9238L138.796 6.28198L17.0513 41.2007L51.3777 61.6799Z" fill="white"/>
<path opacity="0.4" d="M105.699 30.1533L66.9209 74.6925L72.1529 78.7129L105.699 30.1533Z" fill="white"/>
</svg>
          </div>
        </div>
        
        <p style={paraStyle}>
          <div>Some customer Subscription will expire soon</div>
        </p>
       
         {userInfo?.length > 5 ? (
        <div>
        <div  style={{ backgroundColor: "#C5EAFC",width:"480px", height:"40px" }}>
        <tr>  
      <th style={{ color: "#000",textAlign: 'center', paddingLeft:"12px", paddingTop:"10px",fontWeight:"600" }}>S.NO</th>
      <th style={{ color: "#000", paddingLeft:"30px",fontWeight:"600", width:"320px" }}>College {logoImage.productName}</th>
      <th style={{ color: "#000", fontWeight:"600",paddingLeft:"16px"}}>Expires in</th>
    </tr>
        </div>
         <div className={styles.scrollbar} style={{width:"480px", height:"230px" ,overflowY:"scroll"}}> 
                        <Table>
  {/* <thead style={{ backgroundColor: "#C5EAFC",position: "sticky", top: '0',}}>
    <tr>  
      <th style={{ color: "#000",textAlign: 'center' }}>S.NO</th>
      <th style={{ color: "#000",}}>College Name</th>
      <th style={{ color: "#000"}}>Expires in</th>
    </tr>
  </thead> */}
  
  {userInfo.map((data,index) => (
  <tbody style={{ backgroundColor: "#F9FDFF", paddingTop:"50px"}} key={`userInfo${index}`} >
<tr>
<td style={{ textAlign: 'center', fontWeight:"600" }} >{index + 1}</td>
<td style={{fontWeight:"600", width:"320px", paddingLeft:"34px"}}>
{data?.name.length > 30 ? (
                           <CustomizeTooltip 
                           title={<p style={{fontSize:"12px", paddingTop:"2%", height:"10px",}}>
                             {data.name}
                             </p>
                           }
                         >
 <span className={styles.description}>
 {data.name}
 </span>
 </CustomizeTooltip>
):(
  <span>
  {data.name}
  </span>
)}
  </td>
<td style={{paddingLeft:"20px"}}>

{data.daysLeft < 1 ? (
  <span style={{color:"#FF0000", fontWeight:"500", width:"60px"}}>Few hours</span>
):  data.daysLeft == 1 ? (
  <span style={{color:"#FF0000", fontWeight:"500"}}>{data.daysLeft} day</span>
): data.daysLeft < 4 ? (
  <span style={{color:"#FF0000", fontWeight:"500"}}>{data.daysLeft} days</span>
): data.daysLeft < 9 ? (
    <span style={{color:"#794DF5", fontWeight:"500"}}>{data.daysLeft} days</span>
): (
  <span style={{color:"#00B612", fontWeight:"500"}}>{data.daysLeft} days</span> 
)}
</td>  
</tr>

  </tbody>
  ))}

</Table>
              </div>
        </div>

         ):(
          <div style={{width:"480px",}}> 
          <Table responsive>
  <thead style={{ backgroundColor: "#C5EAFC", }}>
    <tr>  
      <th style={{ color: "#000",textAlign: 'center'}}>S.NO</th>
      <th style={{ color: "#000",}}>College Name</th>
      <th style={{ color: "#000"}}>Expires in</th>
    </tr>
  </thead>
  
  {userInfo.map((data,index) => (
  <tbody style={{ backgroundColor: "#F9FDFF" }} key={`userInfo${index}`} >
<tr>
<td style={{ textAlign: 'center', fontWeight:"600" }} >{index + 1}</td>
<td style={{fontWeight:"600", width:"290px",}}>
{data?.name.length > 30 ? (
                           <CustomizeTooltip 
                           placement="botto"
                           title={<p style={{fontSize:"12px", paddingTop:"2%", height:"10px",}}>
                             {data.name}
                             </p>
                           }
                         >
 <span className={styles.description}>
 {data.name}
 </span>
 </CustomizeTooltip>
):(
  <span>
  {data.name}
  </span>
)}
  </td>
<td style={{paddingLeft:"15px"}}>

{data.daysLeft < 1 ? (
  <span style={{color:"#FF0000", fontWeight:"500", width:"60px"}}>Few hours</span>
):  data.daysLeft == 1 ? (
  <span style={{color:"#FF0000", fontWeight:"500"}}>{data.daysLeft} day</span>
): data.daysLeft < 4 ? (
  <span style={{color:"#FF0000", fontWeight:"500"}}>{data.daysLeft} days</span>
): data.daysLeft < 9 ? (
    <span style={{color:"#794DF5", fontWeight:"500"}}>{data.daysLeft} days</span>
): (
  <span style={{color:"#00B612", fontWeight:"500"}}>{data.daysLeft} days</span> 
)}

</td>  
</tr>

  </tbody>
  ))}

</Table>
</div>
         )}
      </DialogContent>
      <DialogActions classes={{ root: styles.dialogActions1 }}>
        <Button
          variant="contained"
          onClick = {() => setDeleteModal(!ModalToggle)}
          // onClick={() => {
          //   Delete();
          //   Close();
          // }}
          classes={{ root: styles.buttonYes }}
        >
          
          I understand
        </Button>
      </DialogActions>
    </Dialog>
    </div>

    </div>

      {Priv?.includes("GET_STUDENT_DASHBOARD") && <DashboardHeroCard />}
      {Priv?.includes("GET_STUDENT_DASHBOARD") && (
      <h2 className={styles.pageTitle}>{t("Your Dashboard ")} </h2>
      )}
 
<div>
{Priv?.includes("MANAGE_CUSTOMER") && (
      <h2 className={styles.pageTitle}>{t("Your Dashboard ")}
      {userInfo?.length > 0 && (
       <span onClick = {() => {
      setDeleteModal(ModalToggle);
      // getExpireCustomer();
    }}>
      <a>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 0C5.3832 0 0 5.3832 0 12C0 18.6168 5.3832 24 12 24C18.6168 24 24 18.6168 24 12C24 5.3832 18.6168 0 12 0ZM13.2 18H10.8V10.8H13.2V18ZM13.2 8.4H10.8V6H13.2V8.4Z" fill="#FF433F"/>
</svg>
</a>
</span>
)}
</h2>
)}
</div>

      <div>
  {Priv?.includes("GET_ADMIN_DASHBOARD") && !Priv?.includes("MANAGE_CUSTOMER") && (
    <h2 className={styles.pageTitle}>{t("Your Dashboard ")} 
    {userInfo?.length > 0 && (
      <span onClick = {() => setDeleteModalAd(ModalToggle)}>
      <a>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 0C5.3832 0 0 5.3832 0 12C0 18.6168 5.3832 24 12 24C18.6168 24 24 18.6168 24 12C24 5.3832 18.6168 0 12 0ZM13.2 18H10.8V10.8H13.2V18ZM13.2 8.4H10.8V6H13.2V8.4Z" fill="#FF433F"/>
</svg>
</a>
</span>
    )}
</h2>
 )}
</div>
  
      {Priv?.includes("MANAGE_CUSTOMER") && (
        <Select
          placeholder="Choose a customer"
          options={tempCustomers}
          onChange={(val) => {
            setCurrentCustomer(val.value);
          }}
          value={GetObjectFromString(tempCustomers, currentCustomer)}
          styles={customStyles}
          maxMenuHeight={120}
          className={styles.customerSelect}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      )}
      {Priv?.includes("GET_STUDENT_DASHBOARD") && (
        <div
          className={cn(styles.pageContent, styles.student, {
            [styles.studentReal]: showReal,
          })}
        >
          <StudentCards />
          {!showReal && <ShowRealDataDiv handleShowReal={handleShowReal} />}
          <Topics showReal={showReal} isMobile={isMobile} />
          <QuestionsSolved showReal={showReal} isMobile={isMobile} />
          <Assessments showReal={showReal} isMobile={isMobile} />
          <PracticePackages showReal={showReal} isMobile={isMobile} />
          <Level showReal={showReal} isMobile={isMobile} />
          <BuyPackages showReal={showReal} isMobile={isMobile} />
          {/* <StudentLeaderboard showReal={showReal} isMobile={isMobile} /> */}
        </div>
      )}
      {Priv?.includes("GET_ADMIN_DASHBOARD") && (
        <div
          className={cn(styles.pageContent, styles.admin, {
            [styles.adminReal]: showReal,
          })}
        >
          <AdminCards showReal={showReal} currentCustomer={currentCustomer} />
          <QLinksAndRActivity
            showReal={showReal}
            isMobile={isMobile}
            userPrivileges={userPrivileges}
          />
          <EventCalender
            showReal={showReal}
            isMobile={isMobile}
            currentCustomer={currentCustomer}
            userPrivileges={userPrivileges}
          />
          {/* <AssessmentDetails showReal={showReal} isMobile={isMobile} /> */}
          {!showReal && <ShowRealDataDiv handleShowReal={handleShowReal} />}
          <AssessmentDetails showReal={showReal} isMobile={isMobile} />
          <StudentEnrollment showReal={showReal} isMobile={isMobile} />
          <AssessmentPerformance showReal={showReal} isMobile={isMobile} />
          <AssessmentMarks showReal={showReal} isMobile={isMobile} />
          <AvgAssessmentMarks showReal={showReal} isMobile={isMobile} />
          <Leaderboard showReal={showReal} isMobile={isMobile} />
        </div>
      )}
    </div>
  );
}
export default connect(null, null)(DashBoard);