import React, { Component } from "react"
import { getSafe } from "../../Taketest/common/testHelper"
import ReactHtmlParser from "react-html-parser"
import { Input, Row, Button } from "reactstrap"
export default function Codereport(props) {
  const [acccordian, setAccordian] = React.useState(false)

  return (
    <div style={{ padding: 10 }}>
      <div
        onClick={() => {
          setAccordian(!acccordian)
        }}
      >
        {" "}
        <h5>
          Question {props.num + 1}{" "}
          {acccordian ? (
            <i style={{ marginLeft: 10 }} className="fas fa-chevron-down"></i>
          ) : (
            <i style={{ marginLeft: 10 }} className="fas fa-chevron-right"></i>
          )}{" "}
        </h5>{" "}
      </div>

      {acccordian ? (
        <div style={{ padding: 10 }}>
          <h6>
            {" "}
            {ReactHtmlParser(props.data.question)}
            {props.from && props.from === "assessment" ? (
              props.grading === "manual" ? (
                <Row style={{ marginLeft: 6 }}>
                  <label>Marks</label>{" "}
                  <Input
                    type="number"
                    style={{ width: 100, marginBottom: 20, marginLeft: 10 }}
                    value={
                      props.grade
                        ? props.grade
                        : props.data.solutionMap?.marksGained
                    }
                    onChange={e => props.updateGrade(e.target.value)}
                    disabled={props.disabled}
                  />
                </Row>
              ) : (
                <span>
                Marks Gained: &nbsp;{props.data.solutionMap?.marksGained}
              </span>
              )
            ) : (
              <span>
                  Marks Gained: &nbsp;{props.data.solutionMap?.marksGained}
                </span>
            )}{" "}
          </h6>
          <span>
            {" "}
            <h6>Sample Input: </h6>
            {ReactHtmlParser(props.data.sampleInput)}{" "}
          </span>
          <span>
            <h6>Sample Output: </h6> {ReactHtmlParser(props.data.sampleOutput)}{" "}
          </span>
        </div>
      ) : (
        ""
      )}

      {acccordian ? (
        <div style={{ padding: 15 }}>
          <div
            style={{
              padding: 10,
              border: "2px solid #c2c2c2",
              color: "#ff8080",
            }}
          >
            <strong>Submitted Code</strong>
          </div>
          <div
            style={{
              padding: 10,
              border: "2px solid #c2c2c2",
              borderTop: "none",
            }}
          >
            <h5>Main Code </h5>
            <div
              style={{
                borderRadius: 5,
                padding: 10,
                border: "2px solid #c2c2c2",
                backgroundColor: "#e8e3e3",
              }}
            >
              {atob(
                getSafe(() => props.data.solutionMap.codeSolution, "Missing")
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {acccordian ? (
        <div style={{ marginTop: 15, padding: 15 }}>
          <div
            style={{
              padding: 10,
              border: "2px solid #c2c2c2",
              color: "#ff8080",
            }}
          >
            <strong>TestCase Execution</strong>
          </div>
          {/* <div style={{ padding: 10, border: '2px solid #c2c2c2', borderTop: "none" }} >
                            <h5>Main Code </h5>
                            <div style={{ borderRadius: 5, padding: 10, border: '2px solid #c2c2c2', backgroundColor: '#e8e3e3' }} >{btoa(getSafe(() => props.data.solutionMap.codeSolution, 'Missing'))}</div>
                        </div> */}
        </div>
      ) : (
        ""
      )}
    </div>
  )
}
